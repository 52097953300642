import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import Loader from "../../../../app/shared/Loader";
import Field from "../../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import React from "react";
import UspsWrapper from "../Form";
import UspsConfigurationModel from "../../../../../modules/models/settings/usps/UspsConfigurationModel";

const UspsConfiguration = () => {
	const {getLangText} = useLang();

	const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
		rest: services.API_SETTINGS_USPS,
		model: UspsConfigurationModel,
	});

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li className="active">
						<div>USPS</div>
					</li>
				</ul>
			</div>
			<UspsWrapper>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="settings-form pt-3">
						<div className="steps-wrapper">
							<div className="step border-step">
								<div className="step-header">
									<div className="number">
										1
									</div>
									<div className="title d-block">
										<a href="https://reg.usps.com/entreg/LoginAction_input?app=Phoenix&appURL=https://www.usps.com/"
										   target="_blank">
											<img style={{marginLeft: 0, height: '30px', width: 'auto'}}
												 src="/assets/images/svg/usps.svg" alt="usps"/>
										</a>
										<div>
											{getLangText('settings_integrations_furgonetka_login_1')}
										</div>
									</div>
									<a target="_blank"
									   href="https://reg.usps.com/entreg/LoginAction_input?app=Phoenix&appURL=https://www.usps.com/"
									   className="button primary small-size ml-auto text-center">
										{getLangText('settings_integrations_fakturownia_description_4')}
									</a>
								</div>
							</div>
							<div className="step border-step mt-3 pb-lg-4 pb-3">
								<div className="step-header align-items-start">
									<div className="number">
										2
									</div>
									<div className="title d-block flex-grow-1 mt-2">
										<div>{getLangText('pasteApiKeyBelowLabel')}</div>
										<Field
											{...getFieldProps('client_id')}
											label={getLangText('clientId')}
											className="mt-3"
										/>
										<Field
											{...getFieldProps('client_secret')}
											label={getLangText('secretKey')}
											type="password"
										/>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center mt-2">
								<SwitchField
									{...getFieldProps('active')}
									label={getLangText('active')}
									className="switch-form inline-label align-items-start big-label light-version mb-0"
								/>
								<div className="button-place ml-auto">
									<button
										type="button"
										onClick={onSave}
										className={`button primary ${isWaiting ? 'loading-button' : ''}`}
									>
										<Loader isLoading={isWaiting}/>
										{getLangText('saveButton')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</UspsWrapper>
		</>
	);
};

export default UspsConfiguration;
