import React, {useEffect, useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import Loader from "../../messenger/loader/Loader";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import TextArea from "../../../../src_shared/components/form/textarea/TextArea";
import Model from "../../../../src_shared/modules/model/Model";
import {useDispatch} from "react-redux";
import {showAlert} from "../../shared/components/alert/AlertActions";
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";
import PhotoPreview from "../../../../src_shared/components/ui/photoPreview/PhotoPreview";

const SharePostModal = ({
                            showModal,
                            hideModal,
                            videoLink,
                            previewSrc = '/assets/images/cover.png',
                            rest = services.API_PUBLISH_VIDEO_POST,
                            initialAutoLoad = false,
                            type = 'video'
                        }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [showPreviewModal, setShoPreviewModal] = useState(false);

    const [{data, isLoading}] = useFetchData({
        rest: services.API_CHECK_POST_PERMISSIONS,
    })

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading: isMessageLoading,
        updateValues
    }] = useFormLocal(
        {
            model: new Model(),
            initialAutoLoad,
            rest,
            afterSubmit: () => {
                hideModal();
                dispatch(showAlert('yuhuLabel', 'postHasBeenPublished1'))
            }
        });

    useEffect(() => {
        updateValues({
            url: videoLink,
            message: 'Nowość w Selmo Apce! 🤩 Od dzisiaj możesz wystawić nam ocenę w aplikacji i podzielić się swoimi wrażeniami po zakupach! 🛍️\n' +
                '\n' +
                'Jak to zrobić?\n' +
                '1️⃣ Otwórz aplikację Selmo\n' +
                '2️⃣ Wyszukaj nasz sklep\n' +
                '3️⃣ Kliknij przycisk „Oceń sklep” i dodaj swoją opinię!\n' +
                '\n' +
                '👉🏻 Pamiętaj, że oceny mogą dodawać wyłącznie osoby, które dokonały zakupów w naszym sklepie przez Selmo Apkę 📲\n' +
                '\n' +
                'Twoja opinia ma ogromne znaczenie. Dziękujemy, że jesteś z nami! 💙'
        })
    }, [])

    return (
        <Modal
            dialogClassName={`default-modal  w-450`}
            show={showModal}
            backdropClassName={showPreviewModal ? 'with-extra-modal' : ''}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('shareVideoOnFacebook')}
                    <div className="text-desc text-sm font-medium">
                        {getLangText('shareFilmForYourClients')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            {isLoading || isMessageLoading ?
                <ComponentLoader
                    className="small-loader"
                    style={{minHeight: '200px'}}
                /> :
                <>
                    <Modal.Body className="mb-4">
                        {!!data.refresh_token_link ?
                            <div className="form-info-box w-100 small-line-height">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('youDontHavePermissions')}</div>
                                    <div className="desc">{getLangText('grantPermissionsToSharePost')}</div>
                                </div>
                            </div> :
                            <div>
                                <TextArea
                                    {...getFieldProps('message')}
                                    label={getLangText('postMessage')}
                                    rows={12}
                                />
                                {/*<div*/}
                                {/*    className="bg-[#EFFEF4] rounded-[5px] flex items-center p-2">*/}
                                {/*    <img*/}
                                {/*        className="w-[18px] min-w-[18px] mr-2"*/}
                                {/*        src="/assets/images/svg/circle-check.svg"*/}
                                {/*        alt="check"*/}
                                {/*    />*/}
                                {/*    <div*/}
                                {/*        className="text-sm font-bold text-[#156534]">{getLangText('videoAttached')}</div>*/}
                                {/*</div>*/}
                                <div className="font-bold text-sm mb-2">
                                    {getLangText('attachedVideo')}
                                </div>
                                <button
                                    onClick={() => setShoPreviewModal(true)}
                                    type="button"
                                    className="bg-[#F9FAFB] p-2 rounded-[5px] flex items-center text-left w-full"
                                >
                                    <img
                                        className="w-[48px] h-[48px] min-w-[48px] rounded-[5px] object-cover"
                                        src={previewSrc}
                                        alt=""
                                    />
                                    {type === 'video' &&
                                        <div className="ml-2">
                                            <div
                                                className="text-sm font-bold">{getLangText('videoWillBeVisibleOnFacebook')}</div>
                                            <div className="text-xs text-desc">{getLangText('seeVideoPreview')}</div>
                                        </div>
                                    }
                                    {type === 'img' &&
                                        <div className="ml-2">
                                            <div
                                                className="text-sm font-bold">{getLangText('photoWillBeVisibleOnFacebook')}</div>
                                            <div className="text-xs text-desc">{getLangText('seeImagePreview')}</div>
                                        </div>
                                    }
                                </button>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button border-button"
                        >
                            {getLangText('cancelButton')}
                        </button>
                        {!!data.refresh_token_link ?
                            <a
                                href={data.refresh_token_link}
                                className={`button primary`}
                            >
                                {getLangText('grandPermissions')}
                            </a> :
                            <button
                                onClick={onSave}
                                type="button"
                                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                            >
                                {isWaiting && <Loader/>}
                                {getLangText('share')}
                            </button>
                        }
                    </Modal.Footer>
                </>
            }
            {showPreviewModal && type === 'video' &&
                <VideoPreview
                    hide={() => setShoPreviewModal(false)}
                    show={showPreviewModal}
                    src={videoLink}
                    subTitle="previewButton"
                />
            }
            {showPreviewModal && type === 'img' &&
                <PhotoPreview
                    src={videoLink}
                    hide={() => setShoPreviewModal(false)}
                    show={showPreviewModal}
                />
            }
        </Modal>
    );
};

export default SharePostModal;
