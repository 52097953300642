export const headerNotificationsSetDropdownVisibility = (toggle) => {
    return {
        type: 'HEADER_NOTIFICATIONS_TOGGLE_VISIBILITY',
        toggle,
    }
}
export const headerNotificationsAddItem = (data) => {
    return {
        type: 'HEADER_NOTIFICATIONS_ADD_ITEM',
        data,
    }
}

export const headerNotificationsUpdateItem = (data) => {
    return {
        type: 'HEADER_NOTIFICATIONS_UPDATE_ITEM',
        data,
    }
}

export const headerNotificationsUpdateFilesFromPusher = (data) => {
    return (dispatch, getState) => {
        const {isOpen} = getState().shared.headerNotifications;
        if (isOpen) {
            dispatch(headerNotificationsAddItem(data));
        } else {
            dispatch(headerNotificationsSetDropdownVisibility(true));
        }
    }
}

export const headerNotificationsUpdateItems = (items) => {
    return {
        type: 'HEADER_NOTIFICATIONS_UPDATE_ITEMS',
        items,
    }
}