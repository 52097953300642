import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

class UspsTemplateModel extends Model {
	getModel() {
		return {
			name: '',
			active: '1',
			length: 1,
			width: 1,
			height: 1,
			weight: 1,
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			active: ValidationError.skip,
			length: ValidationError.notEmpty,
			width: ValidationError.notEmpty,
			height: ValidationError.notEmpty,
			weight: ValidationError.notEmpty,
		};
	}
}

export default new UspsTemplateModel();
