export const INPOST_LABEL_SMALL = 'small';
export const INPOST_LABEL_MEDIUM = 'medium';
export const INPOST_LABEL_BIG = 'large';
export const INPOST_LABEL_CUSTOM = 'custom';

const InpostLabels = [
	{
		id: INPOST_LABEL_SMALL,
		label: 'smallLabel',
		sizes: 'max. 8 x 38 x 64 cm do 5 kg',
		img: 'small',

	},
	{
		id: INPOST_LABEL_MEDIUM,
		label: 'mediumLabel',
		sizes: 'max. 19 x 38 x 64 cm do 5 kg',
		img: 'medium',
	},
	{
		id: INPOST_LABEL_BIG,
		label: 'bigLabel',
		sizes: 'max. 41 x 38 x 64 cm do 8 kg',
		img: 'big',
	},
];

export const InpostLabelsComodaTransmisje = [
	{
		id: INPOST_LABEL_SMALL,
		label: 'smallLabel',
		sizes: 'max. 8 x 38 x 40 cm do 5 kg',
		img: 'small',

	},
	{
		id: INPOST_LABEL_MEDIUM,
		label: 'mediumLabel',
		sizes: 'max. 20 x 40 x 38 cm do 10 kg',
		img: 'medium',
	},
	{
		id: INPOST_LABEL_BIG,
		label: 'bigLabel',
		sizes: 'max. 40 x 38 x 38 cm do 10 kg',
		img: 'big',
	},
];

export default InpostLabels;
