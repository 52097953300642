import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {RouterPaths} from "./RouterPath";
import {useSelector} from "react-redux";

export const AdsPermissionRoute = ({component: Component, allowField = '', ...rest}) => {

    const {userData, waitingForResponse} = useSelector((state) => state.session);

    const isPaid = !!userData.facebook_ads_active;

    if (!isPaid && !waitingForResponse) {
        return <Redirect to={RouterPaths.Marketing}/>;
    }

    return (
        <Route
            component={Component}
            {...rest}
        />
    );
};

AdsPermissionRoute.propTypes = {
    component: PropTypes.elementType,
};

AdsPermissionRoute.defaultProps = {
    component: undefined,
};

export default AdsPermissionRoute;
