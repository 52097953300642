import React from 'react';
import File from "./File";
import useFetchData from "../../../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../../../RestServices";
import {filesUpdateFiles} from "../FilesActions";
import {useDispatch} from "react-redux";
import Skeleton from "./Skeleton";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const Dropdown = ({items, close}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{isLoading}] = useFetchData({
        rest: services.API_EXPORT_EXPORT_FILES,
        afterSubmit: (res) => dispatch(filesUpdateFiles(res)),
    });

    return (
        <div
            className="absolute z-10 top-[calc(100%+10px)] right-0 bg-[white] rounded-[12px] shadow-default min-w-[330px]">
            <div class="flex items-center pt-3 pb-2 px-3">
                <div
                    className="font-bold text-sm text-[#101827]">{getLangText('downloadFiles')}</div>
                <button
                    className="ml-auto text-[8px] text-[#6B7280] hover:text-[#000] transition-all"
                    type="button"
                    onClick={close}
                >
                    <i class="icon-cross"/>
                </button>
            </div>
            {isLoading ?
                <Skeleton/> :
                !!items.length ?
                    items.map((item) => (
                        <File key={item.id} item={item}/>
                    )) :
                    <div className="font-bold text-xs flex items-center flex-col justify-center p-3">
                        <i className="icon-orders"/>
                        <div className="mt-2">{getLangText('noFiles')}</div>
                    </div>
            }
        </div>
    );
};

export default Dropdown;