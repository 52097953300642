import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import React from "react";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import useList from "../../../../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import {
    setWaitingForSendAction
} from "../../../../../../../src_shared/components/ui/waitingForSend/WaitingForSendActions";
import SearchInput from "../../../../../shared/components/form/SearchInput";
import Pagination from "../../../../../shared/components/pagination/Pagination";
import ConfirmModal from "../../../../../../../src_shared/components/modal/ConfirmModal";
import Table from "./components/Table";
import Loader from "../../../../../../../src_shared/components/loader/Loader";
import ComponentLoader from "../../../../../../../src_shared/components/ui/componentLoader/ComponentLoader";

const FacebookAdvertisementsList = () => {
    const {getLangText} = useLang();
    const history = useHistory();
    const dispatch = useDispatch();

    const [{onSave: createAd, isWaiting: isWaitingForAd}] = useSubmitData({
        rest: services.API_MARKETING_AD,
        afterSubmit: (response) => history.push(`${RouterPaths.MarketingFacebookAdvertisements}/details/${response.id}`)
    });

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove},
    } = useList({
        ListActions,
        getStateRoot,
    });

    const [{onSave: changeActivity, isWaiting: isWaitingForChangingActivity}] = useSubmitData(
        {
            rest: `${services.API_MARKETING_ACTIVE_AD}/${markedToRemove?.id}`,
            values: {
                status: (+markedToRemove?.status === 1 || +markedToRemove?.status === 2) ? 3 : 2,
            },
            afterSubmit: () => {
                loadData()
                cancelItemRemoval();
            }
        });

    return (
        <>
            <div className="table-list-wrapper">
                <div
                    style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}
                    className="default-shadow-box pb-0"
                >
                    <div className="table-header">
                        <div className="top-part">
                            <h1 className="title mb-sm-0 mb-2">
                                {getLangText('advertisements')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                            </h1>
                            <SearchInput
                                className="ml-auto"
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                            <button
                                onClick={createAd}
                                className={`button primary small-size ml-2 ${isWaitingForAd ? 'loading-button' : ''}`}
                                type="button"
                            >
                                {isWaitingForAd && <Loader isLoading={true}/>}
                                {getLangText('createAd')}
                            </button>
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        askForRemoveItem={askForRemoveItem}
                        markedToRemove={markedToRemove}
                        createAd={createAd}
                        isWaitingForAd={isWaitingForAd}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {isModalVisible &&
                <ConfirmModal
                    show={isModalVisible}
                    title={getLangText((+markedToRemove?.status === 1 || +markedToRemove?.status === 2) ? 'wantToTurnOfAd' : 'wantToTurnOnAd')}
                    isWaiting={isWaitingForChangingActivity}
                    hide={cancelItemRemoval}
                    onSubmit={changeActivity}
                    dialogClassName="small-modal"
                    saveButton={getLangText('yesButton')}
                />
            }
        </>
    );
};

export default FacebookAdvertisementsList;
