import {getStateRoot, prefix} from "./listReducer";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {services} from "../../../../../RestServices";
import ListActions from "../../../../../../src_shared/list/ListActions";

export class FacebookStatisticsListActions extends ListActions {
    setRefreshTokenLink(link) {
        return {
            type: `${this.prefix}SET_REFRESH_TOKEN_LINK`,
            link,
        };
    }

}

export const getInstance = () => new FacebookStatisticsListActions({
    getStateRoot,
    restService: services.API_MARKETING_AD_STATS,
    pathname: RouterPaths.MarketingFacebookStatistics,
    prefix,
});
export default getInstance();
