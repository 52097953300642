import React, {useEffect, useState} from 'react';
import {findOrderStatusById} from "../../../../shared/enums/OrderStatuses";
import {
    getCorrectOrderPaymentName,
} from "../../../../shared/enums/PaymentStatuses";
import {useDispatch, useSelector} from "react-redux";
import FiltersActions from "../../../filters/FiltersActions";
import {createSelector} from "reselect";
import {getStateRoot as getFiltersStateRoot} from "../../../filters/filtersReducer";
import {findOrderPackedStatusById} from "../../../../shared/enums/PackedStatuses";
import {findPaidStatusById} from "../../../../shared/enums/PaidStatuses";
import {findOrderInvoiceStatusById} from "../../../../shared/enums/OrdersInvoiceStatuses";
import {findOrderLabelStatusById} from "../../../../shared/enums/OrdersLabelStatuses";
import history from "../../../../../history";
import {parseParams} from "../../../../../../src_shared/list/ListActions";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import {getCorrectCountry} from "../../List";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";
import {findOrderMrAutomatePausedStatusById} from "../../../../shared/enums/OrdersMrAutomatePausedStatuses";
import {getProductSourceTypeIcon} from "../../../../shared/enums/ProductSourceType";
import {findYesNoTypesById} from "../../../../shared/enums/YesNoTypes";

const SelectedFilters = ({
                             statuses,
                             productType,
                             payments,
                             isPacked,
                             shipping,
                             isPaid,
                             tag,
                             discounts,
                             country,
                             label,
                             invoice,
                             mr_automate_paused,
                             loadData,
                             priceFrom,
                             priceTo,
                             productsFrom,
                             productsTo,
                             nip,
                             printed,
                             label_printed,
                             employees,
                         }) => {


    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const {data} = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot));
    const [tagsOptions, setTagsOptions] = useState([]);
    const [discountOptions, setDiscountsOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [employeesOptions, setEmployeesOptions] = useState([]);

    const queryParams = history.location.search;
    const parsedParams = parseParams(queryParams)

    const preparedShipping = parsedParams['shipping[]'];
    const preparedPayment = parsedParams['payment[]'];
    const preparedTag = parsedParams['tag[]'];
    const preparedDiscounts = parsedParams['discounts[]'];
    const preparedCountry = parsedParams['country[]'];
    const preparedEmployees = parsedParams['employees[]'];

    const removeFiledFromArray = (field, value) => {
        const filteredArray = data[field].filter((i) => i !== value);
        dispatch(FiltersActions.data.updateValues({
            [field]: filteredArray
        }))
        loadData();
    }

    const getOptions = async (setOptions, rest) => {
        try {
            const {data} = await api.get(`${selmoUrl}/${rest}`)
            setOptions(data.items);
        } catch (e) {
            console.warn('Cannot get options')
        }
    }

    const removeSingleFilter = (field, value) => {
        dispatch(FiltersActions.data.setValue(field, value))
        loadData();
    }

    const removeOrderFromToPrice = () => {
        dispatch(FiltersActions.data.updateValues({
            priceFrom: '',
            priceTo: '',
        }))
        loadData();
    }

    const removeProductsFromToCount = () => {
        dispatch(FiltersActions.data.updateValues({
            productsFrom: '',
            productsTo: '',
        }))
        loadData();
    }

    const findValueInArray = (array, value) => array?.find((status) => status.id === value) || {};

    useEffect(() => {
        if (!!preparedShipping) {
            getOptions(setShippingOptions, services.API_SHIPPING_LIST)
        }
        if (!!preparedPayment) {
            getOptions(setPaymentOptions, services.API_ACTIVE_PAYMENT_METHODS)
        }
        if (!!preparedEmployees) {
            getOptions(setEmployeesOptions, services.API_ORDER_EMPLOYEES)
        }
        if (!!preparedTag) {
            getOptions(setTagsOptions, services.API_ORDERS_TAGS)
        }
        if (!!preparedDiscounts) {
            getOptions(setDiscountsOptions, services.API_DISCOUNT_CODES)
        }
        if (!!preparedCountry) {
            getOptions(setCountryOptions, services.API_FILTER_ORDER_COUNTRIES)
        }
    }, [shipping, tag, discounts, country, employees, payments])

    return (
        <div className="selected-filters d-flex align-items-center flex-wrap">
            {statuses?.map((i, index) => (
                <div
                    key={index}
                    className="tag grey">
                    {getLangText(findOrderStatusById(i).label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('statuses', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {payments?.map((i, index) => (
                <div
                    key={index}
                    className="tag grey"
                >
                    {getLangText(getCorrectOrderPaymentName(i)) || paymentOptions?.find((option) => option.type === i)?.label}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('payments', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {isPacked !== 'all' && (
                <div className="tag grey">
                    {getLangText(findOrderPackedStatusById(isPacked).label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('isPacked', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {shipping?.map((i, index) => (
                <div
                    key={index}
                    className="tag grey">
                    {findValueInArray(shippingOptions, i).name}
                    <div
                        className="country-label ml-1">{getCorrectCountry(findValueInArray(shippingOptions, i).country_name)}</div>
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('shipping', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {employees?.map((i, index) => (
                <div
                    key={index}
                    className="tag grey">
                    {findValueInArray(employeesOptions, i).name}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('employees', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {isPaid !== 'all' && (
                <div className="tag grey">
                    {getLangText(findPaidStatusById(isPaid).label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('isPaid', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {tag?.map((i, index) => (
                <div
                    key={index}
                    className={`tag ${findValueInArray(tagsOptions, i).className}`}>
                    {findValueInArray(tagsOptions, i).text}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('tag', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {label !== 'all' && (
                <div className="tag grey">
                    {getLangText('labelLabel')}:&nbsp;
                    {getLangText(findOrderLabelStatusById(label).label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('label', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {invoice !== 'all' && (
                <div className="tag grey">
                    {getLangText('invoiceLabel')}:&nbsp;
                    {getLangText(findOrderInvoiceStatusById(invoice).label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('invoice', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {discounts?.map((i, index) => (
                <div
                    key={index}
                    className="tag grey">
                    {getLangText('codeLabel')}: {findValueInArray(discountOptions, i).name}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('discounts', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {country?.map((i, index) => (
                <div
                    key={index}
                    className="tag grey">
                    {getLangText('input_label_shipping_country')}: {findValueInArray(countryOptions, i).name}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('country', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {mr_automate_paused !== 'all' && (
                <div className="tag grey">
                    {getLangText('panAutomacikLabel')}:&nbsp;
                    {getLangText(findOrderMrAutomatePausedStatusById(mr_automate_paused).label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('mr_automate_paused', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {(priceFrom || priceTo) && (
                <div className="tag grey">
                    {getLangText('orderPrice')}:&nbsp;
                    {priceFrom && `${getLangText('fromLabel')} ${priceFrom}`}
                    {priceTo && ` ${getLangText('toLabel')} ${priceTo}`}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={removeOrderFromToPrice}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {(productsFrom || productsTo) && (
                <div className="tag grey">
                    {getLangText('productsCount')}:&nbsp;
                    {productsFrom && `${getLangText('fromLabel')} ${productsFrom}`}
                    {productsTo && ` ${getLangText('toLabel')} ${productsTo}`}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={removeProductsFromToCount}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {productType?.map((i, index) => (
                <div
                    key={index}
                    className="tag grey">
                    {getLangText(getProductSourceTypeIcon(i)?.lang)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeFiledFromArray('productType', i)}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            ))}
            {nip !== 'all' && (
                <div className="tag grey">
                    NIP:&nbsp;
                    {getLangText(findYesNoTypesById(nip)?.label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('nip', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {printed !== 'all' && (
                <div className="tag grey">
                    Wydrukowano:&nbsp;
                    {getLangText(findYesNoTypesById(printed)?.label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('printed', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
            {label_printed !== 'all' && (
                <div className="tag grey">
                    {getLangText('labelPrint')}:&nbsp;
                    {getLangText(findYesNoTypesById(label_printed)?.label)}
                    <button
                        type="button"
                        className="hide-tag-input"
                        onClick={() => removeSingleFilter('label_printed', 'all')}
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            )}
        </div>
    );
};

export default SelectedFilters;
