import React, {useEffect, useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useForm from "../../../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {Link, Redirect, useHistory, useParams} from "react-router-dom";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import VideoImageComponent from "./components/videoComponent/VideoImageComponent";
import {services} from "../../../../../../RestServices";
import AssignProducts from "./components/assignProducts/AssignProducts";
import ComponentLoader from "../../../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import SaveAndPublish from "./components/saveAndPublish/SaveAndPublish";
import FacebookAdvertisementsFormWrapper from "./FacebookAdvertisementsFormWrapper";
import Description from "./components/description/Description";
import Budget from "./components/budget/Budget";

const FacebookMarketingDetails = () => {

    const {getLangText} = useLang();
    const {id} = useParams();
    const history = useHistory();
    const [isVideoUploading, setIsVideoUploading] = useState(false);

    const {
        data: {values, updateValues, setValue},
        form: {isWaiting, onSave, getFieldProps, loadData},
        validation,
    } = useForm({
        FormActions,
        params: {
            id,
        },
    });

    const isError = (field) => {
        const errorKey = validation.validateErrors[field](values[field], values);
        return errorKey ? errorKey : false;
    }

    const getError = (field) => !!isError(field) && !!validation.isActive;

    useEffect(() => {
        const unblock = history.block(() => {
            if (isVideoUploading) {
                if (window.confirm(getLangText('videoIsBeingSentWantToQuit'))) {
                    unblock();
                } else {
                    return false;
                }
            }
        });

        return () => {
            unblock();
        };
    }, [isVideoUploading, history]);


    const getArrow = () => (
        <div className="flex justify-center">
            <i className="icon-arrow-down text-[#d1d5db] text-[10px]"/>
        </div>
    )

    if (values.notFound) return <Redirect to={RouterPaths.MarketingFacebookAdvertisements}/>

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.MarketingFacebookAdvertisements}>
                            {getLangText('advertisements')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('editionLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.MarketingFacebookAdvertisements}
                    className="button back-to-panels sm:mb-5 sm:mt-0 mt-3.5">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToList')}
                </Link>
            </div>
            <FacebookAdvertisementsFormWrapper
                isWaiting={isWaiting}
                values={values}
                fetchData={() => loadData(id)}
                setValue={setValue}
            >
                {isWaiting ?
                    <ComponentLoader/> :
                    <div className="bg-[#fff] p-6 rounded-b-medium">
                        <div className="text-lg font-bold">{getLangText('createAd')}</div>
                        <div className="text-desc text-sm font-medium">{getLangText('increaseReach')}</div>
                        <div className="max-w-[550px] mx-auto space-y-8 mt-5">
                            <VideoImageComponent
                                updateValues={updateValues}
                                values={values}
                                restService={services.API_VIDEO_AD_UPLOAD_VIDEO}
                                photoService={services.API_VIDEO_AD_UPLOAD_PHOTO}
                                validation={validation}
                                hasError={getError('media')}
                                setIsVideoUploading={setIsVideoUploading}
                                isVideoUploading={isVideoUploading}
                                id={id}
                            />
                            {getArrow()}
                            <AssignProducts
                                values={values}
                                setValue={setValue}
                                hasError={getError('products')}
                                // isActive={!!values.video_id || isVideoUploading}
                                isActive={true}
                            />
                            {getArrow()}
                            <Description
                                getFieldProps={getFieldProps}
                                setValue={setValue}
                                // isActive={!!values.products?.length}
                                isActive={true}
                                values={values}
                                loadData={loadData}
                                updateValues={updateValues}
                            />
                            {getArrow()}
                            <Budget
                                setValue={setValue}
                                values={values}
                                getFieldProps={getFieldProps}
                            />
                            <SaveAndPublish
                                onSave={onSave}
                                setValue={setValue}
                                getFieldProps={getFieldProps}
                                values={values}
                            />
                        </div>
                    </div>
                }
            </FacebookAdvertisementsFormWrapper>
        </>
    );
};

export default FacebookMarketingDetails;
