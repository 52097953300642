import React from 'react';
import moment from "moment";
import {
    ORDER_TABLE_COLUMN,
    ORDER_TABLE_COLUMN_CLIENT,
    ORDER_TABLE_COLUMN_DATE, ORDER_TABLE_COLUMN_DISCOUNT, ORDER_TABLE_COLUMN_INVOICE, ORDER_TABLE_COLUMN_LABEL,
    ORDER_TABLE_COLUMN_NUMBER,
    ORDER_TABLE_COLUMN_PAYMENT,
    ORDER_TABLE_COLUMN_PAYMENT_DATE,
    ORDER_TABLE_COLUMN_PRICE,
    ORDER_TABLE_COLUMN_SHIPPING,
    ORDER_TABLE_COLUMN_STATUS, ORDER_TABLE_COLUMN_TAGS,
    ORDER_TABLE_LAST_MODIFIED, ORDER_TABLE_NOTE
} from "./OrderTableColumnEnums";
import useTableColumn from "./useTableColumn";
import PackedButton from "../packedButton/PackedButton";
import {findOrderStatusById} from "../../../../shared/enums/OrderStatuses";
import {
    getCorrectOrderPaymentName,
} from "../../../../shared/enums/PaymentStatuses";
import {getCorrectCountry} from "../../List";
import {RouterPaths} from "../../../../../routes/RouterPath";
import ListActions from "../../ListActions";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../listReducer";
import {getStateRoot as getFiltersStateRoot} from "../../../filters/filtersReducer";
import useTableRowClick from "../../../../shared/hooks/useTableRowClick";
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {
    convertToUserTimeZone,
} from "../../../../shared/helpers/dateHelpers";
import {
    AILEMA_SHOP_ID,
    BUTIK_MADAME_SHOP_ID, LADY_WYJATKOWE_KREACJE_SHOP_ID, MAGIC_ROSE_SHOP_ID, SHOP_BAZAR_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";
import {SHOP_ORIGIN_US} from "../../../../shared/enums/ShopOrigins";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import ClientAvatar from "../../../../live/shared/Avatar";

export const shippingLang = (item, getLangText) => {
    const getName = (name) => name ? name : getLangText('lackLabel')
    return (
        <>
            <div>{getName(item.shipping_name)}</div>
            <div className="country-label">{item.shipping_name && getCorrectCountry(item.shipping_country)}</div>
        </>
    )
}

export const getFormattedDate = (date, getLangText) => {
    if (date == null) {
        return getLangText('noPaymentLabel');
    } else {
        const userTimeZoneDate = convertToUserTimeZone(date);
        if (userTimeZoneDate.isSame(convertToUserTimeZone(), 'day')) {
            return `${getLangText('todayLabel')} ${userTimeZoneDate.format('HH:mm')}`;
        } else {
            return convertToUserTimeZone(date).format('DD.MM.YYYY HH:mm');
        }
    }
};

export const getFormatteLabeldDate = (date, getLangText, locale = 'pl') => {
    if (date == null) {
        return getLangText('lackLabel');
    } else {
        const userTimeZoneDate = convertToUserTimeZone(date);
        if (userTimeZoneDate.isSame(convertToUserTimeZone(), 'day')) {
            return `${getLangText('todayLabel')} ${userTimeZoneDate.format('HH:mm')}`;
        } else {
            return convertToUserTimeZone(date, locale).format('DD.MM.YYYY HH:mm')
        }
    }
};

export const getCorrectName = (item, getLangText) => {
    if (item.name) {
        return item.name
    } else if (item.first_name) {
        return <>{item.first_name} {item.last_name}</>
    } else {
        return getLangText('noDataLabel');
    }
};

const TableBody = ({items, toggleSelection, isSelected}) => {
    const dispatch = useDispatch();
    const {preparedTableColumn} = useTableColumn(ORDER_TABLE_COLUMN, 'tableColumn', getFiltersStateRoot)
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const {userData} = useSelector((state) => state.session);
    const {selectedRows} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const onTableRowClick = useTableRowClick(RouterPaths.OrdersList)

    const setSelectedRow = (id) => dispatch(ListActions.setSelectedTableRow(id))

    const buttonClick = (e, item) => {
        e.stopPropagation();
        dispatch(ListActions.setOrderPackingStatus(item))
    }

    const TagsRow = ({item}) => {
        let displayedTagsNumber = 2;
        const rest = item.tags?.length - displayedTagsNumber;

        const getTag = (i) => (
            <div
                key={i.id}
                className={`tag no-remove ${i.className}`}
            >
                {i.text}
            </div>
        )

        return (
            <div className="tags-row mt-0 tooltip-parent">
                {rest > 0 &&
                    <div className="tooltip-content">
                        {item.tags?.map((i) => getTag(i))}
                    </div>
                }
                {item.tags && item.tags?.slice(0, displayedTagsNumber).map((i) => (
                    getTag(i)
                ))}
                {rest > 0 &&
                    <div className="tag no-remove grey">+{rest}</div>
                }
            </div>
        )
    }

    const getFieldContent = (field, item) => {
        switch (field) {
            case ORDER_TABLE_COLUMN_NUMBER:
                return (
                    <td
                        key={field}
                        data-thead="#"
                        className="number with-mobile-thead inline-thead circle-mobile-separator"
                    >
                        {item.shop_order_id}
                    </td>
                )
            case ORDER_TABLE_COLUMN_CLIENT:
                return (
                    <td
                        key={field}
                        className="biggest-col text-nowrap name order-lg-0 order-3 mb-0">
                        <div className="d-flex align-items-center">
                            {getCorrectName(item, getLangText)}
                        </div>
                    </td>
                )
            case ORDER_TABLE_COLUMN_STATUS:
                return (
                    <React.Fragment key={field}>
                        <td className="mobile-break order-6 "/>
                        <td className="status-col w-auto mt-2">
                            <div
                                className={`status-bg ${findOrderStatusById(item.status)?.color}`}>{getLangText(findOrderStatusById(item.status)?.label)}
                            </div>
                            <PackedButton
                                setValue={(e) => buttonClick(e, item)}
                                item={item}
                            />
                        </td>
                    </React.Fragment>
                )
            case ORDER_TABLE_COLUMN_PRICE:
                return <td
                    key={field}
                    className="price text-nowrap">
                    <PackedButton
                        className="d-lg-none d-inline-block mr-1"
                        setValue={(e) => buttonClick(e, item)}
                        item={item}
                    />
                    {getPrice(item.all_products_price || 0, item.currency_text)}
                </td>
            case ORDER_TABLE_COLUMN_TAGS:
                return (
                    <td
                        key={field}
                        className="tags-col text-nowrap">
                        <TagsRow item={item}/>
                    </td>
                )
            case ORDER_TABLE_COLUMN_PAYMENT_DATE:
                return (
                    <td
                        key={field}
                        className="text-nowrap text-sm-right date d-lg-table-cell d-none">
                        {getFormattedDate(item.payment_date, getLangText, userData.origin)}
                    </td>
                )
            case ORDER_TABLE_COLUMN_PAYMENT:
                return (
                    <td
                        key={field}
                        className="text-nowrap payment-col order-lg-0 order-4 circle-mobile-separator mr-lg-0 mr-2 mt-sm-0 mt-2 mobile-small-font-size">
                        {!!+userData.show_payment_status ? (
                            item.products_paid ?
                                <span className="icon-circle paid">
								<i className="icon-tick"/>
							</span> :
                                <span className="icon-circle">
								<i className="icon-cross"/>
							</span>
                        ) : ""}
                        {item.transfer_name || item.payment_name || getLangText(getCorrectOrderPaymentName(item.payment))}
                    </td>
                )
            case ORDER_TABLE_COLUMN_SHIPPING:
                return (
                    <td
                        key={field}
                        className="text-nowrap order-lg-0 order-5 mt-sm-0 mt-2 mobile-small-font-size">
                        {shippingLang(item, getLangText)}
                    </td>
                )
            case ORDER_TABLE_LAST_MODIFIED:
                return (
                    <td
                        key={field}
                        className="text-nowrap text-sm-right date d-lg-table-cell d-none">
                        {getFormattedDate(item.last_modified, getLangText, userData.origin)}
                    </td>
                )
            case ORDER_TABLE_COLUMN_LABEL:
                return (
                    <td
                        key={field}
                        data-thead={getLangText('labelLabel')}
                        className="icon-status with-mobile-thead inline-thead order-lg-0 order-6 mt-2 ml-sm-0 ml-1"
                    >
                        {item.label ?
                            <img src="/assets/images/svg/paid.svg" width={18} alt=""/> :
                            <i className="icon-cross"/>
                        }
                    </td>
                )
            case ORDER_TABLE_COLUMN_INVOICE:
                return (
                    <td
                        key={field}
                        data-thead={getLangText('invoiceLabel')}
                        className="icon-status with-mobile-thead inline-thead order-lg-0 order-6 mt-2 ml-sm-0 ml-1"
                    >
                        {item.invoice ?
                            <img src="/assets/images/svg/paid.svg" width={18} alt=""/> :
                            <i className="icon-cross"/>
                        }
                    </td>
                )
            case ORDER_TABLE_COLUMN_DISCOUNT:
                return (
                    <td
                        key={field}
                        data-thead={getLangText('discountCodeLabel')}
                        className="icon-status with-mobile-thead inline-thead order-lg-0 order-6 mt-2 ml-sm-0 ml-1"
                    >
                        {item.discount_name}
                    </td>
                )
            case ORDER_TABLE_NOTE:
                return (
                    <td
                        key={field}
                        data-thead={getLangText('input_label_package_type_description')}
                        className="with-mobile-thead date order-lg-0 order-10 sm:w-auto w-full mobile-small-font-size"
                    >
                        {item.note || getLangText('lackLabel')}
                    </td>
                )
            case ORDER_TABLE_COLUMN_DATE:
                return (
                    <td
                        key={field}
                        className="text-nowrap text-sm-right date order-lg-0 order-2 ml-lg-0 ml-2 mobile-small-font-size"
                    >
                        {getFormattedDate(item.date, getLangText, userData.origin)}
                    </td>
                )
            default:
                return null;
        }
    };

    return (
        <tbody>
        {items.map((item) => (
            <tr
                className={selectedRows.includes(item.shop_order_id) ? 'clicked' : ''}
                {...onTableRowClick(item.shop_order_id, setSelectedRow)}
            >
                <td className="checkbox-col">
                    <CheckboxField
                        showLabel={false}
                        name="selectedItems"
                        onClick={(e) => e.stopPropagation()}
                        className="mb-0"
                        value={isSelected(item.id)}
                        setValue={(field, value) => toggleSelection(item, field, value)}
                    />
                </td>
                {preparedTableColumn.map((column) => getFieldContent(column, item))}
                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === LADY_WYJATKOWE_KREACJE_SHOP_ID) &&
                    <td
                        data-thead={getLangText('generateLabelDateLabel')}
                        className="text-nowrap with-mobile-thead order-lg-0 order-10 sm:w-auto w-full mobile-small-font-size"
                    >
                        {item?.assigned_employee?.name ?
                            <div className="flex items-center">
                                <ClientAvatar
                                    photo={item?.assigned_employee?.photo}
                                />
                                <span>{item?.assigned_employee?.name}</span>
                            </div> :
                            getLangText('lackLabel')
                        }
                    </td>
                }
                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === LADY_WYJATKOWE_KREACJE_SHOP_ID) &&
                    <td
                        data-thead={getLangText('labelPrintDate')}
                        className="text-nowrap with-mobile-thead text-sm-right date order-lg-0 order-10 sm:w-auto w-full mobile-small-font-size">
                        {getFormatteLabeldDate(item.last_label_print_date, getLangText, userData.origin)}
                    </td>
                }
                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                    <td
                        data-thead={getLangText('generateLabelDateLabel')}
                        className="text-nowrap with-mobile-thead text-sm-right date order-lg-0 order-10 sm:w-auto w-full mobile-small-font-size">
                        {getFormatteLabeldDate(item.inpost_label_date, getLangText, userData.origin)}
                    </td>
                }
                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                    <td
                        data-thead={getLangText('packedDate')}
                        className="text-nowrap with-mobile-thead text-sm-right date order-lg-0 order-10 sm:w-auto w-full mobile-small-font-size">
                        {getFormatteLabeldDate(item.is_packed_date, getLangText, userData.origin)}
                    </td>
                }
                {+userData.id === MAGIC_ROSE_SHOP_ID &&
                    <td
                        data-thead={getLangText('packageClosedDate')}
                        className="text-nowrap with-mobile-thead text-sm-right date order-lg-0 order-10 sm:w-auto w-full mobile-small-font-size">
                        {getFormatteLabeldDate(item.package_closed_date, getLangText, userData.origin)}
                    </td>
                }
                {+userData.id === SHOP_BAZAR_SHOP_ID &&
                    <td
                        data-thead={getLangText('printDate')}
                        className="text-nowrap with-mobile-thead text-sm-right date order-lg-0 order-10 sm:w-auto w-full mobile-small-font-size">
                        {getFormatteLabeldDate(item.last_print_date, getLangText, userData.origin)}
                    </td>
                }
            </tr>
        ))}
        </tbody>
    );
};

export default TableBody;
