import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Model from "../../../../../src_shared/modules/model/Model";
import Modal from "react-bootstrap/Modal";
import Field from "../../../../../src_shared/components/form/field/Field";
import Loader from "../../../shared/Loader";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";

const SignupToReserveListModal = ({hideModal, modalVisible, title}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{onSave, isWaiting, getFieldProps}] = useFormLocal({
        rest: `${services.API_MARKETING_SUBMIT}/${modalVisible}`,
        model: new Model(),
        afterSubmit: () => {
            hideModal();
            dispatch(showAlert('yuhuLabel', 'successfulSubmittedToWaitingList'))
        }
    });

    return (
        <Modal
            show={modalVisible}
            dialogClassName="default-modal w-380"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>{getLangText(title)}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="">
                <Field
                    {...getFieldProps('phone')}
                    label={getLangText('typePhoneNumberLabel')}
                    floatedLabel
                    className="big-size black"
                />
                <button
                    type="button"
                    style={{borderRadius: 8}}
                    className={`button black w-full ${isWaiting ? 'loading-button' : ''}`}
                    onClick={onSave}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('signupForWaitingList')}
                </button>
            </Modal.Body>
        </Modal>
    );
};

export default SignupToReserveListModal;
