import {getStateRoot, prefix} from "./formReducer.js";
import {getStateRoot as getEditOrderStateRoot} from '../form/formReducer'
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import InpostLabelModel from "../../../../../modules/models/order/InpostLabelModel";
import {batch} from "react-redux";
import {INPOST_LABEL_CUSTOM} from "../../../shared/enums/InpostLabelsTypes";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {selmoUrl} from "../../../../../src_shared/api/api";
import EditOrderFormActions from "../form/FormActions";
import api from "../../../../../services/axios/axios";

export class OrderDetailsInpostLabelFormActions extends FormActions {
    createLabel() {
        return async (dispatch, getState) => {

            const {id, shop_order_id} = getEditOrderStateRoot(getState()).data;
            const values = getStateRoot(getState()).data;

            const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
            const finalData = Object.fromEntries(filteredData)

            const preparedData = this.dataModel.buildDTO(finalData)

            dispatch(this.waiting.startWaiting());

            try {
                await api.post(`${selmoUrl}/${this.restService}/${id}`, preparedData)
                batch(() => {
                    dispatch(showAlert('labelHasBennGeneratedLabel', ''))
                    dispatch(EditOrderFormActions.loadData(shop_order_id))
                })
            } catch (e) {
                if (e.response.data.message === 'notAllProductsHasBeenPacked') {
                    dispatch(showAlert('notAllProductsHasBeenPacked', 'packAllProductsAndGenerateLabel', 'alert-fail'))
                    return;
                }
                dispatch(showAlert('sthWentWrongWarning', e.response.data.message, 'alert-fail'))
                dispatch(EditOrderFormActions.loadData(shop_order_id))
                console.error('Error submitEditForm')
            } finally {
                dispatch(this.waiting.stopWaiting());
            }
        }
    }

    submitEditForm(values, history) {
        return async (dispatch) => {

            dispatch(this.validation.activateValidation());

            if (!this.dataModel.validateData(values)) {
                dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
                console.error('Validation failed!');
                return;
            }

            dispatch(this.afterSubmit(values))

        }
    }

    afterSubmit(data) {
        return (dispatch) => {
            batch(() => {
                dispatch(this.modal.hideModal())
                dispatch(this.validation.deactivateValidation());
                dispatch(this.data.setValue('inpost_size', INPOST_LABEL_CUSTOM))
            })
        };
    }
}

export const getInstance = () => new OrderDetailsInpostLabelFormActions({
    getStateRoot,
    prefix,
    model: InpostLabelModel,
    restService: services.API_ORDER_INPOST_SHIPPMENT_LABEL,
});

export default getInstance();
