import React from 'react';
import {Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import FacebookMarketingDetails from "../app/marketing/facebook/submitted/advertisements/form/Form";
import FacebookMarketingDetailsStats
    from "../app/marketing/facebook/submitted/advertisements/stats/FacebookMarketingDetailsStats";
import {AdsPermissionRoute} from "./AdsPermissionRoute";

const MarketingDetailsRoute = ({path}) => {

    return (
        <Route path={path}>
            <AdsPermissionRoute>
                <Switch>
                    <Route
                        component={FacebookMarketingDetailsStats}
                        exact
                        path={RouterPaths.MarketingFacebookAdvertisementStatistics}
                    />
                    <Route
                        component={FacebookMarketingDetails}
                        exact
                        path={RouterPaths.MarketingFacebookAdvertisement}
                    />
                </Switch>
            </AdsPermissionRoute>
        </Route>
    );
};

export default MarketingDetailsRoute;
