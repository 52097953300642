import React from "react";
import {useDispatch} from "react-redux";
import Loader from "../../../messenger/loader/Loader";
import {useRemoveModal} from "../../../shared/hooks/useRemoveModal";
import {services} from "../../../../RestServices";
import EditOrderFormActions from "../form/FormActions";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import moment from "moment";
import StylesLoader from "../../../shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import Field from "../../../../../src_shared/components/form/field/Field";
import MondialLabelModel from "../../../../../modules/models/order/MondialLabelModel";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const MondialLabel = ({data}) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const loadData = () => dispatch(EditOrderFormActions.loadData(data.shop_order_id))

    const [{onSave, isWaiting, values, getFieldProps}] = useFormLocal({
        rest: `${services.API_SHIPMENT_MONDIAL_RELAY}/${data.id}`,
        model: MondialLabelModel,
        initialAutoLoad: false,
        afterSubmit: loadData,
        onError: (e) => dispatch(showAlert(getLangText(e.response?.data?.message), '', 'alert-fail'))
    });

    const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(`${services.API_SHIPMENT_MONDIAL_RELAY}/${data.id}`, loadData)

    const {download, waiting: waitingForLabel} = useDownloadFile({
        rest: `${services.API_SHIPMENT_MONDIAL_RELAY}/${data.id}`,
        fileName: 'Mondial-Relay',
    })

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: `${services.API_SHIPMENT_MONDIAL_RELAY}/${data.id}`,
    })

    if (!+data.shippingData.shop_mondial_active) {
        return null
    }

    return (
        <>
            {!data.shippingData.mondial_tracking_id ?
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>Mondial Relay</h2>
                            <div className="section-header__description">
                                {getLangText('addMondialLabelLabel')}
                            </div>
                        </div>
                        <div>
                            <img width={70} src="/assets/images/svg/logomondial-relay.svg" alt="Mondial"/>
                        </div>
                    </div>
                    {!data.shipping_address_exist ?
                        <div
                            className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                            </div>
                        </div> :
                        <form onSubmit={onSave}>
                            <div className="data-boxes-wrapper separated small-padd">
                                <div className="flex items-end">
                                    <Field
                                        {...getFieldProps('weight')}
                                        label={getLangText('packedWeightLabel')}
                                        className="mb-0 small-size mt-1 min-w-[100px] hidden-arrows"
                                        addon="KG"
                                        type="number"
                                        extraProps={{required: true, step: 0.001}}
                                    />
                                    <button
                                        type="submit"
                                        className={`button primary ml-3 w-full ${!values.weight ? 'not-active' : ''} ${isWaiting ? 'loading-button' : ''}`}
                                    >
                                        {isWaiting && <Loader/>}
                                        {getLangText('createLabelLabel')}
                                    </button>
                                </div>
                                {/*{MONDIAL_RELAY_LABEL.map((box) => (*/}
                                {/*    <div*/}
                                {/*        key={box.id}*/}
                                {/*        className={`content ${values.mode === box.id ? 'active' : ''}`}*/}
                                {/*    >*/}
                                {/*        <div className="content__top-part">*/}
                                {/*            <input*/}
                                {/*                onChange={() => setValue('mode', box.id)}*/}
                                {/*                checked={values.mode === box.id}*/}
                                {/*                type="radio"*/}
                                {/*                value={values.mode}*/}
                                {/*                required={true}*/}
                                {/*            />*/}
                                {/*            <span className="checkmark"/>*/}
                                {/*            <div className="flex-grow-1">*/}
                                {/*                <div className="label text-xs">{getLangText(box.label)}</div>*/}
                                {/*                {values.mode === box.id &&*/}
                                {/*                    <Field*/}
                                {/*                        {...getFieldProps('weight')}*/}
                                {/*                        label={getLangText('weightLabel')}*/}
                                {/*                        className="mb-0 small-size mt-1 max-w-[120px]"*/}
                                {/*                        floatedLabel*/}
                                {/*                        addon="KG"*/}
                                {/*                        extraProps={{required: true}}*/}
                                {/*                    />*/}
                                {/*                }*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*))}*/}
                            </div>
                        </form>
                    }
                </section> :
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>Mondial Relay</h2>
                            <div className="section-header__description">
                                {getLangText('manageMondialLabelLabel')}
                            </div>
                        </div>
                        <div>
                            <a
                                href="https://www.mondialrelay.fr/"
                                target="_blank"
                                rel="nofollow"
                            >
                                <img width={70} src="/assets/images/svg/logomondial-relay.svg" alt="Mondial"/>
                            </a>
                        </div>
                    </div>
                    <div className="loader-parent">
                        <StylesLoader
                            className="small-loader"
                            isLoading={waitingForLabel || waitingForPrint}
                        />
                        <div className="tripple-buttons">
                            <div>
                                <button
                                    onClick={print}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('printButton')}
                                    <i className="icon-print"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={download}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('previewButton')}
                                    <i className="icon-eye"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setModalOpen(true)}
                                    type="button"
                                    className="button border-button big-width"
                                >
                                    {getLangText('removeButton')}
                                    <i className="icon-bin"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    {data.shippingData.mondial_label_date &&
                        <div className="info-label mt-3">
                            <i className="icon-tick-c"/>
                            {getLangText('labelHasBennGeneratedLabel')}
                            <div className="date-box">
                                <span>{convertToUserTimeZone(data.shippingData.mondial_label_date).format('DD.MM.YYYY')}</span>
                                <span>{convertToUserTimeZone(data.shippingData.mondial_label_date).format('HH:mm:ss')}</span>
                            </div>
                        </div>
                    }
                </section>
            }
            {modalOpen &&
                <ConfirmModal
                    title={getLangText('askForRemovingMondialLabelLabel')}
                    SubTitle={() => <>{getLangText('actionCannotBeUnDoneLabel')}</>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen}
                    isWaiting={waiting}
                    onSubmit={remove}
                    hide={() => setModalOpen(false)}
                    submitButtonClass="danger"
                >
                    <div
                        className="form-info-box red small-line-height w-100 mb-0 mb-3">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div>
                            <div
                                className="title">{getLangText('attentionLabel')}</div>
                            <div
                                className="desc">{getLangText('toDeleteMondialRelay')}</div>
                        </div>
                    </div>
                </ConfirmModal>
            }
        </>
    );
};

export default MondialLabel;



