import React from 'react';
import moment from "../../../../messenger/helpers/moment.js";
import {useHistory} from "react-router-dom";
import EmptyList from "../../../../shared/EmptyList.jsx";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";
import {USER_PERMISSIONS_LIVE_PRICE_OVERALL} from "../../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";

const Table = ({updateSortOptions, isWaiting, getSortClass, items}) => {
    const history = useHistory();
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/live-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddLiveLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table">
                        <thead>
                        <tr>
                            <th
                                name="title"
                                onClick={updateSortOptions}
                                className={getSortClass('title')}
                            >
							<span name="title">
								{getLangText('liveNameLabel')}
							</span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={getSortClass('date')}
                            >
							<span name="date">
								{getLangText('liveAddNewModalDateLabel')}
							</span>
                            </th>
                            <th
                                name="client_count"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('client_count')}`}
                            >
							<span name="client_count">
								{getLangText('clientsLabel')}
							</span>
                            </th>
                            <th
                                name="piece_count"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('piece_count')}`}
                            >
							<span name="piece_count">
								{getLangText('soldPiecesLabel')}
							</span>
                            </th>
                            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_LIVE_PRICE_OVERALL]}>
                                <th
                                    name="total_price"
                                    onClick={updateSortOptions}
                                    className={`text-right ${getSortClass('total_price')}`}
                                >
							<span name="total_price">
								{getLangText('totalValueLabel')}
							</span>
                                </th>
                            </UserPermissionsWrapper>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr onClick={() => history.push(`/transmisje/${item.shop_live_id}`)}
                                key={item.id}>
                                <td className="name-td">
                                    {item.title ? item.title : getLangText('noNameLabel')}
                                </td>
                                <td className="date-td date">
                                    <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                </td>
                                <td className="text-right client-td">{item.client_count}<span
                                    className="d-lg-none">{getLangText('clientsLabel')}</span></td>
                                <td className="text-right sold-td">{item.piece_count}<span
                                    className="d-lg-none">{getLangText('soldPiecesLabel')}</span>
                                </td>
                                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_LIVE_PRICE_OVERALL]}>
                                    <td className="text-right total-price-td">{getPrice(item.total_price)}</td>
                                </UserPermissionsWrapper>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
