import React, {useState} from 'react';
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {arrayMoveImmutable as arrayMove} from "array-move";
import useLang from "../../../../../../../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../../../../../../../shared/components/serverImage/ServerImage";
import {useUnitPrice} from "../../../../../../../../../shared/helpers/Price";


const SortableItem = SortableElement(({item, onSortProductEnd, i, removeItem}) => {
    const {getPrice} = useUnitPrice();
    const {getLangText} = useLang();
    const [activeHover, setActiveHover] = useState(false);

    return (
        <li className="sortable-tree-item new-styles">
            <div className="flex items-center">
                <div className="mr-2 text-[#9CA3AF]">
                    <i className="icon-drag"/>
                </div>
                <div
                    className={`hover:no-underline relative flex items-center grow transition-all delay-100 duration-500 border p-[12px] rounded-[5px]`}>
                    <figure className="min-w-[36px] w-[36px] h-[36px] rounded-[5px] overflow-hidden">
                        {item.photo_small && item.photo_small !== '0' ?
                            <ServerImage className="w-full h-full object-cover" src={item.photo_small}/> :
                            <img alt={item.name} className="w-full h-full object-cover"
                                 src="/assets/images/default/product.svg"/>
                        }
                    </figure>
                    <div className="ml-2">
                        <div className="font-bold text-[#000] text-sm leading-[16px]">{item.name}</div>
                        <div className="font-medium text-desc text-xs">{item.selmo_code}</div>
                    </div>
                    <div className="ml-auto text-xs text-desc font-medium sm:flex items-center text-right">
                        <div className="whitespace-nowrap sm:mr-4">
                        <span
                            className="text-[#1F2937]">{getLangText('availableLabel')}</span>:&nbsp;{item.squ}</div>
                        <div className="whitespace-nowrap">{getPrice(item.price)}</div>
                    </div>
                </div>
                <button
                    type="button"
                    className="hover:text-[#000] transition-all text-[#9CA3AF] py-3 pl-[10px] sm:pl-[20px] text-xs cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        removeItem(item.product_id)
                    }}
                >
                    <i className="icon-cross pointer-events-none"/>
                </button>
            </div>
        </li>
    )
});

const SortableListWrapper = SortableContainer(({items, onSortProductEnd, removeItem}) => {
    return (
        <ul className="category-tree">
            {items.map((item, index) => (
                <SortableItem
                    key={item.id}
                    index={index}
                    i={index}
                    item={item}
                    onSortProductEnd={(i) => onSortProductEnd(i, item)}
                    removeItem={removeItem}
                />
            ))}
        </ul>
    );
});

const Products = ({products, setProducts, setModalVisible}) => {
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const updateAllItems = async (updatedItems) => {
        setProducts(updatedItems);
    }

    const onSortEnd = async ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) return;
        const updatedItems = arrayMove(products, oldIndex, newIndex);
        await updateAllItems(updatedItems)
    };

    const removeItem = (id) => {
        const updated = products.filter((product) => +product.product_id !== +id);
        setProducts(updated)
    }

    return (
        <div>
            <div className="space-y-1.5">
                {products.map((item) => (
                    <div className="flex items-center">
                        <div
                            className={`hover:no-underline relative flex items-center grow transition-all delay-100 duration-500 border p-[12px] rounded-[5px]`}>
                            <figure className="min-w-[36px] w-[36px] h-[36px] rounded-[5px] overflow-hidden">
                                {item.photo_small && item.photo_small !== '0' ?
                                    <ServerImage className="w-full h-full object-cover" src={item.photo_small}/> :
                                    <img alt={item.name} className="w-full h-full object-cover"
                                         src="/assets/images/default/product.svg"/>
                                }
                            </figure>
                            <div className="ml-2">
                                <div className="font-bold text-[#000] text-sm leading-[16px]">{item.name}</div>
                                <div className="font-medium text-desc text-xs">{item.selmo_code}</div>
                            </div>
                            <div className="ml-auto text-xs text-desc font-medium sm:flex items-center text-right">
                                <div className="whitespace-nowrap sm:mr-4">
                        <span
                            className="text-[#1F2937]">{getLangText('availableLabel')}</span>:&nbsp;{item.squ}</div>
                                <div className="whitespace-nowrap">{getPrice(item.price)}</div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="hover:text-[#000] transition-all text-[#9CA3AF] py-3 pl-[10px] sm:pl-[20px] text-xs cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                removeItem(item.product_id)
                            }}
                        >
                            <i className="icon-cross pointer-events-none"/>
                        </button>
                    </div>
                ))}
            </div>
            {!!products?.length &&
                <button
                    type="button"
                    onClick={() => setModalVisible(true)}
                    className="button text-blue-button mt-3"
                >
                    <i className="icon-plus"/>
                    {getLangText('liveAssignProductLabel')}
                </button>
            }
        </div>
    );
};

export default Products;
