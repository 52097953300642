import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import Loader from "../../../messenger/loader/Loader";

const options = ['asideOrdersLabel', 'asideProductsLabel', 'submittedCustomersLabel']

const ExtendPlanModal = ({modalVisible, hideModal}) => {

    const {getLangText} = useLang();

    return (
        <Modal
            dialogClassName={`default-modal w-850`}
            contentClassName="rounded-xl p-0"
            show={modalVisible}
            onHide={hideModal}
        >
            <div className="grid sm:grid-cols-5">
                <div className="sm:col-span-3">
                    <div className="p-6 sm:px-12 sm:py-16">
                        <div
                            className="text-[#F87171] font-bold text-sm mb-2">{getLangText('christmasBreakLabel')}</div>
                        <div
                            className="text-[#101827] font-bold text-[30px] sm:text-[32px] leading-[38px] whitespace-pre-wrap tracking-[-0.74px] mb-3">{getLangText('extendAccessToSpentChristmasBreak')}</div>
                        <div
                            className="text-[#101827] font-medium leading-[22px] mb-4 text-sm"
                            dangerouslySetInnerHTML={{__html: getLangText('extendAccessToAccountSubLabel')}}/>
                        <div class="bg-[#FEF2F2] p-3 rounded-[7px]">
                            <div
                                className="text-[#101827] font-bold text-sm leading-[18px] mb-3">{getLangText('afterThisWillBeRemovedAllData')}</div>
                            <div className="space-y-3">
                                {options.map((option) => (
                                    <div className="flex items-center text-sm text-[#981B1B] font-bold">
                                        <img className="min-w-[20px] w-[20px] mr-2" src="/assets/images/svg/cross.svg"
                                             alt=""/>
                                        {getLangText(option)}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="sm:hidden mt-5">
                            <button
                                onClick={hideModal}
                                type="button"
                                className="button absolute text-[#6B7280] text-[8px] right-3 top-3"
                            >
                                <i className="icon-cross"/>
                            </button>
                            <div
                                className="bg-[#F9FAFB] relative sm:rounded-r-[16px] rounded-[8px] p-[12px] pt-[28px] pb-[0px] flex flex-col">
                                <div className="flex">
                                    <div className="text-[#1F2937] leading-[22px] text-sm font-medium mb-3"
                                         dangerouslySetInnerHTML={{__html: getLangText('extendAndGetFourteenDaysFreeSubLabel')}}
                                    />
                                    <div
                                        className="absolute top-[-17px] left-1/2 -translate-x-1/2  bg-[#5FA5FA] text-xs mt-auto self-start text-[#fff] font-normal px-[14px] py-[10px] rounded-l-[16px] rounded-t-[16px] rounded-br-[3px]"
                                        dangerouslySetInnerHTML={{__html: getLangText('getFourteenDaysForFree')}}/>
                                    <div className="self-end min-w-[90px] grow h-full w-[90px] relative ml-auto">
                                        <img
                                            className="absolute bottom-0 right-0 left-0 w-full"
                                            src="/assets/images/svg/mr-automate-christmas.webp" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link
                            to={`${RouterPaths.Payments}?christmas=1`}
                            className="button block leading-[18px] text-center mt-4 w-full bg-[#000] text-[white] font-bold py-[14px] sm:py-[16px] px-[13px] rounded-[8px] hover:text-[white] hover:bg-[#35383b] sm:order-2 transition-all"
                        >
                            {getLangText('extendAndGetFourteenDaysFree')}
                        </Link>
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button block text-center text-[#9CA3AF] text-sm font-bold mx-auto mt-3 hover:text-[black] transition-all sm:order-1"
                        >
                            {getLangText('noThankYouLabel')}
                        </button>
                    </div>
                </div>
                <div className="sm:col-span-2 relative sm:block hidden">
                    <div className="bg-[#F9FAFB] h-full sm:rounded-r-[16px] p-6 sm:pt-16 sm:pb-[0px] flex flex-col">
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button absolute text-[#6B7280] text-[8px] right-3 top-3"
                        >
                            <i className="icon-cross"/>
                        </button>
                        <div className="text-[#1F2937] font-medium mb-3 sm:mt-[30px]"
                             dangerouslySetInnerHTML={{__html: getLangText('extendAndGetFourteenDaysFreeSubLabel')}}
                        />
                        <div
                            className="bg-[#5FA5FA] mt-auto self-start text-[#fff] font-normal px-3 py-[12px] rounded-l-[16px] rounded-t-[16px] rounded-br-[3px]"
                            dangerouslySetInnerHTML={{__html: getLangText('getFourteenDaysForFree')}}/>
                        <img
                            className="w-[206px] ml-auto mt-6"
                            src="/assets/images/svg/mr-automate-christmas.webp" alt=""/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ExtendPlanModal;