import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import Marketing from "../app/marketing/Marketing";
import FacebookMarketing from "../app/marketing/facebook/FacebookMarketing";
import MarketingOther from "../app/marketing/other/Other";
import FirstSteps from "../app/marketing/facebook/submitted/firstSteps/FirstSteps";
import Statistics from "../app/marketing/facebook/submitted/statistics/Statistics";
import SubmittedWrapper from "../app/marketing/facebook/submitted/SubmittedWrapper";
import FacebookAdvertisementsList from "../app/marketing/facebook/submitted/advertisements/list/List";
import {AdsPermissionRoute} from "./AdsPermissionRoute";

const MarketingRoute = ({path}) => {

    return (
        <Route path={path}>
            <Marketing>
                <Switch>
                    <Route
                        exact
                        path={RouterPaths.MarketingSelmoApp}
                        component={MarketingOther}
                    />
                    <Route
                        exact
                        path={RouterPaths.MarketingSms}
                        component={MarketingOther}
                    />
                    <Route
                        exact
                        path={RouterPaths.MarketingEmail}
                        component={MarketingOther}
                    />
                    <Route
                        component={FacebookMarketing}
                        exact
                        path={RouterPaths.MarketingFacebook}
                    />
                    <Route
                        path={RouterPaths.MarketingFacebook}
                    >
                        <AdsPermissionRoute>
                            <SubmittedWrapper>
                                <Switch>
                                    <Route
                                        exact
                                        component={Statistics}
                                        path={RouterPaths.MarketingFacebookStatistics}
                                    />
                                    <Route
                                        exact
                                        path={RouterPaths.MarketingFacebookAdvertisements}
                                        component={FacebookAdvertisementsList}
                                    />
                                    <Route
                                        exact
                                        component={FirstSteps}
                                        path={RouterPaths.MarketingFacebookFirstSteps}
                                    />
                                </Switch>
                            </SubmittedWrapper>
                        </AdsPermissionRoute>
                    </Route>
                    <Redirect to={RouterPaths.MarketingFacebook}/>
                </Switch>
            </Marketing>
        </Route>
    );
};

export default MarketingRoute;
