const initialState = {
    errors: [],
    isSuccess: false,
    isLoading: false,
    isError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SETTINGS_PRODUCTS_IMPORT_UPDATE_DATA':
            return {
                ...state,
                ...action?.data,
            };
        default:
            return state;
    }
}
