import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../src_shared/form/FormActions";
import {services} from "../../../RestServices";
import SalesPostModel from "../../../../modules/models/salesPost/SalesPostModel";
import {RouterPaths} from "../../../routes/RouterPath";

export class SalesPostFormActions extends FormActions {
    updateVideoStatus = (pusherData) => {
        return (dispatch, getState) => {
            const {data} = getStateRoot(getState());

            if (+data.id === +pusherData.post_id) {
                dispatch(this.data.updateValues({
                    is_being_transmitted: pusherData.is_being_transmitted,
                    video_url: pusherData.video_url,
                    video_id: pusherData.id,
                    video_name: pusherData.video_name,
                    poster_url: pusherData.poster_url,
                    is_error: pusherData.is_error
                }))
            }
        }
    }

    updateWaitingForSend = (pusherData) => {
        return (dispatch, getState) => {
            const {data} = getStateRoot(getState());

            if (+data.id === +pusherData.post_id) {
                dispatch(this.loadData(data.id))
            }
        }
    }

}

export const getInstance = () => new SalesPostFormActions({
    prefix,
    getStateRoot,
    model: SalesPostModel,
    route: RouterPaths.SalesPost,
    restService: services.API_VIDEO_SOCIAL_VIDEOS,
});

export default getInstance();

