import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

class UspsConfigurationModel extends Model {
	getModel() {
		return {
			client_id: '',
			client_secret: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			client_id: ValidationError.notEmpty,
			client_secret: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}

	buildDTO(data) {
		return {
			client_id: data.client_id,
			client_secret: data.client_secret,
			active: data.active,
		}
	}
}

export default new UspsConfigurationModel();
