import React from 'react';
import FormActions from "./FormActions";
import {getStateRoot as getFiltersStateRoot} from "../../../filters/filtersReducer.js";
import {getStateRoot as getListStateRoot} from "../../../list/listReducer.js";
import DefaultFilter
    from "../../../../../../src_shared/components/filters/panelFilters/defaultFilter/DefaultFilter.jsx";
import ToggleFilterButton from "../components/ToggleFilterButton.jsx";
import useForm from "../../../../../../src_shared/form/useForm";
import {getStateRoot} from "./formReducer";
import {batch, useDispatch, useSelector} from "react-redux";
import {
    getCorrectOrderPaymentName,
} from "../../../../shared/enums/PaymentStatuses";
import SingleSelectDefaultFilter
    from "../../../../../../src_shared/components/filters/panelFilters/singleSelectDefaultFilter/SingleSelectDefaultFilter";
import {ORDER_PACKED_STATUES} from "../../../../shared/enums/PackedStatuses";
import {ORDER_STATUES} from "../../../../shared/enums/OrderStatuses";
import AsyncPanelFilter
    from "../../../../../../src_shared/components/filters/panelFilters/asyncSelectDefaultFilter/SingleSelectDefaultFilter";
import {services} from "../../../../../RestServices";
import {getCorrectCountry} from "../../List";
import {ORDER_PRODUCTS_PAID_STATUES} from "../../../../shared/enums/PaidStatuses";
import {createSelector} from "reselect";
import {ORDER_LABEL_STATUES} from "../../../../shared/enums/OrdersLabelStatuses";
import {ORDER_INVOICE_STATUES} from "../../../../shared/enums/OrdersInvoiceStatuses";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {ORDER_MR_AUTOMATE_PAUSED_STATUSES} from "../../../../shared/enums/OrdersMrAutomatePausedStatuses";
import PriceFromToFilter from "./components/PriceFromToFilter";
import ProductsFromToFilter from "./components/ProductsFromToFilter";
import {ProductsSourceTypes} from "../../../../shared/enums/ProductSourceType";
import YES_NO_TYPES from "../../../../shared/enums/YesNoTypes";
import {
    LADY_WYJATKOWE_KREACJE_SHOP_ID,
    SHOP_BAZAR_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";
import ClientAvatar from "../../../../live/shared/Avatar";

const FiltersPanel = ({FiltersActions, ListActions}) => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const {hasTags} = useSelector(createSelector(getListStateRoot, (stateRoot) => stateRoot));
    const {width} = useWindowDimensions();
    const {getLangText} = useLang();

    const {
        form: {getFieldProps},
        data: {values, defaultValues, setValue},
        modal: {hideModal}
    } = useForm({
        FormActions,
        params: {},
        preventAutoLoad: true,
    });


    const saveFilters = () => {
        dispatch(FiltersActions.data.updateValues(values))
        dispatch(ListActions.loadPage(1));
        if (width < 767) {
            hideModal();
        }
    };

    const clearFilters = () => {
        batch(() => {
            dispatch(FiltersActions.data.updateValues(defaultValues))
            dispatch(FormActions.data.updateValues(defaultValues))
            dispatch(ListActions.loadPage(1));
            if (width < 767) {
                hideModal();
            }
        })
    };

    return (
        <div className="aside-panel">
            <div className="aside-header">
                <button
                    type="button"
                    className="icon-button"
                    onClick={hideModal}
                >
                    <i className=" icon-cross"/>
                </button>
                <div className="title">{getLangText('filterOrdersLabel')}</div>
            </div>
            <div className="aside-wrapper">
                <div className="aside-scroll">
                    <ToggleFilterButton
                        buttonTitle={getLangText('statusLabel')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="statuses"
                        selectAllName="filtersSelectAll"
                    >
                        <DefaultFilter
                            ListActions={ListActions}
                            FiltersActions={FiltersActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            options={ORDER_STATUES}
                            name="statuses"
                            selectAllName="filtersSelectAll"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                        />
                    </ToggleFilterButton>
                    <ToggleFilterButton
                        buttonTitle={getLangText('customerBasketPaymentLabel')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="payments"
                        selectAllName="paymentsSelectAll"
                    >
                        <AsyncPanelFilter
                            ListActions={ListActions}
                            FiltersActions={FiltersActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="payments"
                            selectAllName="paymentsSelectAll"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                            rest={services.API_ACTIVE_PAYMENT_METHODS}
                            optionLabel={(item) => (
                                <label className="control-label">
                                    {getLangText(getCorrectOrderPaymentName(item.label)) || item.label}
                                </label>
                            )}
                            optionsParser={(items) => items.map((i) => ({
                                value: i.type,
                                label: i.label || i.type,
                            }))}
                        />
                        {/*<DefaultFilter*/}
                        {/*	ListActions={ListActions}*/}
                        {/*	FiltersActions={FiltersActions}*/}
                        {/*	getFiltersStateRoot={getFiltersStateRoot}*/}
                        {/*	options={preparedOrderPaymentStatus}*/}
                        {/*	name="payments"*/}
                        {/*	selectAllName="paymentsSelectAll"*/}
                        {/*	FormActions={FormActions}*/}
                        {/*	getStateRoot={getStateRoot}*/}
                        {/*/>*/}
                    </ToggleFilterButton>
                    <ToggleFilterButton
                        buttonTitle={getLangText('packageStateLabel')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="isPacked"
                    >
                        <SingleSelectDefaultFilter
                            getFiltersStateRoot={getFiltersStateRoot}
                            options={ORDER_PACKED_STATUES}
                            name="isPacked"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                        />
                    </ToggleFilterButton>
                    <ToggleFilterButton
                        buttonTitle={getLangText('shipmentLabel')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="shipping"
                        selectAllName="shippingSelectAll"
                    >
                        <AsyncPanelFilter
                            ListActions={ListActions}
                            FiltersActions={FiltersActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="shipping"
                            selectAllName="shippingSelectAll"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                            rest={services.API_SHIPPING_LIST}
                            optionLabel={(item) => (
                                <label className="control-label">
                                    {item.label}
                                    <div className="country-label">{getCorrectCountry(item.country)}</div>
                                </label>
                            )}
                        />
                    </ToggleFilterButton>
                    {!!+userData.show_payment_status &&
                        <ToggleFilterButton
                            buttonTitle={getLangText('paymentStatusLabel')}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="isPaid"
                        >
                            <SingleSelectDefaultFilter
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={ORDER_PRODUCTS_PAID_STATUES}
                                name="isPaid"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                            />
                        </ToggleFilterButton>
                    }
                    {hasTags &&
                        <ToggleFilterButton
                            buttonTitle={getLangText('tagsLabel')}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="tag"
                            selectAllName="tagSelectAll"
                        >
                            <AsyncPanelFilter
                                ListActions={ListActions}
                                FiltersActions={FiltersActions}
                                getFiltersStateRoot={getFiltersStateRoot}
                                name="tag"
                                selectAllName="tagSelectAll"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                                rest={services.API_ORDERS_TAGS}
                                optionsParser={(items) => items.map((i) => ({
                                    value: i.id,
                                    label: i.text,
                                    className: i.className,
                                }))}
                                optionLabel={(item) => (
                                    <label className="control-label">
                                        <div className={`tag m-0 no-remove ${item.className}`}>
                                            {item.label}
                                        </div>
                                    </label>
                                )}
                            />
                        </ToggleFilterButton>
                    }
                    <ToggleFilterButton
                        buttonTitle={getLangText('labelLabel')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="label"
                    >
                        <SingleSelectDefaultFilter
                            getFiltersStateRoot={getFiltersStateRoot}
                            options={ORDER_LABEL_STATUES}
                            name="label"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                        />
                    </ToggleFilterButton>
                    <ToggleFilterButton
                        buttonTitle={getLangText('invoiceLabel')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="invoice"
                    >
                        <SingleSelectDefaultFilter
                            getFiltersStateRoot={getFiltersStateRoot}
                            options={ORDER_INVOICE_STATUES}
                            name="invoice"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                        />
                    </ToggleFilterButton>
                    {!!+userData.discount_codes_enabled &&
                        <ToggleFilterButton
                            buttonTitle={getLangText('discountCodesLabel')}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="discounts"
                        >
                            <AsyncPanelFilter
                                ListActions={ListActions}
                                FiltersActions={FiltersActions}
                                getFiltersStateRoot={getFiltersStateRoot}
                                name="discounts"
                                selectAllName="discountsSelectAll"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                                rest={services.API_DISCOUNT_CODES}
                            />
                        </ToggleFilterButton>
                    }
                    <ToggleFilterButton
                        buttonTitle={getLangText('settings_shipping_countries')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="country"
                    >
                        <AsyncPanelFilter
                            ListActions={ListActions}
                            FiltersActions={FiltersActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="country"
                            selectAllName="countrySelectAll"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                            rest={services.API_FILTER_ORDER_COUNTRIES}
                        />
                    </ToggleFilterButton>
                    {!!+userData.selmo_pro_active &&
                        <ToggleFilterButton
                            buttonTitle={getLangText('stoppedByClient')}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="mr_automate_paused"
                        >
                            <SingleSelectDefaultFilter
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={ORDER_MR_AUTOMATE_PAUSED_STATUSES}
                                name="mr_automate_paused"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                            />
                        </ToggleFilterButton>
                    }
                    <PriceFromToFilter
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        getFiltersStateRoot={getFiltersStateRoot}
                    />
                    <ProductsFromToFilter
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        getFiltersStateRoot={getFiltersStateRoot}
                    />
                    <ToggleFilterButton
                        buttonTitle={getLangText('sourceProduct')}
                        getFiltersStateRoot={getFiltersStateRoot}
                        name="productType"
                        selectAllName="productTypeAll"
                    >
                        <DefaultFilter
                            ListActions={ListActions}
                            FiltersActions={FiltersActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            options={ProductsSourceTypes.map((i) => ({...i, value: i.id, label: i.lang}))}
                            name="productType"
                            selectAllName="productTypeAll"
                            FormActions={FormActions}
                            getStateRoot={getStateRoot}
                            optionLabel={(item, getLangText) => (
                                <label className="control-label text-nowrap">
                                    <img
                                        className="source-icon inline-block"
                                        src={`/assets/images/svg/product-type/${item.icon}.svg`}
                                        width={14}
                                        alt=""
                                    />
                                    {getLangText(item.lang)}
                                </label>
                            )}
                        />
                    </ToggleFilterButton>
                    {!!+userData.selmo_pro_active &&
                        <ToggleFilterButton
                            buttonTitle="NIP"
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="nip"
                        >
                            <SingleSelectDefaultFilter
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={YES_NO_TYPES}
                                name="nip"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                            />
                        </ToggleFilterButton>
                    }
                    {+userData.id === SHOP_BAZAR_SHOP_ID &&
                        <ToggleFilterButton
                            buttonTitle="Wydrukowane zamówienia"
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="printed"
                        >
                            <SingleSelectDefaultFilter
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={YES_NO_TYPES}
                                name="printed"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                            />
                        </ToggleFilterButton>
                    }
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === LADY_WYJATKOWE_KREACJE_SHOP_ID) &&
                        <ToggleFilterButton
                            buttonTitle={getLangText('teamLabel')}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="employees"
                        >
                            <AsyncPanelFilter
                                ListActions={ListActions}
                                FiltersActions={FiltersActions}
                                getFiltersStateRoot={getFiltersStateRoot}
                                name="employees"
                                selectAllName="employeesSelectAll"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                                rest={services.API_ORDER_EMPLOYEES}
                                optionsParser={(items) => items.map((i) => ({
                                    value: i.id,
                                    label: i.name,
                                    photo: i.photo,
                                }))}
                                optionLabel={(item) => (
                                    <label className="flex items-center mb-0">
                                        <ClientAvatar
                                            photo={item.photo}
                                            fbLogo={false}
                                        />
                                        <div className="text-xs font-medium text-[#6B7280]">
                                            {item.label}
                                        </div>
                                    </label>
                                )}
                            />
                        </ToggleFilterButton>
                    }
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === LADY_WYJATKOWE_KREACJE_SHOP_ID) &&
                        <ToggleFilterButton
                            buttonTitle={getLangText('labelPrint')}
                            getFiltersStateRoot={getFiltersStateRoot}
                            name="label_printed"
                        >
                            <SingleSelectDefaultFilter
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={YES_NO_TYPES}
                                name="label_printed"
                                FormActions={FormActions}
                                getStateRoot={getStateRoot}
                            />
                        </ToggleFilterButton>
                    }
                </div>
                <div className="d-flex justify-content-between align-items-center mt-auto">
                <button
                        className="button text-only w-50 font-weight-bold"
                        type="button"
                        onClick={clearFilters}
                    >
                        {getLangText('clearLabel')}
                    </button>
                    <button
                        className="button primary w-50"
                        type="button"
                        onClick={saveFilters}
                    >
                        {getLangText('filterButton')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FiltersPanel;
