import React, {useRef} from 'react';
import {RouterPaths} from "./RouterPath";
import useCSSClass from "../app/messenger/hooks/useCSSClass/useCSSClass";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import useLang from "../../src_shared/hooks/useLang";
import NotificationsList from "../app/systemNotifications/notificationsList/NotificationsList";
import NotificationsBox from "../app/systemNotifications/notificationsBox/NotificationsBox";
import TestRoute from "./TestRoute";

const SystemNotificationsRoute = () => {
    const {getLangText} = useLang();
    const messengerRef = useRef(null);

    const {visibleRightSide} = useSelector((state) => state.messenger.chatBox)

    useCSSClass('full-height-page')
    useCSSClass('messeger-container')

    return (
        <TestRoute path={RouterPaths.SystemNotification}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('newsLabel')} - Selmo</title>
            </Helmet>
            <div
                ref={messengerRef}
                className={`messenger-wrapper ${visibleRightSide ? 'open' : ''}`}
            >
                <NotificationsList messengerRef={messengerRef}/>
                <NotificationsBox/>
            </div>
        </TestRoute>
    );
};

export default SystemNotificationsRoute;
