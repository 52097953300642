import React from 'react';
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import useTableRowClick from "../../../../../../shared/hooks/useTableRowClick";
import {RouterPaths} from "../../../../../../../routes/RouterPath";
import {useSelector} from "react-redux";
import EmptyList from "../../../../../../shared/EmptyList";
import ServerImage from "../../../../../../shared/components/serverImage/ServerImage";

const PromotedProducts = ({
                              items,
                              isWaiting,
                              updateSortOptions,
                              getSortClass,
                          }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick(`${RouterPaths.ProductsList}/edytuj`);
    const {userData} = useSelector((state) => state.session);

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd with-bg rounded-b-medium"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('noSelectedProducts')}
            // text={getLangText('changeSearchOrAddReelsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive rounded-b-medium">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th>
                                {getLangText('productLabel')}
                            </th>
                            <th
                                name="price"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('price')}`}
                            >
                                    <span name="price">
                                        {getLangText('priceLabel')}
                                    </span>
                            </th>
                            <th
                                name="available_squ"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('available_squ')}`}
                            >
                                <span name="available_squ">
                                        {getLangText('availablePieces')}
                                    </span>
                            </th>
                            <th
                                name="sold_qty"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('sold_qty')}`}
                            >
                                    <span name="sold_qty">
                                        {getLangText('soldPieces')}
                                    </span>
                            </th>
                            <th
                                name="sold_value"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('sold_value')}`}
                            >
                                    <span name="sold_value">
                                        {getLangText('valueLabel')}
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items?.map((item) => (
                            <tr
                                {...onTableRowClick(item.product_id)}>
                                <td
                                    style={{color: 'black'}}
                                    className="text-black sm:mb-0 mb-1 w-full sm:w-[50%]"
                                >
                                    <div className="flex items-center">
                                        <div
                                            className="w-[32px] h-[32px] rounded-[5px] overflow-hidden mr-2">
                                            {item?.photo_small ?
                                                <ServerImage
                                                    className="w-full h-full object-cover"
                                                    src={item?.photo_small}
                                                    alt={item?.name}/> :
                                                <img
                                                    className="w-full h-full object-cover"
                                                    src="/assets/images/default/product.svg" alt=""/>
                                            }
                                        </div>
                                        <div
                                            className="max-w-[240px] sm:max-w-[300px]">
                                            {item?.name}
                                            <div className="text-desc text-xs">
                                                {getLangText('codeLabel')}: {item.selmo_code}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-right">
                                    <div
                                        className="sm:block hidden">{item.price}&nbsp;{userData?.currency_info?.text}</div>
                                </td>
                                <td className="text-right">
                                    <div
                                        className="sm:block hidden">{item.squ}</div>
                                </td>
                                <td className="text-right">
                                    <div
                                        className="sm:block hidden">{item.sold_qty}</div>
                                </td>
                                <td className="text-right">
                                    <div
                                        className="sm:block hidden">{item.sold_value}&nbsp;{userData?.currency_info?.text}</div>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default PromotedProducts;
