import {combineReducers} from "redux";
import productCodesFormReducer from "./productCodes/formReducer";
import integrationsReducer from "./integrations/integrationsReducer";
import automaticallyNotificationsReducer from "../../settings/automaticallyNotifications/formReducer";
import teamReducer from "../../settings/team/teamReducer";
import automationsReducer from "../../settings/automations/automationsReducer";
import searchModalReducer from "../../settings/searchModal/listReducer";
import productsImportReducer from "../../settings/productsImport/productsImportReducer";

export default combineReducers({
	productCodes: productCodesFormReducer,
	integrations: integrationsReducer,
	automaticallyNotifications: automaticallyNotificationsReducer,
	team: teamReducer,
	automations: automationsReducer,
	searchModal: searchModalReducer,
	productsImport: productsImportReducer,
});
