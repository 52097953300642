import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import SharePostModal from "./SharePostModal";
import {useSelector} from "react-redux";
import {
    SHOP_ORIGIN_EN,
    SHOP_ORIGIN_ES,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_IT,
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_PT,
    SHOP_ORIGIN_SK
} from "../../shared/enums/ShopOrigins";

const AppReelsPost = () => {
    const {getLangText} = useLang();
    const [activeVideo, setActiveVideo] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const {origin} = useSelector((state) => state.session.userData);
    const preparedLang = () => {
        if (origin === SHOP_ORIGIN_PL) return SHOP_ORIGIN_PL
        if (origin === SHOP_ORIGIN_IT) return SHOP_ORIGIN_IT
        if (origin === SHOP_ORIGIN_FR) return SHOP_ORIGIN_FR
        if (origin === SHOP_ORIGIN_PT) return SHOP_ORIGIN_PT
        return SHOP_ORIGIN_EN;
    }

    const videoPath = `/assets/images/multiLanguage/${preparedLang()}/rateShop/rate-us.mp4`
    const imgPath = `/assets/images/multiLanguage/${preparedLang()}/rateShop/rate-us.png`

    const videoLink = `https://panel.selmo.io/assets/images/multiLanguage/${preparedLang()}/rateShop/rate-us.mp4`

    return (
        <div className="news dashboard-card mb-3">
            <div>⭐️⭐️⭐️⭐️⭐️</div>
            <div className="dashboard-card-title mb-3">{getLangText('shopRatePostTitle')}</div>
            <div className="dashboard-card-subtitle whitespace-pre-wrap font-medium text-[#1F2937]" dangerouslySetInnerHTML={{__html: getLangText('shopRatePostDesc')}}/>
            <div className="grid sm:grid-cols-2 gap-3 mt-4">
                <button
                    type="button"
                    style={{paddingLeft: 15, paddingRight: 15}}
                    onClick={() => setShowModal(true)}
                    className="button primary facebook-button w-full overflow-hidden text-left"
                >
                    <img width={18} height={18} className="mr-auto" alt="facebook"
                         src="/assets/images/svg/facebook-icon.svg"/>
                    <span className="mr-auto sm:ml-0 ml-1.5">{getLangText('shareOnFacebook')}</span>
                </button>
                <a
                    className="button border-button google-button w-full overflow-hidden text-left"
                    download
                    href={videoLink}
                    style={{paddingLeft: 15, paddingRight: 15}}
                >
                    <img width={18} height={18} className="mr-auto" alt="download"
                         src="/assets/images/svg/download.svg"/>
                    <span className="mr-auto sm:ml-0 ml-1.5">{getLangText('downloadVideoLabel')}</span>

                </a>
            </div>
            <figure className="w-full rounded-[8px] overflow-hidden relative my-4">
                {activeVideo ?
                    <video
                        autoPlay={true}
                        className="w-full h-full object-cover"
                        controls
                        muted={true}
                        src={videoPath}
                    /> :
                    <>
                        <div
                            style={{width: 60, height: 60}}
                            className="absolute top-1/2 left-1/2 -translate-y-1/2 transition-all pointer-events-none group-hover:scale-[105%] -translate-x-1/2 rounded-full w-[60px] h-[60px] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex items-center justify-center">
                            <img
                                style={{
                                    width: 14,
                                    minWidth: 18,
                                    height: 'auto'
                                }}
                                className="sm:w-[18px] w-[14px] z-10"
                                src="/assets/images/automaticTransfer/triangle.svg"
                                alt="play"
                            />
                        </div>
                        <img
                            onClick={() => setActiveVideo(true)}
                            className="h-full w-full object-cover cursor-pointer"
                            src={imgPath}
                            alt=""
                        />
                    </>
                }
            </figure>
            {showModal &&
                <SharePostModal
                    showModal={showModal}
                    hideModal={() => setShowModal(false)}
                    videoLink={videoLink}
                    previewSrc={imgPath}
                />
            }
        </div>
    );
};

export default AppReelsPost;
