import React, {useState} from 'react';
import SelmoPaczkaPage from "../SelmoPaczkaPage";
import useLang from "../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";
import cn from "clsx";
import {useSelector} from "react-redux";
import useAssetsLang from "../../shared/helpers/useAssetsLang";
import Faq from "../../notifications/landing/components/Faq/Faq";

const options = [
    {id: 2, logo: '/assets/images/svg/orlen-paczka.svg', price: '10.44'},
    {id: 1, logo: '/assets/images/svg/dpd-logo.svg', price: '11.49'},
    {id: 3, logo: '/assets/images/settings/inpost.svg', price: '15.19'},
]
const benefits = [
    {id: 1, title: 'Brak wymagań ilościowych'},
    {id: 2, title: 'Bardzo korzystne stawki'},
    {id: 3, title: 'Sprawdzeni partnerzy logistyczni'},
]


const howItWorksOptions = [
    {
        id: 1,
        title: 'selmoPaczkaHowItWorks1',
        icon: 'configuration',
    },
    {
        id: 2,
        title: 'selmoPaczkaHowItWorks2',
        icon: 'labels',
    },
    {
        id: 3,
        title: 'selmoPaczkaHowItWorks3',
        icon: 'truck',
    },
]

const questions = [
    {
        title: 'Czym jest Selmo Paczka?',
        desc: 'Selmo Paczka to usługa brokerska, która pozwala sprzedawcom zlecać przesyłki kurierskie i automatowe w prosty sposób, bez konieczności podpisywania długoterminowych umów. Dzięki niej zyskujesz dostęp do hurtowych cen za przesyłki bez minimalnych zobowiązań ilościowych.',
        id: 0
    },
    {
        title: 'Jakie są wymagania, aby skorzystać z Selmo Paczki?',
        desc: 'Nie ma żadnych skomplikowanych wymagań. Wystarczy, że posiadasz konto w Selmo, aby zacząć korzystać z naszej usługi i wysyłać paczki w atrakcyjnych cenach.',
        id: 1
    },
    {
        title: 'Czy muszę zamawiać określoną liczbę przesyłek miesięcznie?',
        desc: 'Nie, Selmo Paczka nie wymaga żadnych zobowiązań co do liczby przesyłek. Możesz wysyłać tyle paczek, ile potrzebujesz, bez obaw o dodatkowe koszty.',
        id: 2
    },
    {
        title: 'Jakie korzyści daje korzystanie z Selmo Paczki w porównaniu z bezpośrednimi umowami z kurierami?',
        desc: 'Dzięki Selmo Paczce masz dostęp do hurtowych cen przesyłek, bez konieczności negocjowania i podpisywania indywidualnych umów z firmami kurierskimi. Oszczędzasz czas i pieniądze, skupiając się na prowadzeniu swojego biznesu.',
        id: 3
    },
    {
        title: 'W jaki sposób mogę wygenerować etykietę poprzez Selmo Paczkę?',
        desc: 'Wystarczy wejść w widok zamówienia w Selmo, a następnie przejść do sekcji Selmo Paczka. Tam możesz wygenerować etykietę, wybierając dostępnego przewoźnika oraz pożądany rozmiar paczki. To proste i szybkie!',
        id: 4
    },
]

const Landing = () => {
    const {getLangText} = useLang();
    const {origin} = useSelector((state) => state.session.userData);
    const lang = useAssetsLang();
    const handleShowVideo = () => setShowVideo(true);
    const [showVideo, setShowVideo] = useState(false);
    const autoPlaySrc = `https://www.youtube.com/embed/zHFPMJnEwc4?si=A69rTfwvLs0DeJ0u&autoplay=1`
    const [isActive, setActive] = useState(0);

    return (
        <SelmoPaczkaPage>
            <div className="bg-[#fff] mt-3 rounded-[5px] pt-[0px] p-6 sm:pt-20 sm:pb-24">
                {lang === SHOP_ORIGIN_PL &&
                    <div className="w-full sm:w-[750px] mx-auto">
                        <div className="selmo-video-wrapper no-border-sm sm:mx-0 -mx-6">
                            {!showVideo ?
                                <div
                                    onClick={handleShowVideo}
                                    className={cn('absolute top-0 left-0 bottom-0 right-0 group cursor-pointer sm:rounded-[12px] overflow-hidden')}>
                                    <video
                                        autoPlay={true}
                                        loop
                                        playsInline
                                        muted
                                        src="/assets/images/selmo-paczka/email-etykiety-short.mp4"/>
                                    <div className="absolute top-0 right-0 bottom-0 left-0 bg-[#000] opacity-30"/>
                                    <img
                                        className="absolute sm:w-[100px] sm:h-[100px] w-[84pxpx] h-[84px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-all duration-200"
                                        src="/assets/images/svg/play-white.svg" alt="play" width={100}
                                        height={100}
                                    />
                                </div> :
                                <iframe
                                    frameBorder="0" allowFullScreen="" scrolling="no"
                                    allow="autoplay;fullscreen"
                                    src={autoPlaySrc}
                                    style={{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: '0px',
                                        top: '0px'
                                    }}
                                />
                            }
                        </div>
                    </div>
                }
                <div className={`max-w-[590px] mx-auto sm:px-0 ${origin === 'pl' ? 'sm:mt-16' : ''}`}>
                    <div class="flex mt-4 mb-3 relative">
                        <div>
                            <div className="text-[#5fa5fa] flex items-center font-bold sm:mb-[16px] mb-[8px]">SELMO PACZKA</div>
                            <div
                                className="whitespace-pre-wrap big-text-vacation font-bold text-[24px] sm:text-[32px] leading-[36px] sm:leading-[46px]"
                                dangerouslySetInnerHTML={{
                                    __html: getLangText('sentPackageSevenZloty')
                                }}
                            />
                        </div>
                        <img
                            className="ml-auto sm:relative absolute top-0 right-0 sm:w-auto w-[75px]"
                            src="/assets/images/selmo-paczka/badge.svg" alt=""/>
                    </div>

                    <div class="mt-12">
                        <div className="mb-[30px]">
                            <div className="or-separator big-size text-center">
                                <div
                                    style={{color: '#4b5563'}}
                                    className="text-[#4b5563]">{getLangText('paymentsHowItWorksLabel')}</div>
                            </div>
                        </div>
                        <div className="grid gap-4 mt-3 mb-4">
                            {howItWorksOptions?.map((i) => (
                                <div
                                    key={i.id}
                                    className={`marketing-card selmo-paczka-card sm:flex pb-[25px] items-center relative`}
                                >
                                    <div
                                        className="absolute -top-2 -left-2 border-4 border-[#fff] w-[34px] h-[34px] rounded-full bg-[#5fa5fa] flex items-center justify-center text-sm font-bold text-[#fff]">{i.id}</div>
                                    <div
                                        className="sm:whitespace-pre-wrap grow py-[20px] pl-[40px] sm:pr-0 pr-3.5"
                                        dangerouslySetInnerHTML={{
                                            __html: getLangText({
                                                key: i.title,
                                            })
                                        }}
                                    />
                                    <img
                                        className={`sm:ml-auto sm:mr-0 sm:mx-0 mx-auto sm:h-full max-w-[250px] ${i.id === 1 ? '' : ''}`}
                                        src={`/assets/images/selmo-paczka/${i.icon}.${i.id === 3 ? 'webp' : 'svg'}`}
                                        alt=""/>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-center mt-24">
                        <div className="mb-[30px]">
                            <div className="or-separator big-size text-center">
                                <div
                                    style={{color: '#4b5563'}}
                                    className="text-[#4b5563]">Dostępni przewoźnicy
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-3">
                            {options.map((option) => (
                                <div className="text-center">
                                    <img
                                        className="h-[40px] max-w-[50px] mx-auto object-contain"
                                        src={option.logo} alt=""/>
                                    <div className="text-[#4B5563] text-sm font-medium mt-2">
                                        Cena za przesyłkę
                                    </div>
                                    <div className="font-bold text-[20px]">{option.price} zł</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="sm:text-center mt-24">
                        <div className="mb-[30px]">
                            <div className="or-separator big-size text-center">
                                <div
                                    style={{color: '#4b5563'}}
                                    className="text-[#4b5563]">
                                    {getLangText('pricingWhyWorthyTitle')}
                                </div>
                            </div>
                        </div>
                        <div className="space-y-2">
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className="rounded-[8px] bg-[#F0FAFF] flex items-center p-3"
                                >
                                    <i className="icon-tick-c text-[#5FA5FA] text-2xl mr-2"/>
                                    <div className="text-[#0D3364] font-bold">{benefit.title}</div>
                                </div>
                            ))}
                        </div>
                        <Link
                            to={RouterPaths.SelmoPaczkaConfiguration}
                            className="button primary w-full big-size mt-3 text-center"
                            style={{fontSize: 14, borderRadius: 8}}
                        >
                            {getLangText('trySelmoPaczka')}
                        </Link>
                    </div>
                    <div className="mt-12">
                        <div className="mb-[30px]">
                            <div className="or-separator big-size text-center">
                                <div
                                    style={{color: '#4b5563'}}
                                    className="text-[#4b5563]">
                                    {getLangText('helpMostFrequentlyAskedQuestionsLabel')}
                                </div>
                            </div>
                        </div>
                        <Faq
                            setActive={setActive}
                            isActive={isActive}
                            questions={questions}
                            smallSize={true}
                        />
                    </div>
                </div>
            </div>
        </SelmoPaczkaPage>
    );
};

export default Landing;
