import {combineReducers} from "redux";
import livesReducer from "../components/app/live/livesReducer";
import messengerReducer from "../components/app/messenger/messengerReducer";
import sharedReducer from "../components/app/shared/sharedReducer";
import ordersReducer from "../components/app/orders/ordersReducer";
import newsReducer from "../components/app/news/newsReducer";
import clientsReducer from "../components/app/clients/clientsReducer";
import productsReducer from "../components/app/products/productsReducer";
import statsReducer from "../components/app/stats/statsReducer";
import statsNewReducer from "../components/app/statsNew/statsReducer";
import sessionReducer from "../components/app/shared/session/sessionReducer";
import langsReducer from "../components/app/shared/langs/langsReducer";
import langsAuthSinglePageReducer from "../components/app/shared/langsAuthSinglePage/langsReducer";
import knowledgeBaseReducer from "../components/app/knowledgeBase/knowledgeBaseReducer";
import paymentsReducer from "../components/app/payments/paymentsReducer";
import settingsReducer from "../components/app/settings/settingsReducer";
import shopSettingsReducer from "../components/app/shopSettings/shopsSettingsReducer";
import basketReducer from "../components/app/basket/basketReducer";
import shopCartAuthReducer from "../components/app/shopCartAuth/shopCartAuthReducer";
import adminReducer from "../components/admin/reducers/index";
import notificationsReducer from "../components/app/notifications/notificationsReducer";
import automationReducer from "../components/app/automation/automationReducer";
import storageReducer from "../components/app/storage/storageReducer";
import mrAutomateReducer from "../components/app/mrAutomate/mrAutomateReducer";
import livesOverlayReducer from "../components/app/liveOverlay/livesOverlayReducer";
import salesPostReducer from "../components/app/salesPost/salesPostReducer";
import selmoPaczkaReducer from "../components/app/selmoPaczkaPage/selmoPaczkaReducer";
import marketingReducer from "../components/app/marketing/marketingReducer";

export default combineReducers({
	lives: livesReducer,
	messenger: messengerReducer,
	orders: ordersReducer,
	clients: clientsReducer,
	news: newsReducer,
	products: productsReducer,
	stats: statsReducer,
	statsNew: statsNewReducer,
	knowledgeBase: knowledgeBaseReducer,
	payments: paymentsReducer,
	shopSettings: shopSettingsReducer,
	basket: basketReducer,
	automation: automationReducer,
	settings: settingsReducer,
	shopCartAuth: shopCartAuthReducer,
	notifications: notificationsReducer,
	storage: storageReducer,
	mrAutomate: mrAutomateReducer,
	streamOverlay: livesOverlayReducer,
	salesPost: salesPostReducer,
	selmoPaczka: selmoPaczkaReducer,
	shared: sharedReducer,
	session: sessionReducer,
	i18n: langsReducer,
	i18nAuthSinglePage: langsAuthSinglePageReducer,
	admin: adminReducer,
	marketing: marketingReducer
});
