import Model from "../../../src_shared/modules/model/Model";

class UspsLabelModel extends Model {
	getModel() {
		return {
			template_id: null,
			items: [],
		};
	}

	buildDTO(data) {
		return {
			template_id: data.template_id
		}
	}
}

export default new UspsLabelModel();
