import React from 'react';
import SettingsBox from "../../components/SettingsBox";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";
import {
    ESTORE_SHOP_ID,
    FASHION_DOROTA_SHOP_ID,
    KOKARDECZKA_SHOP_ID,
    PRZEMO_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../src_shared/enums/TrustedShopsIds";

export const SETTINGS_SHOP = [
    {
        id: 0,
        title: 'settings_nav_general',
        desc: 'settings_nav_general_description',
        imgSrc: '/assets/images/settings/overview.svg',
        path: RouterPaths.SettingsGeneral
    },
    {
        id: 1,
        title: 'settings_nav_terms',
        desc: 'settings_nav_terms_description',
        imgSrc: '/assets/images/settings/waybills.svg',
        path: RouterPaths.SettingsTerms
    },
    {
        id: 2,
        title: 'settings_nav_product_code',
        desc: 'settings_nav_product_code_description',
        imgSrc: '/assets/images/svg/codes.svg',
        path: RouterPaths.SettingsProductCodes
    },
    {
        id: 3,
        title: 'discountCodesLabel',
        desc: 'settings_nav_discount_description',
        imgSrc: '/assets/images/settings/discount.svg',
        path: RouterPaths.SettingsDiscountCodes
    },
    {
        id: 4,
        title: 'settings_nav_message_templates',
        desc: 'settings_nav_terms_description',
        imgSrc: '/assets/images/settings/waybills.svg',
        path: RouterPaths.SettingsMessenger
    },
    {
        id: 5,
        title: 'settings_nav_package_types',
        desc: 'settings_nav_package_types_description',
        imgSrc: '/assets/images/svg/packs-s.svg',
        path: RouterPaths.SettingsPackages,
        shopIds: [KOKARDECZKA_SHOP_ID, PRZEMO_SHOP_ID, TEST_ACCOUNT_SHOP_ID],
    },
    {
        id: 6,
        title: 'settings_nav_audio_messages',
        desc: 'settings_nav_audio_messages_description',
        imgSrc: '/assets/images/svg/voice-s.svg',
        path: RouterPaths.SettingsVoiceMessage,
        shopIds: [KOKARDECZKA_SHOP_ID, PRZEMO_SHOP_ID, TEST_ACCOUNT_SHOP_ID],
        origins: [],
    },
    {
        id: 7,
        title: 'settlementsLabel',
        desc: 'settlementsSubLabel',
        imgSrc: '/assets/images/svg/orders.svg',
        path: RouterPaths.SettingsSettlements
    },
    {
        id: 8,
        title: 'mobileAppSelmo',
        desc: 'mobileAppSelmoSubLabel',
        // imgSrc: '/assets/images/svg/orders.svg',
        path: RouterPaths.SettingsMobileApp
    },
    {
        id: 8,
        title: 'automaticallyNotificationsLabel',
        desc: 'automaticallyNotificationsSubLabelSmall',
        path: RouterPaths.SettingsAutomaticallyNotifications
    },
    {
        id: 9,
        title: 'teamLabel',
        desc: 'manageTeamLabel',
        imgSrc: '/assets/images/svg/team.svg',
        path: RouterPaths.SettingsTeam,
        permission: 'employees_accounts',
    },
    {
        id: 10,
        title: 'qrProductCode',
        desc: 'manageQRCodeAppearance',
        // imgSrc: '/assets/images/svg/orders.svg',
        path: RouterPaths.SettingsQRProduct,
    },
    {
        id: 11,
        title: 'productsImport',
        desc: 'productsImportDesc',
        imgSrc: '/assets/images/svg/orders.svg',
        path: RouterPaths.SettingsProductsImport,
    },
]

const GeneralBoxes = () => {
    const {getLangText} = useLang();

    return (
        <section>
            <div className="section-title">{getLangText('settings_nav_shop_settings')}</div>
            <div className="settings-box-wrapper border-boxes">
                {SETTINGS_SHOP.map((item) => (
                    <SettingsBox
                        key={item.id}
                        item={item}
                    />
                ))}
            </div>
        </section>
    );
};

export default GeneralBoxes;
