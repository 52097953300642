import React, {useEffect} from 'react';
import Loader from "../../../../src_shared/components/loader/Loader";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import {useParams} from "react-router-dom";
import useLang from "../../../../src_shared/hooks/useLang";
import {useIntercom} from "react-use-intercom";
import {useDispatch} from "react-redux";
import SessionActions from "../../shared/session/SessionActions";

const NotificationsBox = () => {

        const {id} = useParams()
        const {getLangText} = useLang();
        const {show} = useIntercom();
        const dispatch = useDispatch();

        const afterSubmit = (response) => {
            dispatch(SessionActions.updateUserData('not_read_notifications', response.not_read_notifications))
        }

        const [{isLoading, fetchData, data}] = useFetchData({
            rest: `${services.API_NOTIFICATIONS_LIST}/${id}`,
            initialAutoLoad: false,
            afterSubmit,
        })

        useEffect(() => {
            fetchData()
        }, [id]);

        return (
            <div className={`right-panel loader-parent overflow-y-auto`}>
                <Loader isLoading={isLoading}/>
                <div className="bg-[#f9fafb] rounded-[12px] p-4">
                    <div className="flex items-center mb-3">
                        <div className="font-bold grow">{data.title}</div>
                        {!!data.link &&
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={data.link}
                                style={{
                                    borderRadius: 8,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontSize: 12,
                                    paddingLeft: 12,
                                    paddingRight: 12
                                }}
                                className="button primary black ml-3 small-size whitespace-nowrap"
                            >
                                {data.link_text}
                            </a>
                        }
                    </div>
                    <div
                        className="font-medium notification-description"
                        dangerouslySetInnerHTML={{__html: data.description}}
                    />
                    {!!data.link &&
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={data.link}
                            style={{borderRadius: 8, paddingTop: 12, paddingBottom: 12}}
                            className="button primary black w-full text-center mt-3"
                        >
                            {data.link_text}
                        </a>
                    }
                    {/*{!!data.link &&*/}
                    <button
                        type="button"
                        onClick={show}
                        style={{borderRadius: 8, paddingTop: 12, paddingBottom: 12, fontSize: 14}}
                        className="button border-button w-full text-center mt-2"
                    >
                        {getLangText('replyToMessage')}
                    </button>
                    {/*}*/}
                </div>
            </div>
        );
    }
;

export default NotificationsBox;
