import React, {useState} from 'react';
import Field from "../../../../src_shared/components/form/field/Field";
import Group from "../../../../src_shared/components/form/group/Group";
import {services} from "../../../RestServices";
import {selmoUrl} from "../../../../src_shared/api/api";
import Loader from "../../shared/Loader";
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../shared/components/alert/AlertActions";
import AsyncSearchSelectField
    from "../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField.jsx";
import InvoiceDataTypes, {INVOICE_DATA_COMPANY} from "../../shared/enums/InvoiceDataEnums";
import ZipCodeField from "../../orders/details/deliveryAddress/components/ZipCodeInputs";
import useLang from "../../../../src_shared/hooks/useLang";
import {createSelector} from "reselect";
import {getStateRoot} from "../formReducer";
import PaymentFormActions from "../FormActions";
import api from "../../../../services/axios/axios";

const InvoiceData = ({setValue, setValueAndUpdatePrice, updateValues, values, getFieldProps, withLabel = true, inputClassName = '', selectId = ''}) => {

    const [invoiceDataWaiting, setInvoiceDataWaiting] = useState(false);
    const {getLangText} = useLang();
    const {notValidNip} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const [errorNip, setErrorNip] = useState(false);
    const dispatch = useDispatch();

    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    const checkInvoiceData = async () => {
        if (+values.country_id === 103 && !values.nip?.match(/^[0-9]{10}$/g)) {
            setErrorNip(true)
            dispatch(showAlert('sthWentWrongWarning', 'checkNipValidLabel', 'alert-fail'))
            return;
        }
        setInvoiceDataWaiting(true)
        try {
            const {data} = await api.post(`${selmoUrl}/${services.API_GUS_DATA}`, {nip: values.nip})
            updateValues({
                ...data.item,
                company_name: data.item?.name
            })
            setErrorNip(false)
        } catch (e) {
            setErrorNip(true)
            dispatch(showAlert('sthWentWrongWarning', 'checkNipValidLabel', 'alert-fail'))
        } finally {
            setInvoiceDataWaiting(false)
        }
    }

    const updateCountryName = (option) => {
        updateValues({
            country: {
                id: option.id,
                name: option.name
            },
            country_id: option.id,
        })
    }

    const getNotValidNipError = notValidNip ? () => 'enterTheCorrectNipLabel' : getFieldProps('nip').getError;
    const setNipValue = (e) => {
        setValue('nip', e.target.value)
        dispatch(PaymentFormActions.setNotValidNip(false))
        dispatch(PaymentFormActions.validation.deactivateValidation())
    }

    return (
        <div className="invoice-wrapper">
            <div className="select-boxes-wrapper">
                <div className="section-label">
                    {withLabel && getLangText('paymentsChoseLegalFormLabel')}
                </div>
                <div className="data-boxes-wrapper small-padd inline">
                    {InvoiceDataTypes.map((item) => (
                        <div key={item.id}>
                            <div className="content payment-content">
                                <div className="content__top-part">
                                    <input
                                        onChange={() => setValueAndUpdatePrice('invoice', item.id)}
                                        checked={values.invoice === item.id}
                                        type="radio"
                                        name={item.id}
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1">
                                        {getLangText(item.label)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="form-place loader-parent mt-3">
                <Loader isLoading={invoiceDataWaiting}/>
                {values.invoice === INVOICE_DATA_COMPANY ?
                    <>
                        <Field
                            {...getFieldProps('company_name')}
                            setValue={setValue}
                            label={getLangText('companyNameLabel')}
                            floatedLabel
                            className={inputClassName}
                        />
                        <Group
                            floatedLabel
                            className={`with-copy-btn absolute-btn with-invoice-data ${inputClassName}`}
                            {...getFieldProps('nip')}
                            validateErrors={getFieldProps('nip').validateErrors || errorNip}
                            getError={getNotValidNipError}
                        >
						<span
                            className={`floated-label ${values.nip?.length ? 'selected' : ''}`}>{getLangText('nipLabel')}</span>
                            <input
                                {...getFieldProps('nip')}
                                className="form-control"
                                onChange={setNipValue}
                            />
                            {+values.country_id === 103 &&
                                <button
                                    type="button"
                                    onClick={checkInvoiceData}
                                    className="button border-button small-size copy-btn copied left-icon">
                                    <i className="icon-download"/>
                                    {getLangText('loadDataLabel')}
                                </button>
                            }
                        </Group>
                        <AsyncSearchSelectField
                            id={selectId}
                            {...getFieldProps('country_id')}
                            label={getLangText('countryLabel')}
                            setValue={setValue}
                            rest={services.API_COUNTRIES}
                            formatOptionLabel={formatOptionLabel}
                            defaultValue={values.country}
                            floatedLabel
                            placeholder={getLangText('countryLabel')}
                            setExtraValues={updateCountryName}
                            searchOnFocus
                            className={inputClassName}
                        />
                        <Field
                            {...getFieldProps('address')}
                            setValue={setValue}
                            label={getLangText('streetNumberLabel')}
                            floatedLabel
                            className={inputClassName}
                        />
                        <div className="row small-padd-row">
                            <div className="col-md-6">
                                <ZipCodeField
                                    {...getFieldProps('zip_code')}
                                    setValue={setValue}
                                    label={getLangText('zipCodeLabel')}
                                    floatedLabel
                                    isPoland={+values.country_id === 103}
                                    className={inputClassName}
                                />
                            </div>
                            <div className="col-md-6">
                                <Field
                                    {...getFieldProps('city')}
                                    setValue={setValue}
                                    label={getLangText('cityLabel')}
                                    floatedLabel
                                    className={inputClassName}
                                />
                            </div>
                        </div>
                    </> :
                    <>
                        <AsyncSearchSelectField
                            id={selectId}
                            {...getFieldProps('country_id')}
                            label={getLangText('countryLabel')}
                            setValue={setValue}
                            rest={services.API_COUNTRIES}
                            formatOptionLabel={formatOptionLabel}
                            defaultValue={values.country}
                            floatedLabel
                            placeholder={getLangText('countryLabel')}
                            setExtraValues={updateCountryName}
                            searchOnFocus
                            className={inputClassName}
                        />
                        <Field
                            {...getFieldProps('city')}
                            setValue={setValue}
                            label={getLangText('cityLabel')}
                            floatedLabel
                            className={inputClassName}
                        />
                    </>
                }
            </div>
        </div>
    );
};

export default InvoiceData;
