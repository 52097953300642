import React, {useEffect, useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import {Link, NavLink, useLocation} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import {Helmet} from "react-helmet";


export const marketingTabOptions = [
    {
        id: 1,
        title: 'facebookAdds',
        desc: 'increaseSale',
        route: RouterPaths.MarketingFacebook,
        icon: 'fb',
        active: true,
    },
    {
        id: 2,
        title: 'selmoAppAdds',
        route: RouterPaths.MarketingSelmoApp,
        icon: 'app',
        active: false,
        soonTitle: 'marketingSelmoAppTitle',
        soonDesc: 'marketingSelmoAppDesc',
    },
    {
        id: 3,
        title: 'SMS',
        route: RouterPaths.MarketingSms,
        icon: 'sms',
        active: false,
        soonTitle: 'marketingSMSTitle',
        soonDesc: 'marketingSMSDesc',
    },
    {
        id: 4,
        title: 'E-mail',
        route: RouterPaths.MarketingEmail,
        icon: 'email',
        active: false,
        soonTitle: 'marketingEmailTitle',
        soonDesc: 'marketingEmailDesc',
    },
]

const Marketing = ({children}) => {

    const {getLangText} = useLang();
    const {pathname} = useLocation();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('advertisements')} - Selmo</title>
            </Helmet>
            <div className="bg-[#fff] sm:rounded-medium shadow-default overflow-hidden sm:p-6 mb-3">
                <div className="text-lg font-bold sm:mb-[16px] mb-[10px] sm:p-[0px] p-6 pb-0">
                    Selmo Marketing
                </div>
                <div className="flex overflow-auto sm:grid sm:grid-cols-4 sm:px-0 px-6 sm:pb-[0px] pb-6 gap-3">
                    {marketingTabOptions.map((option) => (
                        <NavLink
                            activeClassName="border-[#3c61ea] bg-[#f7f9fe]"
                            to={option.route}
                            className={`text-left hover:no-underline hover:text-[#000] border sm:min-w-[auto] min-w-[250px] rounded-[8px] px-[16px] py-[13px] flex items-center ${pathname.includes(option.route) ? 'border-[#3c61ea] bg-[#f7f9fe]' : 'border-[#e5e7eb] '}`}
                        >
                            <div>
                                <div className="text-sm font-bold">{getLangText(option.title) || option.title}</div>
                                <div className="text-[#9ca3af] text-xs font-medium">
                                    {option.active ?
                                        <div className="text-red-500 flex items-center">
                                            <span className="w-[3px] mr-1 h-[3px] block bg-red-500 rounded-full"/>
                                            <span>{getLangText('applicationStopped')}</span>
                                        </div> :
                                        <div>
                                            <i className="icon-safe mr-1"/>
                                            <span>{getLangText('soon')}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <img className="sm:w-[44px] ml-auto" src={`/assets/images/svg/marketing/${option.icon}.svg`}
                                 alt="icon"/>
                        </NavLink>
                    ))}
                </div>
            </div>
            {children}
        </>
    );
};

export default Marketing;
