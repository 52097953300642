import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../../../../src_shared/form/FormActions";
import {services} from "../../../../../../RestServices";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import MarketingDetailsFormModel from "../../../../../../../modules/models/marketing/MarketingDetailsFormModel";
import api from "../../../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {batch} from "react-redux";
import {ERROR_NOT_FOUND} from "../../../../../../../src_shared/enums/ErrorEnums";
import LangsActions from "../../../../../shared/langs/LangsActions";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";

export class FacebookAdvertisementsFormActions extends FormActions {
    updateVideoStatus = (pusherData) => {
        return (dispatch, getState) => {
            const {data} = getStateRoot(getState());


            const newMediaItem = {
                is_being_transmitted: pusherData.is_being_transmitted,
                media_url: pusherData.media_url,
                media_poster: pusherData.media_poster,
                media_type: 'video',
                is_error: pusherData.is_error,
                id: pusherData.id,
            }

            // const updatedMedia = !!data.media?.length ? data.media?.map((item) => +item.id === +pusherData?.id ? {
            //     ...item,
            //     ...newMediaItem,
            // } : item) : [newMediaItem]

            const updatedMedia = [newMediaItem];

            if (+data.id === +pusherData.ad_id) {
                dispatch(this.data.updateValues({
                    media: updatedMedia,
                    is_being_transmitted: pusherData.is_being_transmitted,
                    is_error: pusherData.is_error,
                }))
                // dispatch(this.data.updateValues({
                //     is_being_transmitted: pusherData.is_being_transmitted,
                //     video_url: pusherData.video_url,
                //     video_id: pusherData.id,
                //     video_name: pusherData.video_name,
                //     poster_url: pusherData.poster_url,
                //     is_error: pusherData.is_error
                // }))
            }
        }
    }

    loadData(id) {
        return async (dispatch) => {
            dispatch(this.waiting.startWaiting());
            api.defaults.headers.common['lang'] = dispatch(LangsActions.getLang());
            const restUrl = id ? `${this.restService}/${id}` : this.restService;
            try {
                const {data} = await api.get(`${selmoUrl}/${restUrl}`)
                const parsedItem = dispatch(this.parseData(data.item));
                batch(() => {
                    dispatch(this.data.updateValues(parsedItem))
                    dispatch(this.data.updateEmptyValues(parsedItem))
                })
            } catch (e) {
                if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
                    dispatch(this.data.setValue('notFound', true));
                }
                dispatch(this.onErrorLoad(e))
                console.error('Error loadData')
            } finally {
                dispatch(this.waiting.stopWaiting());
            }
        }
    }

    submitEditForm(values, history) {
        return async (dispatch, state) => {
            const {id} = values;
            const {emptyData} = getStateRoot(state());

            dispatch(this.validation.activateValidation());

            if (!this.dataModel.validateData(values)) {
                dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
                console.error('Validation failed!');
                return;
            }

            const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
            const finalData = Object.fromEntries(filteredData)
            const filteredEmptyData = Object.entries(emptyData).filter(([key]) => key !== 'id');
            const finalEmptyData = Object.fromEntries(filteredEmptyData)

            const preparedData = this.dataModel.buildDTO(finalData, finalEmptyData)

            dispatch(this.waiting.startWaiting());

            try {
                if (id) {
                    await api.put(`${selmoUrl}/${this.restService}/${id}`, preparedData)
                    dispatch(this.afterSubmit(id))
                } else {
                    const {data} = await api.post(`${selmoUrl}/${this.restService}`, preparedData)
                    if (this.route) {
                        this.updateRoute(data, history)
                    } else {
                        dispatch(this.afterSubmit(data.id, data))
                    }
                }
                batch(() => {
                    dispatch(showAlert())
                    dispatch(this.validation.deactivateValidation())
                    dispatch(this.data.setValue('initialMount', true))
                })
            } catch (e) {
                dispatch(showAlert(e?.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
                console.error('Error submitEditForm')
            } finally {
                dispatch(this.waiting.stopWaiting());
            }
        }
    }

    updateWaitingForSend = (pusherData) => {
        return (dispatch, getState) => {
            const {data} = getStateRoot(getState());

            if (+data.id === +pusherData.post_id) {
                dispatch(this.loadData(data.id))
            }
        }
    }

}

export const getInstance = () => new FacebookAdvertisementsFormActions({
    prefix,
    getStateRoot,
    model: MarketingDetailsFormModel,
    route: RouterPaths.MarketingFacebookAdvertisementsDetails,
    restService: services.API_MARKETING_AD,
});

export default getInstance();

