import React, {useEffect, useState} from "react";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {Link, useParams} from "react-router-dom";
import ComponentLoader from "../../../../../shared/components/componentLoader/ComponentLoader";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import FacebookAdvertisementsFormWrapper from "../form/FacebookAdvertisementsFormWrapper";
import useList from "../../../../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import Model from "../../../../../../../src_shared/modules/model/Model";
import PromotedProducts from "./components/PromotedProducts";
import StatsRow from "../../statistics/components/StatsRow";
import Pagination from "../../../../../../admin/components/app/shared/components/pagination/Pagination";
import Table from "../../statistics/components/Table";
import VideoPreview from "./components/VideoPreview";

const FacebookMarketingDetailsStats = () => {

    const [dataLoaded, setDataLoaded] = useState(false);
    const {getLangText} = useLang();
    const {id} = useParams();
    const [showNoStatsModal, setShowNoStatsModal] = useState(false);

    const [{getFieldProps, onSave, isWaiting: isWaitingForDetails, values, setValue, isLoading}] = useFormLocal({
        model: new Model(),
        rest: `${services.API_MARKETING_AD_STATS_DETAILS}/${id}`,
    });

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove},
    } = useList({
        ListActions,
        getStateRoot,
        hasParentId: true,
        params: {
            id,
        },
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.MarketingFacebookAdvertisements}>
                            {getLangText('advertisements')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('editionLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.MarketingFacebookAdvertisements}
                    className="button back-to-panels sm:mb-5 sm:mt-0 mt-3.5">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToList')}
                </Link>
            </div>
            <FacebookAdvertisementsFormWrapper
                isWaiting={isLoading}
                values={values}
                setValue={setValue}
                fetchData={() => loadData(id)}
            >
                <div>
                    {isLoading ?
                        <ComponentLoader/> :
                        <div>
                            <div className="stats-wrapper bg-[#fff] p-6 mb-3 rounded-b-medium">
                                <StatsRow data={values}/>
                            </div>
                            <div className="">
                                <div className="text-lg font-bold p-6 pb-2 bg-[#fff] rounded-t-medium">
                                    {getLangText('promotedProducts')}
                                </div>
                                <PromotedProducts
                                    isWaiting={isLoading}
                                    items={values.products}
                                    getSortClass={() => {
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {isWaiting ?
                        <ComponentLoader/> :
                        <div className="grid sm:grid-cols-5 gap-5 mt-3">
                            <div className="sm:col-span-3">
                                <div className="text-lg font-bold p-6 pb-2 bg-[#fff] rounded-t-medium">
                                    {getLangText('adPurchases')}
                                </div>
                                <Table
                                    isWaiting={isWaiting}
                                    items={items}
                                    updateSortOptions={updateSortOptions}
                                    getSortClass={getSortClass}
                                    askForRemoveItem={askForRemoveItem}
                                    markedToRemove={markedToRemove}
                                    type="details"
                                />
                                <Pagination
                                    pages={getPagination()}
                                    items={items}
                                    itemsPerPage={itemsPerPage}
                                    totalItems={totalItems}
                                    onPrevPage={prevPage}
                                    onChangePage={changePage}
                                    onNextPage={nextPage}
                                    onChangeItemsPerPage={updateItemsPerPage}
                                    classes={{
                                        prevIcon: 'icon-chevron left', nextIcon: 'icon-chevron right',
                                    }}
                                    showItemsPerPageSelect={false}
                                />
                            </div>
                            {!!values.media?.length &&
                                <div className="sm:col-span-2">
                                    <VideoPreview values={values?.media[0]}/>
                                </div>
                            }
                        </div>
                    }
                </div>
            </FacebookAdvertisementsFormWrapper>
            {/*{showNoStatsModal &&*/}
            {/*    <NoDataModal*/}
            {/*        modalVisible={showNoStatsModal}*/}
            {/*        hideModal={() => setShowNoStatsModal(false)}*/}
            {/*    />*/}
            {/*}*/}
        </>
    );
};

export default FacebookMarketingDetailsStats;
