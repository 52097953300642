import Composition from "../../../../../shared/helpers/Composition";
import createReducer from "../../../../../shared/helpers/createReducer";
import FormReducer from "../../../../../../../src_shared/form/FormReducer";
import MarketingDetailsFormModel from "../../../../../../../modules/models/marketing/MarketingDetailsFormModel";

export const getStateRoot = (state) => state.marketing.facebookMarketing.advertisements.form;

export const prefix = 'MARKETING_FACEBOOK_ADVERTISEMENTS_FORM_';

export const getInitState = () => FormReducer.getInitState(MarketingDetailsFormModel.getModel(), {
	waiting: true,
})

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
