import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../Loader";
import moment from "../../../../moment";
import {convertToUserTimeZone} from "../../../../helpers/dateHelpers";

const productWithSizesQRCodesPdf = 'productWithSizesQRCodesPdf'
const productQRCodesPdf = 'productQRCodesPdf'
const shelvesQRCodesPdf = 'shelvesQRCodesPdf'
const chosenProductSizesQRCodesPdf = 'chosenProductSizesQRCodesPdf'

const fileTypes = [
    {id: productWithSizesQRCodesPdf, label: 'productWithSizesQRCodeLabel'},
    {id: productQRCodesPdf, label: 'productQRCodeLabel'},
    {id: shelvesQRCodesPdf, label: 'shelvesQRCodesPdfLabel'},
    {id: chosenProductSizesQRCodesPdf, label: 'shelvesQRCodesPdfLabel'},
]

const File = ({item}) => {
    const {getLangText} = useLang();

    return (
        <div
            key={item.id}
            className="flex items-center grow py-[12px] px-3"
        >
            <div className="w-[34px] min-w-[34px] h-[34px] flex items-center justify-center rounded-[7px] bg-[#F3F4F6]">
                {!!item.url ?
                    <i class="icon-qr-code text-[#D1D5DB]"/> :
                    <Loader isLoading={true}
                            className="h-[12px] bg-transparent w-[12px] static-loader extra-small-loader transform-none"/>
                }
            </div>
            <div className="flex items-center ml-2 grow">
                <div>
                    <div
                        className="font-bold text-xs text-[#1F2937]">{getLangText(fileTypes.find((i) => i.id === item.export_type)?.label) || item.export_type}</div>
                    <div
                        className="text-[#6B7280] font-medium text-xs">{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                </div>
                {item.url &&
                    <div className="ml-auto">
                        <a
                            href={item.url}
                            download
                            target="_blank"
                            style={{fontSize: 12}}
                            className="button border-button"
                        >
                            {getLangText('downloadLabel')}
                        </a>
                    </div>
                }
            </div>
        </div>
    );
};

export default File;
