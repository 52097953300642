import useLang from "../../../../src_shared/hooks/useLang";
import React, {useRef, useState} from "react";
import api from "../../../../services/axios/axios";
import Loader from "../../../../src_shared/components/loader/Loader";

const FileUpload = ({
                        values = {},
                        updateValues,
                        setValue,
                        restService,
                    }) => {
    const {getLangText} = useLang();
    const [isToBig, setIsToBig] = useState(false);
    const [isInvalidFormat, setIsInvalidFormat] = useState(false);

    const dropRef = useRef(null);
    const fileInputRef = useRef(null);

    const validExtensions = [
        '.csv', '.xlsx', '.xls',
    ];

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.add('bg-[#F9FAFB]');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.remove('bg-[#F9FAFB]');
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.remove('bg-[#F9FAFB]');
        setIsToBig(false);
        setIsInvalidFormat(false);

        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0]

            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                setIsInvalidFormat(true);
                e.dataTransfer?.clearData();
                return;
            }

            if ((file.size / 1000000) > 2000) {
                setIsToBig(true);
                return;
            }

            await upload(file)
            e.dataTransfer?.clearData();

        }
    };

    const handleFileChange = async (e) => {
        setIsToBig(false);
        setIsInvalidFormat(false);

        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0]

            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                setIsInvalidFormat(true);
                e.dataTransfer?.clearData();
                return;
            }

            if ((file.size / 1000000) > 2000) {
                setIsToBig(true);
                return;
            }

            await upload(file)
        }
    };

    const upload = async (file) => {
        updateValues({
            isLoading: true,
            isError: false,
            isSuccess: false,
            errors: [],
        })
        const endpoint = `https://api2.selmo.io/${restService}`;
        const formData = new FormData();
        formData.append('file', file);

        try {
            await api.post(endpoint, formData)

        } catch (e) {
            console.error('Failed to upload images');
        } finally {

        }
    };


    return (
        <div>
            {values.isLoading ?
                <div className="flex items-start bg-[#F5FCFF] border border-[#5FA5FA] px-6 py-3 rounded-[5px]">
                    <Loader
                        className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent"
                        isLoading
                    />
                    <div>
                        <div className="font-bold text-sm">
                            {getLangText('verifyingFile')}
                        </div>
                        <div className="text-desc text-xs whitespace-pre-wrap"
                             dangerouslySetInnerHTML={{__html: getLangText('itMakeTakeAWhileDoNotClosePageFile')}}/>
                    </div>
                </div> :
                (values?.isSuccess || values.isError) ?
                    <div className="flex items-center bg-[#f9f9f9] border px-6 py-3 rounded-[5px]">
                        <div>
                            {values.isError ?
                                <img className="w-[32px] mr-1.5" src="/assets/images/settings/cross.svg" alt=""/> :
                                <img className="w-[32px] mr-1.5" src="/assets/images/settings/tick.svg" alt=""/>
                            }
                        </div>
                        <div>
                            <div className="font-bold rext text-sm">
                                {getLangText(values.isError ? 'sthWentWrongWarning' : 'yuhuLabel')}
                            </div>
                            <div className="text-desc text-xs whitespace-pre-wrap"
                                 dangerouslySetInnerHTML={{__html: getLangText(values.isError ? 'unsuccessfullyImportedProducts' : 'successfullyImportedProducts')}}/>
                        </div>
                        <button
                            onClick={() => updateValues({
                                isSuccess: false,
                                isError: false,
                            })}
                            type="button"
                            className="button border-button small-size ml-auto">
                            {getLangText('insertFile')}
                        </button>
                    </div> :
                    <div
                        ref={dropRef}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={() => fileInputRef?.current?.click()}
                        className={`hover:bg-[#F9FAFB] cursor-pointer rounded-[5px] w-full transition-background duration-300 border overflow-hidden ${!!values.video_id ? 'border-[#DFE1E4]' : 'border-dashed'}`}
                    >

                        <div className="text-center py-16">
                            <div
                                className="w-[30px] mx-auto h-[30px] rounded-[5px] bg-[#E5E7EB] flex items-center justify-center text-[#230C34] mb-2">
                                <i className="icon-upload"/>
                            </div>
                            <div className="font-bold sm:text-lg">{getLangText('selectFileToSend')}</div>
                            <div
                                className="font-medium text-sm text-desc">{getLangText('orDragAndDropHere')}</div>
                            <div className="mt-3">
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="hidden"
                                    id="fileUpload"
                                    ref={fileInputRef}
                                />
                                <div
                                    style={{fontSize: 14}}
                                    className="button primary small-size mb-0"
                                >
                                    {getLangText('selectFile')}
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {isInvalidFormat &&
                <div className="bg-[#FEF2F2] rounded-[5px] flex items-center p-2 text-[#981B1B] mt-2">
                    <div>
                        <i className="icon-alert-c"/>
                    </div>
                    <div>
                        <div className="font-bold text-sm ml-2">
                            {getLangText('invalidFileType')}
                        </div>
                        <div className="font-medium text-xs ml-2">
                            {getLangText({key: 'acceptableFormats', data: [validExtensions.join(', ')]})}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

FileUpload.defaultProps = {
    restService: 'api/media',
    field: 'file',
}

export default FileUpload;
