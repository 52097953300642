import React, {useEffect} from 'react';
import {batch, useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import useLang from "../../../../src_shared/hooks/useLang";
import ClientAvatar from "../../live/shared/Avatar";
import {RouterPaths} from "../../../routes/RouterPath";
import {services} from "../../../RestServices";
import useScrollList from "../../../../src_shared/hooks/useScrollData";
import {odmiana} from "../../messenger/helpers/odmiana";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";
import Loader from "../../../../src_shared/components/loader/Loader";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";
import TimeAgo from "../../../../src_shared/helpers/TimeAgo";
import Item from "../../shared/header/notifications/components/Item";
import Skeleton from "../../shared/header/notifications/components/Skeleton";

export const statuses = [
	{id: null, name: 'mainFolderLabel', color: ''},
	{id: 0, name: 'allLabel', color: 'default'},
	{id: 1, name: 'inquiryLabel', color: 'grey'},
	{id: 2, name: 'customerBasketOrderLabel', color: 'blue'},
	{id: 3, name: 'openBasketLabel', color: 'green'},
	{id: 4, name: 'readyLabel', color: 'green'},
	{id: 5, name: 'noResponse', color: 'grey'},
]

const NotificationsList = ({messengerRef}) => {
	const history = useHistory();
	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);
	const {id} = useParams();
	const {lang} = useSelector((state) => state.i18n);
	const [rightSideVisible, setRightSideVisible] = React.useState(false);

	// const getMessages = (withLoader) => dispatch(getMessagesList(withLoader, signal))
	const getMessages = (withLoader) => {}

	const {
		getNewPage,
		hasMore: hasMoreMessage,
		isNewPageLoading,
		isLoading,
		data: items,
		setData,
		page,
		setPage,
		fetchData,
	} = useScrollList({
		simpleArray: true,
		// params: selectedParams,
		rest: `${services.API_NOTIFICATIONS_LIST}`,
	});

	const {width} = useWindowDimensions();

	const setVisibleRightSide = (toggle) => setRightSideVisible(toggle)

	const getConversationItemClass = (item) => {
		const activeClass = id === item.id ? 'active' : '';
		const newMessageClass = item.viewed === '0' ? 'new' : '';
		return `single-conversation ${activeClass} ${newMessageClass}`
	}

	const onNextPage = () => {
		// dispatch(nextListPage())
		// getMessages(false)
	}

	const redirectToMessages = (item) => {
		history.push(`${RouterPaths.SystemNotifications}/${item.id}`)

		// batch(() => {
		// 	dispatch(chatBoxReset());
		// 	dispatch(updateFieldInItem('viewed', '1', item.id));
		// 	dispatch(setActiveConversation(item));
		// 	dispatch(addOrderModalToggleVisibility(false));
		// 	dispatch(orderModalToggleVisibility(false));
		// })
	}

	const handleConversationClick = (item) => {
		redirectToMessages(item)
		if (width < 991) {
			setVisibleRightSide(true)
		}
	}

	const setAsRead = (item) => setData((prevState) => prevState.map((i) => i.id === item.id ? {...item, viewed: item.id} : i))

	useEffect(() => {
		if (width < 767 && id) {
			setVisibleRightSide(true)
		} else if (width < 767 && !id) {
			setVisibleRightSide(false);
		}
	}, [width, id])

	return (
		<div className="left-panel">
			<div className="text-[#101827] text-xl font-bold pt-[25px] pl-[25px] pb-3">
				{getLangText('newsLabel')}
			</div>
			<div className="chat-list-container">
				{!isLoading && items?.length === 0 ?
					<div className="no-results-text mt-4">
						<img
							width={100}
							src="/assets/images/empty/clients-empty.svg" alt=""/>
						<div className="font-medium text-desc text-sm">{getLangText('noConversationLabel')}</div>
					</div> :
					<div className="chat-list-wrapper loader-parent" id="listScrollableDiv">
						<InfiniteScroll
							dataLength={items?.length}
							next={getNewPage}
							hasMore={hasMoreMessage}
							loader={isNewPageLoading &&  <Loader className="small-loader static-loader" isLoading={true}/>}
							scrollableTarget="listScrollableDiv"
						>
							{isLoading ?
								<Skeleton /> :
								items && items.map((item) => (
									<Item
										inHeader={false}
										item={item}
										key={item.id}
										afterClick={setAsRead}
									/>
								))
							}
						</InfiniteScroll>
					</div>
				}
			</div>
		</div>
	);
};

export default NotificationsList;
