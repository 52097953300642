import Model, {convertObjectProperties} from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";
import moment from "moment";

export class NewsModel extends Model {
	getModel() {
		return {
			id: 0,
			title: '',
			date: new Date(),
			link: '',
			description: '',
			is_active: '1',
			link_text: '',
			category_id: null,
			category: {},
			lang: 'pl',
			short_description: '',
			is_download: '0',
			highlighted: '0',
			type: null,
		};
	}

	getValidators() {
		return {
			title: ValidationError.notEmpty,
			date: ValidationError.skip,
			link: ValidationError.skip,
			link_text: ValidationError.skip,
			description: ValidationError.skip,
			is_active: ValidationError.skip,
			photo: ValidationError.skip,
			category_id: ValidationError.skip,
			lang: ValidationError.notEmpty,
			short_description: ValidationError.skip,
			is_download: ValidationError.skip,
			highlighted: ValidationError.skip,
			type: (value, data) => +data.category_id === 1 ? ValidationError.notEmpty(value) : ValidationError.skip(),
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{},
			{
				description: '',
				category: {},
				is_download: '0',
			}
		);
	}

	buildDTO(data) {
		delete data.category;
		return data;
	}
}

export default new NewsModel();
