import React, {useEffect, useState} from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {useParams} from "react-router-dom";
import InpostLabels, {INPOST_LABEL_CUSTOM, InpostLabelsComodaTransmisje} from "../../../shared/enums/InpostLabelsTypes";
import CustomSizeModal from "./customSizeModal/CustomSizeModal";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../messenger/loader/Loader";
import {useRemoveModal} from "../../../shared/hooks/useRemoveModal";
import {services} from "../../../../RestServices";
import EditOrderFormActions from "../form/FormActions";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import StylesLoader from "../../../shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import {
    BUTIK_MADAME_SHOP_ID,
    CHIQUE_SHOP_ID, COMODA_TRANSMISJE_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import {RouterPaths} from "../../../../routes/RouterPath";

const InpostLabelFrom = ({data}) => {
    const params = useParams()
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const inpostOptions = +userData.id === COMODA_TRANSMISJE_SHOP_ID ? InpostLabelsComodaTransmisje : InpostLabels;

    const {
        data: {values, setValue, updateValues},
        form: {isWaiting, onSave, getFieldProps},
        modal: {hideModal, showModal, modalVisible},
    } = useForm({
        FormActions,
        params,
        preventAutoLoad: true,
    });

    const loadData = () => dispatch(EditOrderFormActions.loadData(data.shop_order_id))

    const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(`${services.API_ORDER_INPOST_SHIPPMENT_LABEL}/${data.id}`, loadData)

    const [waitingForInpostLabel, setWaitingForInpostLabel] = useState(false);

    const checkIsReady = async (callBack) => {
        setWaitingForInpostLabel(true)
        try {
            await api.get(`${selmoUrl}/${services.API_ORDER_INPOST_LABEL_READY}/${data.shippingData.inpost_shipping_id}`)
            callBack();
        } catch (e) {
            dispatch(showAlert('labelIsGeneratingLabel', 'tryAgainInWhileLabel', 'alert-info'))
        } finally {
            setWaitingForInpostLabel(false);
        }
    }

    const {download, waiting: waitingForLabel} = useDownloadFile({
        rest: `${services.API_ORDER_INPOST_SHIPPMENT_LABEL}/${data.id}`,
        fileName: 'InPost',
    })

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: `${services.API_ORDER_INPOST_SHIPPMENT_LABEL}/${data.id}`,
    })

    const createLabel = () => dispatch(FormActions.createLabel());

    useEffect(() => {
        if ((+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) && data.products_weight) {
            updateValues({
                inpost_size: INPOST_LABEL_CUSTOM,
                length: '50',
                width: '40',
                height: '21',
                weight: data.products_weight,
            })
        }
    }, [data.products_weight])

    if (data.shippingData.shop_inpost_active === '0' || !+userData.enable_shipping_int) {
        return null
    }

    if (+userData.id !== BUTIK_MADAME_SHOP_ID && !data?.shippingData?.country_name?.toLowerCase().includes('polska')) return null;

    if (+userData.id === CHIQUE_SHOP_ID && data?.shippingData?.shipping_name?.toLowerCase()?.includes('odbiór')) return null;

    if (data.shippingData.shop_inpost_active === null) {
        return (
            <section className="summary-section mb-3">
                <div className="section-header mb-3">
                    <div className="flex-grow-1">
                        <h2>{getLangText('configureInpostLabel')}</h2>
                    </div>
                    <div>
                        <img src="/assets/images/inpost.svg" alt="InPost"/>
                    </div>
                    <CustomDropdown className="ml-3">
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => dispatch(EditOrderFormActions.removeIntegrationBox('inpost'))}
                        >
                            {getLangText('hideLabel')}
                        </button>
                    </CustomDropdown>
                </div>
                <a
                    className="button border-button big-width w-100"
                    href={RouterPaths.SettingsIntegrationInpost}
                    target="_blank"
                >
                    {getLangText('configureLabel')}
                </a>
            </section>
        )
    }

    return (
        <>
            {!data.shippingData.inpost_shipping_id ?
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>{getLangText('inpostLabelLabel')}</h2>
                            <div className="section-header__description">
                                {getLangText('addInpostLabelLabel')}
                            </div>
                        </div>
                        <div>
                            <img src="/assets/images/inpost.svg" alt="InPost"/>
                        </div>
                    </div>
                    {!data.shipping_address_exist ?
                        <div
                            className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                            </div>
                        </div> :
                        <div className="inpost-chooser">
                            {inpostOptions.map((box) => (
                                <div
                                    key={box.id}
                                    id={box.id}
                                    className={`inpost-chooser__box ${values.inpost_size === box.id ? 'active' : ''}`}
                                >
                                    <input
                                        onChange={() => setValue('inpost_size', box.id)}
                                        checked={values.inpost_size === box.id}
                                        type="radio"
                                        value={values.inpost_size}
                                    />
                                    <span className="checkmark"/>
                                    <div className="inpost-chooser__box__text-wrapper">
                                        <div className="inpost-chooser__box__text-wrapper__top">
                                            {getLangText(box.label)}
                                        </div>
                                        <div className="inpost-chooser__box__text-wrapper__bottom">
                                            {box.sizes}
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={`/assets/images/svg/inpost/${box.img}.svg`}
                                            className="w-[31px]"
                                            height="31px"
                                        />
                                    </div>
                                </div>
                            ))}
                            <div
                                onClick={showModal}
                                className={`inpost-chooser__box ${values.inpost_size === INPOST_LABEL_CUSTOM ? 'active' : ''}`}
                            >
                                <input
                                    checked={values.inpost_size === INPOST_LABEL_CUSTOM}
                                    type="radio"
                                    value={values.inpost_size}
                                />
                                <span className="checkmark"/>
                                <div className="inpost-chooser__box__text-wrapper">
                                    <div className="inpost-chooser__box__text-wrapper__top">
                                        {getLangText('customSizeLabel')}
                                    </div>
                                    <div className="inpost-chooser__box__text-wrapper__bottom">
                                        {values.length ?
                                            `max. ${values.length} x ${values.width} x ${values.height} ${getLangText('toLabel')} ${values.weight} kg` :
                                            getLangText('selectSizeLabel')
                                        }
                                    </div>
                                </div>
                                <div>
                                    <img
                                        src="/assets/images/svg/inpost/custom.svg"
                                        className="w-[31px]"
                                        height="31px"
                                    />
                                </div>
                            </div>
                            <div className="button-place">
                                <button
                                    type="button"
                                    className={`button position-relative ${isWaiting ? 'pointer-events-none' : ''}`}
                                    onClick={createLabel}
                                >
                                    {isWaiting && <Loader/>}
                                    {getLangText('createLabelLabel')}
                                </button>
                            </div>
                        </div>
                    }
                </section> :
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>{getLangText('inpostLabelLabel')}</h2>
                            <div className="section-header__description">
                                {getLangText('manageInpostLabelLabel')}
                            </div>
                        </div>
                        <div>
                            <a
                                href="https://inpost.pl/"
                                target="_blank"
                                rel="nofollow"
                            >
                                <img src="/assets/images/inpost.svg" alt="InPost"/>
                            </a>
                        </div>
                    </div>
                    <div className="loader-parent">
                        <StylesLoader
                            className="small-loader"
                            isLoading={waitingForInpostLabel || waitingForLabel || waitingForPrint}
                        />
                        <div className="tripple-buttons">
                            <div>
                                <button
                                    onClick={() => checkIsReady(print)}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('printButton')}
                                    <i className="icon-print"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => checkIsReady(download)}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('previewButton')}
                                    <i className="icon-eye"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setModalOpen(true)}
                                    type="button"
                                    className="button border-button big-width"
                                >
                                    {getLangText('removeButton')}
                                    <i className="icon-bin"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    {data.shippingData.inpot_label_date &&
                        <div className="info-label mt-3">
                            <i className="icon-tick-c"/>
                            {getLangText('labelHasBennGeneratedLabel')}
                            <div className="date-box">
                                <span>{convertToUserTimeZone(data.shippingData.inpot_label_date).format('DD.MM.YYYY')}</span>
                                <span>{convertToUserTimeZone(data.shippingData.inpot_label_date).format('HH:mm:ss')}</span>
                            </div>
                        </div>
                    }
                </section>
            }
            {modalVisible &&
                <CustomSizeModal
                    setValue={setValue}
                    getFieldProps={getFieldProps}
                    modalVisible={modalVisible}
                    hideModal={hideModal}
                    onSave={onSave}
                />
            }
            {modalOpen &&
                <ConfirmModal
                    title={getLangText('askForRemovingInpostLabelLabel')}
                    SubTitle={() => <>{getLangText('actionCannotBeUnDoneLabel')}</>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen}
                    isWaiting={waiting}
                    onSubmit={remove}
                    hide={() => setModalOpen(false)}
                    submitButtonClass="danger"
                />
            }
        </>
    );
};

export default InpostLabelFrom;



