import React from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import DescriptionField from "./DescriptionField";
import BoxWrapper from "../BoxWrapper";
import Field from "../../../../../../../../../src_shared/components/form/field/Field";

const Description = ({getFieldProps, setValue, values, updateValues}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    return (
        <BoxWrapper
            title="adContent"
            desc="adContentSubLabel"
        >

            <DescriptionField
                data={values}
                updateValues={updateValues}
                setValue={setValue}
            />
        </BoxWrapper>
    );
};

export default Description;
