import React, {useEffect, useRef, useState} from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../../../../../../src_shared/components/form/switchField/SwitchField";
import ValidationError from "../../../../../../../../../src_shared/form/validation/ValidationError";
import api from "../../../../../../../../../services/axios/axios";
import {services} from "../../../../../../../../RestServices";

const DescriptionField = ({setValue, data, updateValues}) => {
    const {getLangText} = useLang();
    const isInitialMount = data.initialMount;

    const defaultValues = {
        inStock: 0,
        showPrices: 0,
        withVariants: 1,
    };

    const [values, setValues] = useState(defaultValues);

    const selectTemplate = async () => {
        try {
            const response = await api.post(`${services.API_MARKETING_CHOSEN_TEMPLATE}/${data?.id}`, {
                in_stock: values.inStock,
                show_prices: values.showPrices,
                with_variants: values.withVariants,
                products: data.products.map((product) => product.id),
            })
            updateValues({
                description_text: response.data.item?.description_text,
            })
        } catch (error) {
            // onError(error)
        } finally {
        }
    };

    console.log(isInitialMount)

    useEffect(() => {
        if (isInitialMount) {
            setValue('initialMount', false)
            return
        }

        selectTemplate();
    }, [values.inStock, values.showPrices, values.withVariants, data.products]);

    return (
        <>
            <div className="form-group mt-3">
                <div className="flex items-end mb-2">
                    <label
                        style={{
                            color: '#101827',
                        }}
                        className="control-label mb-0">{getLangText('defaultAdText')}</label>
                    {/*<button*/}
                    {/*    onClick={selectTemplate}*/}
                    {/*    className={`button border-button small-size ml-auto left-icon ${isWaiting ? 'loading-button' : ''}`}*/}
                    {/*    type="button"*/}
                    {/*>*/}
                    {/*    <Loader isLoading={isWaiting}  />*/}
                    {/*    <i className="icon-messenger"/>*/}
                    {/*    {getLangText('selectTemplateLabel')}*/}
                    {/*</button>*/}
                </div>
                <div className="border-area">
                <textarea
                    placeholder={getLangText('enterAdContent')}
                    className="form-control mb-0"
                    name="description_text"
                    rows="10"
                    value={data.description_text}
                    onChange={(e) => setValue('description_text', e.target.value)}
                />
                    <div className="p-3 pt-4 rounded-b-[5px] border-t mt-3">
                        <SwitchField
                            className="switch-form inline-label align-items-start"
                            label={getLangText('variantsInStockLabel')}
                            subLabel={getLangText('variantsInStockSubLabel')}
                            setValue={(field, value) => setValues({...values, inStock: value})}
                            value={values.inStock}
                            validateErrors={false}
                            getError={ValidationError.skip}
                        />
                        <div>
                            <SwitchField
                                className="switch-form inline-label align-items-start"
                                label={getLangText('showPricesLabel')}
                                subLabel={getLangText('showPricesInVariantsLabel')}
                                setValue={(field, value) => setValues({...values, showPrices: value})}
                                value={values.showPrices}
                                validateErrors={false}
                                getError={ValidationError.skip}
                            />
                        </div>
                        <div>
                            <SwitchField
                                className="switch-form inline-label align-items-start mb-0"
                                label={getLangText('productsWithVariants')}
                                subLabel={getLangText('showProductsWithVariants')}
                                setValue={(field, value) => setValues({...values, withVariants: value})}
                                value={values.withVariants}
                                validateErrors={false}
                                getError={ValidationError.skip}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DescriptionField;
