import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../formReducer";
import {variants} from "../../../../src_shared/helpers/helper";
import {dniVariants} from "../Form";
import FormActions from "../FormActions";
import SummarySkeleton from "../skeletons/SumarySkeleton";
import {useUnitPrice} from "../../shared/helpers/Price";
import useLang from "../../../../src_shared/hooks/useLang";
import {useLocation, useParams} from "react-router-dom";
import {parseParams} from "../../../../src_shared/list/ListActions";

const Summary = ({values, selectedSubscription, isWaiting, setValue}) => {

    const dispatch = useDispatch();
    const {waitingForResponse, userData} = useSelector((state) => state.session);
    const {summaryInfo} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const selectedPlan = values.subscriptions.find((i) => i.id === selectedSubscription) || {};
    const priceForMonth = selectedPlan.new_month_price;
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const {search} = useLocation();
    const parsedParams = parseParams(search);

    useEffect(() => {
        if (!!+parsedParams.christmas) {
            setValue('christmas', parsedParams.christmas)
        }
    }, [parsedParams.christmas])

    const getSummaryTexts = (plan) => {
        if (selectedSubscription !== plan.id) {
            return;
        }
        return (
            <Fragment key={plan.id}>
                {!!+values.extend_plan &&
                    <div>
                        <div className="label">
                            {getLangText('planLabel')} <span style={{textTransform: 'lowercase'}}>{plan.name}</span>
                            {+plan.months === 1 &&
                                <div className="sub-label">{getLangText('paymentPaymentForMonthInDaysLabel')}</div>
                            }
                        </div>
                        <div className="value align-self-start">
                            {+plan.months === 1 ?
                                (!!+userData.new_payment ?
                                    getPrice(plan.old_price, values.currencyText) :
                                    getPrice(plan.price, values.currencyText)
                                ) :
                                `${plan.months} x ${getPrice(values.subscriptions.find((i => +i.months === 1))?.price, values.currencyText)}`
                            }
                        </div>
                    </div>
                }
                {values.functionalities.map((item) => (
                    !!+values[item.selmo_name] &&
                    <div key={item.selmo_name}>
                        <div className="label">
                            {item.name}
                            {summaryInfo?.functionalities[item.selmo_name] &&
                                <div
                                    className="sub-label">{getLangText('paymentsPaymentForNoVariablesLabel')} {summaryInfo?.functionalities[item.selmo_name].days} {getLangText(variants(summaryInfo?.functionalities[item.selmo_name].days, dniVariants))}</div>
                            }
                        </div>
                        <div
                            className="value align-self-start">{getPrice(summaryInfo?.functionalities[item.selmo_name]?.price, values.currencyText) || getPrice(item.price, values.currencyText)}
                        </div>
                    </div>
                ))}
                {!!plan.discount_amount && !!+values.extend_plan &&
                    <div>
                        <div className="label">
                            {getLangText('paymentsDiscountLabel')}
                        </div>
                        <div
                            className="value align-self-start green">-{getPrice(plan.discount_amount, values.currencyText)}</div>
                    </div>
                }
            </Fragment>
        )
    }

    useEffect(() => {
        if (!waitingForResponse && values?.default_subscription) {
            dispatch(FormActions.getTotalPrice())
        }
    }, [waitingForResponse, values?.default_subscription])

    return (
        <SummarySkeleton hidden={!isWaiting}>
            <div className="summary-info-wrapper">
                <div className="section-label mb-3">
                    {getLangText('summaryLabel')}
                    {!!userData.user_info.premium_date &&
                        <div className="sub-section-label">
                            {getLangText('paymentsYourCurrentPlan')}&nbsp;
                            <strong>{summaryInfo.subscription.daysLeft} {getLangText(variants(summaryInfo.subscription.daysLeft, dniVariants))}</strong>
                        </div>
                    }
                </div>
                <div className="prices-wrapper">
                    <div className="partials-prices">
                        {values.subscriptions.map((plan) => (
                            getSummaryTexts(plan)
                        ))}
                    </div>
                    <div className="total-price">
                        {!!+parsedParams.christmas &&
                            <div
                                className="d-sm-none d-block bg-[#5FA5FA] mb-1 text-center text-[#fff] font-medium text-xs px-[10px] py-[6px] rounded-[8px]"
                                dangerouslySetInnerHTML={{__html: getLangText('fourteenDaysForFree')}}/>
                        }
                        {+selectedPlan.months !== 1 && !!+values.extend_plan &&
                            <div className="price-for-month">
                                <div className="label">{getLangText('paymentsPriceForMonthLabel')}</div>
                                <div className="value">
                                    <span
                                        className="old-price">{getPrice(values.subscriptions.find((i => +i.months === 1))?.price, values.currencyText)}</span>
                                    <span>{getPrice(priceForMonth, values.currencyText)}</span>
                                </div>
                            </div>
                        }
                        <div className="align-items-end">
                            {+selectedPlan.months === 1 ?
                                <div className="label">{getLangText('paymentNetPriceToPayLabel')}</div> :
                                <div className="label">{getLangText('paymentNetPriceToPayTodayLabel')}</div>
                            }
                            <div className="value">
                                {summaryInfo.discount?.amount &&
                                    <div className="status-bg green no-bg small-status text-nowrap">
                                        {getLangText({
                                            key: 'saveMoneyLabel',
                                            data: [getPrice(summaryInfo.discount?.amount, values.currencyText)]
                                        })}
                                    </div>
                                }
                                <div>
                                    {getPrice(summaryInfo.totalPrice, values.currencyText)}
                                </div>
                            </div>
                        </div>
                        {!!+parsedParams.christmas &&
                            <div
                                className="d-sm-inline-block d-none bg-[#5FA5FA] mb-sm-0 mb-3 justify-content-start text-[#fff] font-medium text-xs px-[10px] py-[6px] rounded-[8px]"
                                dangerouslySetInnerHTML={{__html: getLangText('fourteenDaysForFree')}}/>
                        }
                    </div>
                </div>
            </div>
        </SummarySkeleton>
    )
        ;
};

export default Summary;
