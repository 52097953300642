import {combineReducers} from "redux";
import listReducer from "./list/listReducer";
import formReducer from "./form/formReducer";
import statisticsReducer from "./stats/listReducer";

export default combineReducers({
	list: listReducer,
	form: formReducer,
	statistics: statisticsReducer,
});
