import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import SettingsGLSModel from "../../../../../modules/models/settings/SettingsGLSModel";
import Field from "../../../../../src_shared/components/form/field/Field";
import AsyncSearchSelectField
    from "../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";

const GlsConfiguration = () => {
    const {getLangText} = useLang();

    const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
        rest: services.API_SETTINGS_GLS_SK,
        model: SettingsGLSModel,
    });

    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        GLS
                    </li>
                </ul>
            </div>
            <Wrapper>
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-4">
                        <div className="steps-wrapper">
                            <div className="step">
                                <div className="step-header align-items-start">
                                    <div className="title d-block flex-grow-1">
                                        <div className="mb-2">{getLangText('pasteKeysInFieldsBelowLabel')}</div>
                                        <Field
                                            {...getFieldProps('email')}
                                            label={getLangText('emailLabel')}
                                        />
                                        <div className="grid grid-cols-2 gap-x-5">
                                            <Field
                                                {...getFieldProps('client_number')}
                                                label={getLangText('clientNumberLabel')}
                                            />
                                            <Field
                                                {...getFieldProps('password')}
                                                label={getLangText('passwordLabel')}
                                                type="password"
                                            />
                                        </div>
                                        <div className="grid grid-cols-2 gap-x-5">
                                            <Field
                                                {...getFieldProps('name')}
                                                label={getLangText('nameLabel')}
                                            />
                                            <Field
                                                {...getFieldProps('street')}
                                                label={getLangText('streetLabel')}
                                            />
                                            <Field
                                                {...getFieldProps('house_number')}
                                                label={getLangText('buildingNumberLabel')}
                                            />
                                            <Field
                                                {...getFieldProps('city')}
                                                label={getLangText('cityLabel')}

                                            />
                                            <Field
                                                {...getFieldProps('zip_code')}
                                                label={getLangText('zipCodeLabel')}
                                            />
                                            <AsyncSearchSelectField
                                                {...getFieldProps('country')}
                                                label={getLangText('countryLabel')}
                                                rest={services.API_SETTINGS_SHIPPING_COUNTRIES}
                                                formatOptionLabel={formatOptionLabel}
                                                defaultValue={{
                                                    id: values.country,
                                                    name: values.country_name
                                                }}
                                                placeholder={getLangText('countryLabel')}
                                                optionsParser={(options) => options?.map((i) => ({
                                                    ...i,
                                                    id: i.id,
                                                }))}
                                                searchOnFocus
                                            />
                                            <Field
                                                {...getFieldProps('phone')}
                                                label={getLangText('phoneLabel')}
                                            />
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <SwitchField
                                                {...getFieldProps('active')}
                                                label={getLangText('activeLabel')}
                                                className="switch-form inline-label align-items-start big-label light-version mt-0 mb-0"
                                            />
                                            <button
                                                type="button"
                                                onClick={onSave}
                                                className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                            >
                                                <Loader isLoading={isWaiting}/>
                                                {getLangText('saveButton')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default GlsConfiguration;
