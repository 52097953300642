import React, {useState} from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import BoxWrapper from "../BoxWrapper";
import SwitchField from "../../../../../../../../../src_shared/components/form/switchField/SwitchField";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import {prepareRealLang} from "../../../../../../../../../services/i18n/i18nAPI";
import {useSelector} from "react-redux";

export const DAILY_BUDGET = 1;
export const TOTAL_BUDGET = 2;

const tabs = [
    {id: DAILY_BUDGET, label: 'dailyBudget'},
    {id: TOTAL_BUDGET, label: 'totalBudget'},
]
const Budget = ({values, setValue, getFieldProps}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    return (
        <BoxWrapper
            title="adjustAdBudget"
            desc="adjustAdBudgetDesc"
        >
            <div className="grid grid-cols-2 bg-[#f3f4f6] rounded-medium p-1 mt-3">
                {tabs.map((item) => (
                    <div className={+item.id === +values.budget_type ? 'shadow-default-small rounded-medium overflow-hidden' : ''}>
                        <button
                            onClick={() => setValue('budget_type', item.id)}
                            className={`text w-full text-xs py-2 transition-all font-bold ${+item.id === +values.budget_type ? 'bg-[#fff] text-[#101827]' : 'text-[#6b7280]'}`}
                            type="button"
                            key={item.id}
                        >
                            {getLangText(item.label)}
                        </button>
                    </div>
                ))}
            </div>
            <div>
                <div className="flex items-center justify-between bg-[#f9fafb] rounded-medium px-3 py-2 mt-2">
                    <div className="shadow-default-small rounded-[6px] overflow-hidden">
                        <button
                            className="w-[40px] h-[40px] flex items-center justify-center bg-[#fff] text-[#6b7280] text-xl"
                            onClick={() => +values.budget_value > 50 && setValue('budget_value', +values.budget_value - 50)}
                            type="button"
                        >
                           <span className="-mt-0.5">-</span>
                        </button>
                    </div>
                    <div className="text-[36px] font-medium text-[#101827]">
                        {values.budget_value} <span className="text-[24px] text-[#4b5563]">zł</span>
                    </div>
                    <div className="shadow-default-small rounded-[6px] overflow-hidden">
                        <button
                            className="w-[40px] h-[40px] flex items-center justify-center bg-[#fff] rounded-[6px] text-[#6b7280] text-[10px]"
                            onClick={() => setValue('budget_value', +values.budget_value + 50)}
                            type="button"
                        >
                            <i className="icon-plus"/>
                        </button>
                    </div>
                </div>
                <div className="form-info-box align-items-center w-100 mb-0 mt-2 small-line-height">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div
                            className="title font-weight-normal"
                            dangerouslySetInnerHTML={{
                                __html: getLangText({
                                    key: +values.budget_type === 1 ? 'everydayYouWillSpendMoney' : 'youWillSpendMoneyForWholeAd',
                                    data: [values.budget_value]
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="border-t pt-4 mt-4 mb-3">
                    <SwitchField
                        {...getFieldProps('end_date_active')}
                        name="end_date_active"
                        className="switch-form inline-label big-label light-version mb-0"
                        setValue={setValue}
                        label={getLangText('setFinishDate')}
                    />
                    {!!+values.end_date_active &&
                        <div className="grid grid-cols-2 gap-4 mt-3 animate-[slideAndShow_.3s_ease_forwards]">
                            <div className="form-group calendar-input">
                                <div className="control-label">
                                    {getLangText('dateLabel')}
                                </div>
                                <DatePicker
                                    portalId="my-popper"
                                    className="form-control"
                                    selected={values.end_date}
                                    onChange={(value) => setValue('end_date', value)}
                                    locale={lang}
                                    dateFormat="dd.MM.y"
                                />
                                <i className="icon-calendar"/>
                            </div>
                            <div className="form-group">
                                <div className="control-label">
                                    {getLangText('hour')}
                                </div>
                                <div className="react-time-picker-wrapper">
                                    <TimePicker
                                        clearIcon={false}
                                        className="form-control flex items-center"
                                        disableClock={true}
                                        locale={prepareRealLang(lang)}
                                        onChange={(value) => setValue('end_time', value)}
                                        value={values.end_time}
                                        format="HH:mm"
                                    />
                                    <i className="icon-time"/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <SwitchField
                    {...getFieldProps('unactive_when_sold')}
                    name="unactive_when_sold"
                    className="switch-form inline-label big-label align-items-start light-version mb-0"
                    setValue={setValue}
                    label={getLangText('turnWhenProductsWillBeSold')}
                    subLabel={getLangText('turnWhenProductsWillBeSoldSubLabel')}
                />
            </div>
        </BoxWrapper>
    );
};

export default Budget;
