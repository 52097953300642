import React, {useState} from "react";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useLang from "../../../../src_shared/hooks/useLang";
import Pagination from "../../shared/components/pagination/Pagination";
import useList from "../../../../src_shared/list/useList";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../RestServices";
import SalesPostWrapper from "../SalesPostWrapper";
import EmptyList from "../../shared/EmptyList";
import Card from "./components/Card";
import {useDispatch, useSelector} from "react-redux";
import {getStateRoot as getFiltersStateRoot} from '../filters/filtersReducer'
import FiltersActions from "../filters/FiltersActions";
import SortDropdown from "./components/SortDropdown";
import IntroBanner from "./components/IntroBanner";
import DateRangePicker from "../../shared/components/form/DateRangePicker";
import ConfirmModal from "../../../../src_shared/components/modal/ConfirmModal";
import WaitingForSend from "../../../../src_shared/components/ui/waitingForSend/WaitingForSend";
import {setWaitingForSendAction} from "../../../../src_shared/components/ui/waitingForSend/WaitingForSendActions";
import NoDataModal from "./components/NoDataModal";
import HowItWorksComponent from "../howItWorks/HowItWorksComponent";
import SearchInput from "../../shared/components/form/SearchInput";
import useCreatePost from "./components/useCreatePost";

const SalesPostList = () => {
    const {getLangText} = useLang();
    const history = useHistory();
    const {userData} = useSelector((state) => state.session);
    const dispatch = useDispatch();
    const {waitingForSalesPostsImport} = useSelector((state) => state.shared.waitingForSend);
    const [showNoStatsModal, setShowNoStatsModal] = useState(false);
    const {first_step_sales_posts} = useSelector((state) => state.session.userData);

    const createPostCallback = (response) => {
        if (showNoStatsModal) {
            setShowNoStatsModal(false);
        }
        history.push(`${RouterPaths.SalesPost}/${response.id}`)
    }

    const {createPost, isWaitingForPost} = useCreatePost(createPostCallback)

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {callSort, sortBy},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval},
    } = useList({
        ListActions,
        getStateRoot,
    });

    const [{onSave: importPosts, isWaiting: isWaitingForImport}] = useSubmitData(
        {
            rest: services.API_VIDEO_FACEBOOK_POSTS,
            afterSubmit: () => {
                dispatch(setWaitingForSendAction('waitingForSalesPostsImport', true))
                cancelItemRemoval();
            }
        });

    if (first_step_sales_posts) return (
        <HowItWorksComponent
            createPost={createPost}
            isWaitingForPost={isWaitingForPost}
        />
    )

    return (
        <SalesPostWrapper title="salesPost">
            <div className="table-list-wrapper px-3 px-sm-0">
                <IntroBanner/>
                <div className="pb-0">
                    <div className="table-header">
                        <div className="mt-[16px] sm:mt-[40px] mb-[20px]">
                            <h1 className="text-xl font-bold flex items-center">
                                {getLangText('addedPosts')}
                                {totalItems > 0 &&
                                    <div className="badge white">{totalItems}</div>
                                }
                            </h1>
                            <div className="flex flex-end mt-[12px]">
                                <SortDropdown
                                    callSort={callSort}
                                    sortBy={sortBy}
                                />
                                <button
                                    onClick={askForRemoveItem}
                                    type="button"
                                    className="button d-sm-block d-none border-button ml-auto sm:mr-2 small-size"
                                >
                                    {getLangText('importFacebookPosts')}
                                </button>
                                <SearchInput
                                    placeholder={getLangText('searchPlaceholder')}
                                    className="mr-md-2 white-bg"
                                    search={search}
                                />
                                <DateRangePicker
                                    className="bg-white rounded-[5px] ml-sm-0 ml-auto"
                                    actions={ListActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    FiltersActions={FiltersActions}
                                />
                            </div>
                        </div>
                    </div>
                    {waitingForSalesPostsImport &&
                        <div className="flex justify-end -mt-2">
                            <WaitingForSend
                                className=""
                                small
                                title="postAreBeingImported"
                                alertTitle="postAreBeingImported"
                                field="waitingForSalesPostsImport"
                                fetchData={loadData}
                                rest={services.API_VIDEO_FACEBOOK_IMPORT_STATUS}
                                fetchOnUnmount={true}
                            />
                        </div>
                    }
                    {isWaiting ?
                        <ComponentLoader/> :
                        <EmptyList
                            items={items}
                            isLoading={isWaiting}
                            className="with-padd functionality-purchase with-bg rounded-[5px] shadow-selmo"
                            imgSrc="/assets/images/default/shop-logo.svg"
                            title={getLangText('emptyHereLabel')}
                            text={getLangText('changeSearchOrAddReelsLabel')}
                        >
                            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                {items.map((item) => (
                                    <Card
                                        key={item.id}
                                        item={item}
                                        setShowNoStatsModal={setShowNoStatsModal}
                                        userData={userData}
                                    />
                                ))}
                            </div>
                        </EmptyList>
                    }
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {isModalVisible &&
                <ConfirmModal
                    show={isModalVisible}
                    title={getLangText('wantToImportFacebookPosts')}
                    SubTitle={() => <span
                        className="block mt-2">{getLangText('wantToImportFacebookPostsSubLabel')}</span>}
                    isWaiting={isWaitingForImport}
                    hide={cancelItemRemoval}
                    onSubmit={importPosts}
                    dialogClassName="small-modal"
                    saveButton={getLangText('import')}
                />
            }
            {showNoStatsModal &&
                <NoDataModal
                    modalVisible={showNoStatsModal}
                    hideModal={() => setShowNoStatsModal(false)}
                />
            }
        </SalesPostWrapper>
    );
};

export default SalesPostList;
