const initialState = {
    items: [],
    isOpen: false,
    isModalOpen: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HEADER_NOTIFICATIONS_TOGGLE_VISIBILITY':
            return {...state, isOpen: action.toggle};
        case 'HEADER_NOTIFICATIONS_ADD_ITEM':
            return {...state, items: [action.data, ...state.items]};
        case 'HEADER_NOTIFICATIONS_UPDATE_ITEM':
            return {
                ...state,
                items: [...state.items.map((item) => +item.id === +action.data?.id ? action.data : item)],
                isOpen: true,
            };
        case 'HEADER_NOTIFICATIONS_UPDATE_ITEMS':
            return {
                ...state,
                items: action.items,
            };
        default:
            return state;
    }
}
