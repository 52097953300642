import React, {useEffect, useRef, useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {asideSubMenuSetVisible} from "../../AsideActions";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import useFetchData from "../../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../../RestServices";
import ServerImage from "../../../components/serverImage/ServerImage";
import {useHistory} from "react-router-dom";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";

const SubAside = () => {
    const {getLangText} = useLang();
    const subMenuRef = useRef();
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    const {subMenuOpen} = useSelector((state) => state.shared.aside);
    const [initialRender, setInitialRender] = useState(true);
    const {userData} = useSelector((state) => state.session);
    const history = useHistory();

    const [{data, isLoading, fetchData}] = useFetchData({
        rest: services.API_SUB_MENU,
        initialAutoLoad: false,
        initialData: [],
    })
    const hideSubMenu = () => dispatch(asideSubMenuSetVisible(false))
    const hide = () => {
        const body = document.querySelector('body')
        body.classList.remove('open-menu');
        hideSubMenu()
    }

    const getPosition = () => {
        if (subMenuRef.current) {
            const rect = subMenuRef.current.getBoundingClientRect();
            return rect.left + window.pageXOffset + rect.width
        }
        return null;
    };

    useEffect(() => {
        if (!initialRender && subMenuOpen) {
            dispatch(asideSubMenuSetVisible(true, getPosition()));
        }
        setInitialRender(false);
    }, [width, initialRender, dispatch, subMenuOpen]);

    useEffect(() => {
        if (subMenuOpen) {
            fetchData();
        }
    }, [subMenuOpen])

    return (
        <div
            ref={subMenuRef}
            className={`absolute left-0 lg:left-[100%] lg:top-auto top-[56px] lg:bottom-auto bottom-0 z-[999] sm:bg-transparent bg-white lg:right-auto right-0 w-full lg:w-[340px] pt-[14px] lg:pt-[47px] lg:pl-[40px] ${subMenuOpen ? 'opacity-0 lg:animate-[fadeIn_.4s_ease_forwards_.2s] animate-[fadeIn_.4s_ease_forwards]' : 'animate-[fadeOut_.2s_ease_forwards] pointer-events-none'}`}>
            <button
                className="absolute lg:block hidden top-2 right-5 text-desc text-[10px]"
                type="button"
                onClick={hide}
            >
                <i className="icon-cross"/>
            </button>
            <button
                className="lg:hidden text-xs font-bold flex items-center mb-4"
                type="button"
                onClick={hideSubMenu}
            >
                <i className="icon-arrow-left text-[10px] text-desc mr-2 relative bottom-[1px]"/>
                {getLangText('backButton')}
            </button>
            {data.map((option) => (
                <div className="pr-5">
                    <div className="text-sm font-bold mb-3">{getLangText(option.name)}</div>
                    <div className="space-y-3.5">
                        {option?.items?.map((item) => {
                            if (!+item.test_account || (!!+item.test_account && +userData.id === TEST_ACCOUNT_SHOP_ID)) {
                                return (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            hide()
                                            setTimeout(() => {
                                                history.push(item.route)
                                            }, 300)
                                        }}
                                        className="flex items-center hover:no-underline w-full rounded-[5px] group p-1 hover:text-[#000] hover:bg-[#F9FAFB] transition-all text-left"
                                    >
                                        <div
                                            className="group-hover:shadow-default rounded-[5px] overflow-hidden mr-2 transition-all">
                                            <ServerImage
                                                className="w-[34px] min-w-[32px]"
                                                src={item.icon}
                                            />
                                        </div>
                                        <div className="grow">
                                            <div className="font-bold text-xs">{getLangText(item.name)}</div>
                                            <div className="text-xs text-desc">{getLangText(item.description)}</div>
                                        </div>
                                        {(!!+item.selmo_pro && !+userData.selmo_pro_active) &&
                                            <div>
                                                <img className="w-[20px] inline-block" src="/assets/images/svg/pro.svg"
                                                     alt="pro"/>
                                            </div>
                                        }
                                    </button>
                                )
                            }
                            return null;
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SubAside;
