import React, {useEffect, useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {Link, Redirect} from "react-router-dom";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../RestServices";
import {getFromLS, saveToLS} from "../../../../../../src_shared/helpers/LS";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import {RouterPaths} from "../../../../../routes/RouterPath";
import FacebookMarketingConfirmModel from "../../../../../../modules/models/marketing/FacebookMarketingConfirmModel";

const FirstSteps = () => {
    const {getLangText} = useLang();
    const [isOpen, setIsOpen] = useState(false);

    const tutorialOpened = getFromLS('facebookMarketingTutorialOpened');

    useEffect(() => {
        saveToLS('facebookMarketingTutorialOpened', isOpen);
    }, [isOpen])

    useEffect(() => {
        if (tutorialOpened) {
            setIsOpen(true)
        }
    }, [tutorialOpened])

    const [{onSave, isWaiting, values, isLoading, setValue}] = useFormLocal({
        model: FacebookMarketingConfirmModel,
        rest: services.API_MARKETING_FACEBOOK_CHECK,
        afterSubmit: () => setValue('confirmed', true)
    });

    const isConfirmed = !!+values?.confirmed
    const isPaid = !!+values?.paid

    if (!isPaid) return <Redirect to={RouterPaths.MarketingFacebook} />

    return (
        <div className="marketing-top-gradient green sm:pt-20 sm:pb-20 p-6 loader-parent">
            <Loader isLoading={isLoading} className="small-loader" />
            <div className="text-center max-w-[550px] mx-auto">
                <img
                    className="w-[56px] min-w-[56px] mx-auto mb-3"
                    src="/assets/images/svg/facebookMarketing/tick-1.svg"
                    alt=""
                />
                <div className="text-xl mb-2 font-bold">{getLangText('yourApplicationHasBeenApplied')}</div>
                <div className="text-desc font-medium">{getLangText('yourApplicationHasBeenAppliedDesc')}</div>
                <div className="space-y-3 text-left mt-4">
                    <div className="border flex items-center rounded-[8px] border-[#dfe1e4] p-[18px]">
                        <img
                            className="w-[34px] min-w-[34px]"
                            src="/assets/images/svg/facebookMarketing/tick.svg"
                            alt=""
                        />
                        <div className="font-bold ml-[12px] opacity-30 leading-[1.25]">
                            {getLangText('paymentHasBeenRealized')}
                        </div>
                    </div>
                    <div className="border sm:flex items-center rounded-[8px] border-[#dfe1e4] p-[18px]">
                        <div className="flex items-center grow">
                            {isOpen || isConfirmed ?
                                <img
                                    className="w-[34px] min-w-[34px]"
                                    src="/assets/images/svg/facebookMarketing/tick.svg"
                                    alt=""
                                /> :
                                <div
                                    className="w-[34px] min-w-[34px] h-[34px] border border-[#d1d5db] rounded-full flex items-center justify-center text-sm font-bold"
                                >
                                    2
                                </div>
                            }
                            <div
                                className={`font-bold ml-[12px] sm:whitespace-pre-wrap leading-[1.25] grow ${isOpen || isConfirmed ? 'opacity-30' : ''}`}>
                                {getLangText('grantAccessToAdvertisingAccount')}
                            </div>
                        </div>
                        <Link
                            target="_blank"
                            to="/assets/pdf/selmo-jak-nadac-dostep.pdf"
                            onClick={() => setIsOpen(true)}
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                paddingTop: 9,
                                paddingBottom: 9,
                                paddingLeft: 12,
                                paddingRight: 12
                            }}
                            className={`sm:w-auto w-full sm:mt-0 mt-2.5 text-center bg-[#000000] text-nowrap text-[#fff] rounded-[8px] text-xs font-bold button black`}
                        >
                            {getLangText('openInstruction')}
                        </Link>
                    </div>
                    <div className="border sm:flex items-center w-full rounded-[8px] border-[#dfe1e4] p-[18px]">
                        <div
                            className={`flex items-center grow`}>
                            {isConfirmed ?
                                <img
                                    className="w-[34px] min-w-[34px]"
                                    src="/assets/images/svg/facebookMarketing/tick.svg"
                                    alt=""
                                /> :
                                <div
                                    className={`w-[34px] min-w-[34px] h-[34px] border border-[#d1d5db] rounded-full flex items-center justify-center text-sm font-bold ${isConfirmed ? 'opacity-30 pointer-events-none ' : ''} ${isOpen ? '' : 'opacity-30 pointer-events-none'}`}
                                >
                                    3
                                </div>
                            }
                            <div className={`font-bold ml-[12px] leading-[1.25] grow ${isConfirmed ? 'opacity-30 pointer-events-none ' : ''} ${isOpen ? '' : 'opacity-30 pointer-events-none'}`}>
                                {getLangText('confirmAccessGranting')}
                            </div>
                        </div>
                        {!isConfirmed &&
                            <button
                                type="button"
                                onClick={onSave}
                                style={{
                                    fontSize: 14,
                                    borderRadius: 8,
                                    paddingTop: 9,
                                    paddingBottom: 9,
                                    paddingLeft: 12,
                                    paddingRight: 12
                                }}
                                className={`sm:w-auto sm:mt-0 mt-2.5 block w-full text-center bg-[#000000] text-nowrap text-[#fff] rounded-[8px] text-xs font-bold button black ${isOpen ? '' : 'opacity-20 pointer-events-none'} ${isWaiting ? 'loading-button' : ''}`}
                            >
                                <Loader isLoading={isWaiting}/>
                                {getLangText('confirmButton')}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstSteps;
