import React, {useEffect} from 'react';
import SelectField from "../../../../../../src_shared/components/form/selectField/SelectField";
import {
    BUTIK_MADAME_PLUS_SIZE_SHOP_ID,
    CARMELA2_SHOP_ID, CARMELA_KIELCE_SHOP_ID,
    CARMELA_SHOP_ID,
    SHOP_BAZAR_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";

const options = [
    {value: 'POSNET', label: 'POSNET'},
    {value: 'POSNET 2', label: 'POSNET 2'},
]

const timOptions = [
    {value: 'POSNET 1', label: 'POSNET 1'},
    {value: 'POSNET 2', label: 'POSNET 2'},
]

const carmelaOptions = [
    {value: 'MAGAZYN', label: 'MAGAZYN'},
    {value: 'SKLEP', label: 'SKLEP'},
]

const carmelaKielceOptions = [
    {value: 'Carmela Kielce', label: 'Carmela Kielce'},
]

const shopBazzarOptions = [
    {value: 'POSNET', label: 'POSNET'},
    {value: 'POSNET 2', label: 'POSNET 2'},
    {value: 'POSNET SHOPBAZZAR', label: 'POSNET SHOPBAZZAR'},
]

const madameOptions = [
    {value: 'POSNET THERMAL HX/38.01 - ECA 2201072355', label: 'POSNET THERMAL HX/38.01 - ECA 2201072355'},
    {value: 'POSNET THERMAL HX/38.01 - ECA 2201076629', label: 'POSNET THERMAL HX/38.01 - ECA 2201076629'},
]

const PrinterSelect = ({setSelectedPrinter, selectedPrinter, userData}) => {

    const savedPrinter = localStorage.getItem('fiscalPrinterSelected');
    const setValue = (name, value) => {
        localStorage.setItem('fiscalPrinterSelected', value);
        setSelectedPrinter(value)
    }

    const preparedOptions = () => {
        if (+userData.id === TIM_SHOP_ID) {
            return timOptions;
        }
        if (+userData.id === SHOP_BAZAR_SHOP_ID) {
            return shopBazzarOptions;
        }
        if (+userData.id === CARMELA_SHOP_ID || +userData.id === CARMELA2_SHOP_ID) {
            return carmelaOptions;
        }
        if (+userData.id === CARMELA_KIELCE_SHOP_ID) {
            return carmelaKielceOptions;
        }
        if (+userData.id === BUTIK_MADAME_PLUS_SIZE_SHOP_ID) {
            return madameOptions;
        }
        return options
    }

    useEffect(() => {
        if (!!savedPrinter) {
            setSelectedPrinter(savedPrinter);
        }
    }, [savedPrinter])

    return (
        <div className="mt-3">
            <SelectField
                value={selectedPrinter}
                label="Wybierz drukarkę"
                defaultValue={{
                    value: selectedPrinter,
                    label: selectedPrinter
                }}
                placeholder="Wybierz"
                options={preparedOptions()}
                setValue={setValue}
                className="default-size mb-0"
                extraProps={{
                    isSearchable: false,
                }}
            />
        </div>
    );
};

export default PrinterSelect;
