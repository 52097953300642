import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../../../src_shared/components/loader/Loader";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Row from "../manual/Row";
import ShelvesModel from "../../../../../../../modules/models/warehouse/ShelvesModel";
import {createNewId} from "../../../../../live/details/default/liveDetailsReducer";

const CustomRow = ({afterSubmit, hideModal}) => {
    const {getLangText} = useLang();

    const [{
        setValue,
        values,
        onSave,
        isWaiting,
        updateValues,
        setNestedValue,
    }] = useFormLocal({
        rest: services.API_WAREHOUSE_SHELVES,
        model: ShelvesModel,
        afterSubmit,
        initialAutoLoad: false,
    });

    const addNewVariant = () => updateValues({
        row: [...values.row, {id: createNewId(), shelf_3: '', shelf_2: '', shelf_1: ''}]
    });

    return (
        <form onSubmit={onSave}>
            <Modal.Body className="pt-3 pb-5">
                {values.row.map((item) => (
                    <Row
                        item={item}
                        key={item.id}
                        setValue={setValue}
                        values={values}
                        setNestedValue={setNestedValue}
                        custom={true}
                    />
                ))}
                <button
                    className="button add-new-row text-blue-button"
                    onClick={addNewVariant}
                    type="button"
                >
                    {getLangText('addButton')}
                </button>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="submit"
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </form>
    );
};

export default CustomRow;
