import React from 'react';
import SelectField from "../../../../../../../src_shared/components/form/selectField/SelectField";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Field from "../../../../../../../src_shared/components/form/field/Field";

export function generateAlphabetArray() {
    const alphabet = [];
    for (let i = 65; i <= 90; i++) {
        const letter = String.fromCharCode(i);
        alphabet.push({value: letter, label: letter});
    }
    return alphabet;
}

export function generateNumberArray() {
    const numbers = [];
    for (let i = 1; i <= 1000; i++) {
        const value = i.toString();
        numbers.push({value, label: value});
    }
    return numbers;
}

const Row = ({item, values, setValue, setNestedValue, custom = false}) => {
    const {getLangText} = useLang();
    const removeVariant = (id) => {
        if (values.row?.length === 1) {
            const emptyRow = [{id, shelf_3: '', shelf_2: '', shelf_1: ''}]
            setValue('row', emptyRow);
            return;
        }
        const filtered = values.row.filter((i) => i.id !== id)
        setValue('row', filtered);
    };

    return (
        <div className="flex items-center mb-3 space-x-3">
            {custom ?
                <Field
                    name="shelf_1"
                    value={item.shelf_1}
                    className="default-size mb-0 grow"
                    label={`${getLangText('localizationLabel')}`}
                    setValue={(field, value) => setNestedValue('row', item.id, field, value)}
                    extraProps={{
                        autoFocus: true
                    }}
                /> :
                <>
                    <SelectField
                        name="shelf_1"
                        value={item.shelf_1}
                        className="default-size mb-0 grow"
                        label={`${getLangText('localizationLabel')} 1`}
                        setValue={(field, value) => setNestedValue('row', item.id, field, value)}
                        options={generateAlphabetArray()}
                    />
                    <SelectField
                        name="shelf_2"
                        value={item.shelf_2}
                        className="default-size mb-0 grow"
                        label={`${getLangText('localizationLabel')} 2`}
                        setValue={(field, value) => setNestedValue('row', item.id, field, value)}
                        options={generateNumberArray()}
                    />
                    <SelectField
                        name="shelf_3"
                        value={item.shelf_3}
                        className="default-size mb-0 grow"
                        label={`${getLangText('localizationLabel')} 3`}
                        setValue={(field, value) => setNestedValue('row', item.id, field, value)}
                        options={generateAlphabetArray()}
                    />
                </>
            }
            <button
                tabIndex="-1"
                type="button"
                className="icon-button text-desc text-[10px] mt-[18px] ml-2"
                onClick={() => removeVariant(item.id)}
            >
                <i className="icon-cross"/>
            </button>
        </div>
    );
};

export default Row;
