import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsColissimoModel extends Model {
	getModel() {
		return {
			contract_number: '',
			password: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			contract_number: ValidationError.notEmpty,
			password: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
	buildDTO(data) {
		return {
			contract_number: data.contract_number,
			password: data.password,
			active: data.active,
		}
	}
}

export default new SettingsColissimoModel();
