import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsColissimoDataModel extends Model {
	getModel() {
		return {
			street: '',
			building_number: '',
			city: '',
			zip_code: '',
		};
	}

	getValidators() {
		return {
			street: ValidationError.notEmpty,
			building_number: ValidationError.notEmpty,
			city: ValidationError.notEmpty,
			zip_code: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		const {street, building_number, city, zip_code} = data;
		return {
			street,
			building_number,
			city,
			zip_code,
		}
	}
}

export default new SettingsColissimoDataModel();
