import Model from "../../../src_shared/modules/model/Model";

export class FacebookMarketingConfirmModel extends Model {
    getModel() {
        return {
            paid: true,
        };
    }
}

export default new FacebookMarketingConfirmModel();
