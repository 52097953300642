import React from 'react';
import useLang from "../../../../../../../../src_shared/hooks/useLang";

const BoxWrapper = ({title, desc, children}) => {
    const {getLangText} = useLang();

    return (
        <div className="rounded-[8px] border p-[14px] sm:p-6">
            <div>
                <div className="font-bold">{getLangText(title)}</div>
                <div className="text-sm text-desc font-medium mb-2">{getLangText(desc)}</div>
            </div>
            {children}
        </div>
    );
};

export default BoxWrapper;
