import {combineReducers} from "redux";
import alertReducer from "./components/alert/alertReducer";
import selectSizesModalReducer from "./components/modal/selectSizesModalReducer";
import countDownNotificationReducer from "./components/notification/countDownNotificationReducer";
import shopSettingsLoaderReducer from "./components/shopSettingsLoader/shopSettingsLoaderReducer";
import confettiReducer from "./components/confetti/confettiReducer";
import notificationsPanelReducer from "./components/notificationsPanel/notificationsPaneliReducer";
import waitingForSendReducer from "../../../src_shared/components/ui/waitingForSend/waitingForSendReducer";
import asideReducer from "./aside/asideReducer";
import filesReducer from "./header/files/filesDropdown/filesReducer";
import generateLabelModalReducer from "./generateLabelModal/generateLabelModalReducer";
import selmoPaczkaSuccessDepositModalReducer from "./SelmoPaczkaSuccessDepositModal/selmoPaczkaSuccessDepositModalReducer";
import headerNotificationsReducer from "./header/notifications/headerNotificationsReducer";

export default combineReducers({
	alert: alertReducer,
	selectSizesModal: selectSizesModalReducer,
	countDownNotification: countDownNotificationReducer,
	shopSettingsLoader: shopSettingsLoaderReducer,
	confetti: confettiReducer,
	notificationsPanel: notificationsPanelReducer,
	waitingForSend: waitingForSendReducer,
	aside: asideReducer,
	selmoPaczkaSuccessDepositModal: selmoPaczkaSuccessDepositModalReducer,
	files: filesReducer,
	headerNotifications: headerNotificationsReducer,
	generateLabelModal: generateLabelModalReducer,
});
