import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {RouterPaths} from "../../../../routes/RouterPath";
import Loader from "../../../../app/shared/Loader";
import Field from "../../../../../src_shared/components/form/field/Field";
import AsyncSearchSelectField
	from "../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import React from "react";
import UspsWrapper from "../Form";
import UspsSenderDataModel from "../../../../../modules/models/settings/usps/UspsSenderDataModel";

const UspsSenderDataForm = () => {
	const {getLangText} = useLang()

	const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
		rest: services.API_SETTINGS_USPS,
		model: UspsSenderDataModel,
	});

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<a href={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</a>
					</li>
					<li className="active">
						<div>USPS</div>
					</li>
				</ul>
			</div>
			<UspsWrapper>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="settings-form pt-3">
						<h2 className="mb-3">
							{getLangText('senderDataLabel')}
						</h2>
						<Field
							{...getFieldProps('firstname')}
							label={getLangText('firstNameLabel')}

						/>
						<Field
							{...getFieldProps('lastname')}
							label={getLangText('lastNameLabel')}

						/>
						<div className="row">
							<div className="col-md-6">
								<Field
									{...getFieldProps('street')}
									label={getLangText('streetNumberLabel')}

								/>
							</div>
							<div className="col-md-6">
								<Field
									{...getFieldProps('city')}
									label={getLangText('cityLabel')}

								/>
							</div>
							<div className="col-md-6">
								<AsyncSearchSelectField
									{...getFieldProps('state')}
									label={getLangText('state')}
									rest={services.API_US_STATES}
									defaultValue={{
										id: values.state,
										name: values.state_name
									}}
									selectedOption={(selectedItem) => <div className="text-sm font-medium">{selectedItem.name}</div>}
									searchOnFocus
									// isSearchable={false}
									// withSearchInput
								/>
							</div>
							<div className="col-md-6">
								<Field
									{...getFieldProps('zip_code')}
									label={getLangText('zipCodeLabel')}

								/>
							</div>
						</div>
						<div className="button-place mt-3">
							<button
								type="button"
								className={`button primary ${isWaiting ? 'loading-button' : ''}`}
								onClick={onSave}
							>
								{isWaiting && <Loader isLoading/>}
								{getLangText('saveButton')}
							</button>
						</div>
					</div>
				</div>
			</UspsWrapper>
		</>
	);
};

export default UspsSenderDataForm;
