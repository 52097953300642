import {getStateRoot, prefix} from "./listReducer";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import {services} from "../../../../../../RestServices";
import ListActions from "../../../../../../../src_shared/list/ListActions";

export class FacebookAdvertisementsStatsListActions extends ListActions {
    setRefreshTokenLink(link) {
        return {
            type: `${this.prefix}SET_REFRESH_TOKEN_LINK`,
            link,
        };
    }

}

export const getInstance = () => new FacebookAdvertisementsStatsListActions({
    getStateRoot,
    restService: services.API_MARKETING_AD_STATS,
    pathname: RouterPaths.MarketingFacebookAdvertisementStatistics,
    prefix,
});
export default getInstance();
