import React from 'react';
import Skeleton from "./Skeleton";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Item from "./Item";
import useFetchData from "../../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../../RestServices";
import {useDispatch} from "react-redux";
import {headerNotificationsUpdateItems} from "../HeaderNotificationsActions";

const Dropdown = ({close, items}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{isLoading, setData}] = useFetchData({
        rest: services.API_NOTIFICATIONS_LIST,
        afterSubmit: (res) => dispatch(headerNotificationsUpdateItems(res)),
    });

    const setAsRead = (item) => setData((prevState) => prevState.map((i) => i.id === item.id ? {...item, viewed: item.id} : i))

    const afterClick = (item) => {
        setAsRead(item)
        close();
    }

    return (
        <div
            className="absolute z-10 top-[calc(100%+10px)] right-0 bg-[white] rounded-[12px] shadow-default min-w-[330px]">
            <div className="flex items-center pt-3 pb-2 px-3">
                <div
                    className="font-bold text-sm text-[#101827]">{getLangText('notificationsLabel')}</div>
                <button
                    className="ml-auto text-[8px] text-[#6B7280] hover:text-[#000] transition-all"
                    type="button"
                    onClick={close}
                >
                    <i className="icon-cross"/>
                </button>
            </div>
            {isLoading ?
                <Skeleton/> :
                <div className="m-[5px]">
                    {!!items?.length ?
                        items?.map((item) => <Item afterClick={afterClick} item={item} key={item.id}/>) :
                        <div className="font-bold text-xs flex items-center flex-col justify-center p-3">
                            <i className="icon-orders"/>
                            <div className="mt-2">{getLangText('noFiles')}</div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default Dropdown;
