import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

class UspsSenderDataModel extends Model {
	getModel() {
		return {
			firstname: '',
			lastname: '',
			street: '',
			city: '',
			zip_code: '',
			state: '',
		};
	}

	getValidators() {
		return {
			firstname: ValidationError.notEmpty,
			lastname: ValidationError.notEmpty,
			street: ValidationError.notEmpty,
			city: ValidationError.notEmpty,
			zip_code: ValidationError.notEmpty,
			state: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		const {firstname, lastname, street, city, zip_code, state} = data;
		return {
			firstname,
			lastname,
			street,
			city,
			zip_code,
			state,
		}
	}
}

export default new UspsSenderDataModel();
