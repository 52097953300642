import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import ManualRow from "./components/manual/ManualRow";
import AutomaticRow from "./components/automatic/AutomaticRow";
import CustomRow from "./components/custom/CustomRow";
import {useSelector} from "react-redux";
import {SHOP_BAZAR_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const AUTOMATIC_VIEW = 'AUTOMATIC_VIEW';
const MANUAL_VIEW = 'MANUAL_VIEW';
const CUSTOM_VIEW = 'CUSTOM_VIEW';
const ShelvesForm = ({modalVisible, hideModal, loadData}) => {
    const {getLangText} = useLang();
    const [activeView, setActiveView] = useState(MANUAL_VIEW);
    const {userData} = useSelector((state) => state.session);

    const afterSubmit = () => {
        hideModal();
        loadData();
    }

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="with-tabs">
                <Modal.Title>
                    {getLangText('addPlacesLabel')}
                </Modal.Title>
                <div className="tabs-wrapper">
                    <button
                        type="button"
                        className={activeView === MANUAL_VIEW ? 'active' : ''}
                        onClick={() => setActiveView(MANUAL_VIEW)}
                    >
                        {getLangText('manual')}
                    </button>
                    <button
                        type="button"
                        className={activeView === AUTOMATIC_VIEW ? 'active' : ''}
                        onClick={() => setActiveView(AUTOMATIC_VIEW)}
                    >
                        {getLangText('automatic')}
                    </button>
                    {(+userData.id === SHOP_BAZAR_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) &&
                        <button
                            type="button"
                            className={activeView === CUSTOM_VIEW ? 'active' : ''}
                            onClick={() => setActiveView(CUSTOM_VIEW)}
                        >
                            {getLangText('own')}
                        </button>
                    }
                </div>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            {activeView === MANUAL_VIEW &&
                <ManualRow afterSubmit={afterSubmit} hideModal={hideModal}/>
            }
            {activeView === AUTOMATIC_VIEW &&
                <AutomaticRow afterSubmit={afterSubmit} hideModal={hideModal}/>
            }
            {activeView === CUSTOM_VIEW &&
                <CustomRow afterSubmit={afterSubmit} hideModal={hideModal}/>
            }
        </Modal>
    );
};

export default ShelvesForm;
