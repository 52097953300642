import React from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../../../../../../src_shared/components/form/switchField/SwitchField";
import SalesPostModel from "../../../../../../../../../modules/models/salesPost/SalesPostModel";
import ConfirmRemoveModal from "../../../../../../../../../src_shared/components/modal/ConfirmRemoveModal";
import useRemoveData from "../../../../../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../../../../../RestServices";
import {RouterPaths} from "../../../../../../../../routes/RouterPath";
import MarketingDetailsFormModel from "../../../../../../../../../modules/models/marketing/MarketingDetailsFormModel";

const SaveAndPublish = ({onSave, getFieldProps, setValue, values}) => {
    const {getLangText} = useLang();
    const checkSaveButtonActivity = () => MarketingDetailsFormModel.validateData(values);

    const isActiveSaveButton = checkSaveButtonActivity();

    const [{
        openModal,
        hideModal,
        confirmModalVisible,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest: services.API_VIDEO_SOCIAL_VIDEOS,
        redirectRoute: RouterPaths.SalesPost
    });

    return (
        <div className="flex items-center justify-between">
            {/*{values.id &&*/}
            {/*    <button*/}
            {/*        type="button"*/}
            {/*        onClick={() => openModal(values)}*/}
            {/*        className="button grey-button"*/}
            {/*    >*/}
            {/*        <i className="icon-bin mr-1"/>*/}
            {/*        {getLangText('removePost')}*/}
            {/*    </button>*/}
            {/*}*/}
            <button
                style={{fontSize: 14, paddingTop: 14, paddingBottom: 14, borderRadius: 8, fontWeight: 'bold'}}
                className={`primary small-size sm:w-auto w-full sm:ml-auto button ${isActiveSaveButton ? '' : 'not-active'}`}
                type="button"
                onClick={onSave}
            >
                {getLangText(+values.status === 1 ? 'saveChangesButton' : 'sendToVerification')}
            </button>
            {confirmModalVisible &&
                <ConfirmRemoveModal
                    show={confirmModalVisible}
                    title={getLangText('wantToRemovePost')}
                    subTitle={getLangText('postWillRemoveOnlyInSelmoApp')}
                    hide={hideModal}
                    confirm={removeItem}
                    isWaiting={isDeleting}
                    confirmButton={getLangText('removeButton')}
                    dialogClassName="small-modal w-420"
                />
            }
        </div>
    );
};

export default SaveAndPublish;
