import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import Field from "../../../../../src_shared/components/form/field/Field";
import SettingsColissimoModel from "../../../../../modules/models/settings/SettingsColissimoModel";

const ColissimoConfiguration = () => {
    const {getLangText} = useLang()

    const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
        rest: services.API_SETTINGS_COLISSIMO,
        model: SettingsColissimoModel,
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Colissimo
                    </li>
                </ul>
            </div>
            <Wrapper>
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-4">
                        <div className="steps-wrapper">
                            <div className="step border-step">
                                <div className="step-header">
                                    <div className="number relative top-[-6px]">
                                        1
                                    </div>
                                    <div className="title d-block">
                                        <a
                                            href="https://www.colissimo.entreprise.laposte.fr/"
                                            target="_blank"
                                            rel="nofollow"
                                            className="no-underline"
                                        >
                                            <img style={{marginLeft: 0, height: '20px', width: 'auto'}}
                                                 src="/assets/images/colissimo.png" alt="colissimo"/>
                                        </a>
                                        <div>
                                            {getLangText('settings_integrations_furgonetka_login_1')}
                                        </div>
                                    </div>
                                    <a target="_blank"
                                       href="https://www.colissimo.entreprise.laposte.fr/"
                                       className="button primary small-size ml-auto text-center">
                                        {getLangText('settings_integrations_fakturownia_description_4')}
                                    </a>
                                </div>
                            </div>
                            <div className="step border-step mt-3">
                                <div className="step-header align-items-start">
                                    <div className="number relative top-[-6px]">
                                        2
                                    </div>
                                    <div className="title d-block flex-grow-1">
                                        <div className="mb-2">{getLangText('pasteKeysInFieldsBelowLabel')}</div>
                                        <Field
                                            {...getFieldProps('contract_number')}
                                            label={getLangText('contractNumber')}
                                        />
                                        <Field
                                            {...getFieldProps('password')}
                                            label={getLangText('passwordLabel')}
                                            type="password"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                <SwitchField
                                    {...getFieldProps('active')}
                                    label={getLangText('active')}
                                    className="switch-form inline-label align-items-start big-label light-version mb-0"
                                />
                                <div className="button-place ml-auto">
                                    <button
                                        type="button"
                                        onClick={onSave}
                                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                    >
                                        <Loader isLoading={isWaiting}/>
                                        {getLangText('saveButton')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default ColissimoConfiguration;
