import React from 'react';
import moment from "../../../../messenger/helpers/moment.js";
import {CONFIRM_MODAL_TYPES} from "../clients/confirmModals/ConfirmModalTypes.js";
import {useDispatch, useSelector} from "react-redux";
import {
    setConfirmModalToggleVisibility, summaryBatchRequest
} from "../LiveDetailsActions.js";
import CreateAllOrders from "../clients/confirmModals/ConfirmModal.jsx";
import SendAllBasketStates from "../clients/confirmModals/ConfirmModal.jsx";
import SendAllSummary from "../clients/confirmModals/ConfirmModal.jsx";
import StylesLoader from "../../../../shared/Loader.jsx";
import useLang from "../../../../../../src_shared/hooks/useLang";
import WaitingForSent from "./WaitingForSent";
import ResetCartButton from "./components/ResetCartButton";
import ResetSummariesButton from "./components/ResetSummariesButton";
import BatchOrdersModal from "./batchOrders/BatchOrdersModal";
import {services} from "../../../../../RestServices";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

export const {
    REMOVE_LIVE, CREATE_ALL_ORDERS, SEND_ALL_BASKET_STATES, SEND_ALL_SUMMARY,
} = CONFIRM_MODAL_TYPES;

const Summary = ({isAtLeastOneOrderExist}) => {
    const dispatch = useDispatch();
    const {liveInfo, isInfoLoading, clientsConfirmModal} = useSelector((state) => state.lives.details)
    const finishedLive = +liveInfo.finished;
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const openConfirmModal = (type) => dispatch(setConfirmModalToggleVisibility(type, liveInfo))

    return (
        <div className="loader-parent mt-sm-0 mt-3">
            <StylesLoader isLoading={isInfoLoading}/>
            {liveInfo.waitingForBasket &&
                <WaitingForSent
                    title={getLangText('basketCartsAreBeingSentLabel')}
                    type={1}
                />
            }
            {liveInfo.waitingForSummary &&
                <WaitingForSent
                    title={getLangText('summariesAreBeingSentLabel')}
                    type={2}
                />
            }
            {liveInfo.waitingForOrders &&
                <WaitingForSent
                    title={getLangText('activitiesAreBeingPerformedLabel')}
                    type={3}
                    rest={services.API_LIVE_SEND_BATCH_ORDERS_STATUS}
                    successAlertTitle="stillCreatingOrdersLabel"
                />
            }
            {!!liveInfo?.live_history?.length &&
                <div className="default-shadow-box summary-box mb-3">
                    <div className="title small-size mb-3">{getLangText('historyLabel')}</div>
                    <div className="histories-info">
                        {liveInfo?.live_history?.map((i) => (
                            <div
                                key={i.date}
                                className="info-label green-icon extra-small-text d-flex align-items-center"
                            >
                                <i className="icon-tick-c mr-1"/>
                                {+i.type === 1 &&
                                    <strong>{getLangText('sentBasketCartLabel')}</strong>
                                }
                                {+i.type === 2 &&
                                    <strong>{getLangText('sentSummaryLabel')}</strong>
                                }
                                {+i.type === 3 &&
                                    <strong>{getLangText('createdOrdersLabel')}</strong>
                                }
                                <div className="date-box extra-small-text ml-1">
                                    <span>{convertToUserTimeZone(i.finished_date).format('DD.MM.YYYY')}</span>
                                    {' • '}
                                    <span>{convertToUserTimeZone(i.finished_date).format('HH:mm:ss')}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
            {(!liveInfo.waitingForBasket || !liveInfo.waitingForSummary || !liveInfo.waitingForOrders) &&
                <div className="default-shadow-box summary-box">
                    <div className="title small-size mb-0">{getLangText('actionsLabel')}</div>
                    {!!+liveInfo.shopify &&
                        <div className="w-full bg-[#f9f9f9] rounded-[10px] py-2 px-[12px] mt-2 -mb-2">
                            <span className="text-sm font-bold">{getLangText('orderRealisedOn')}</span>
                            <img className="w-[60px] ml-1 d-inline-block -mt-0.5" src="/assets/images/svg/shopify.svg" alt=""/>
                        </div>
                    }
                    <div className="buttons-place">
                        {(!finishedLive && !liveInfo.waitingForOrders) &&
                            <button
                                type="button"
                                className="button border-button"
                                onClick={() => openConfirmModal(CREATE_ALL_ORDERS)}
                            >
                                {getLangText('createAllOrdersLabel')}
                                <i className="icon-plus"/>
                            </button>
                        }
                        {!!isAtLeastOneOrderExist &&
                            <>
                                {!liveInfo.waitingForBasket &&
                                    <button
                                        type="button"
                                        className="button border-button"
                                        onClick={() => openConfirmModal(SEND_ALL_BASKET_STATES)}
                                    >
                                        {getLangText('sendAllBasketStatesLabel')}
                                        <i className="icon-cart"/>
                                    </button>
                                }
                                {!liveInfo.waitingForSummary &&
                                    <button
                                        type="button"
                                        className="button border-button"
                                        onClick={() => openConfirmModal(SEND_ALL_SUMMARY)}
                                    >
                                        {getLangText('sendAllSummariesLabel')}
                                        <i className="icon-orders"/>
                                    </button>
                                }
                            </>
                        }
                        {(!isAtLeastOneOrderExist && !!+liveInfo.waitingForOrders) &&
                            <div className="text-sm text-desc">
                                {getLangText('currentlyNotActionsLabel')}
                            </div>
                        }
                        <ResetCartButton
                            userData={userData}
                            finishedLive={finishedLive}
                            shopLiveId={liveInfo.shop_live_id}
                            liveId={liveInfo.id}
                        />
                        <ResetSummariesButton
                            userData={userData}
                            finishedLive={finishedLive}
                            shopLiveId={liveInfo.shop_live_id}
                            liveId={liveInfo.id}
                        />
                    </div>
                </div>
            }
            {clientsConfirmModal.modalType === CONFIRM_MODAL_TYPES.CREATE_ALL_ORDERS &&
                <BatchOrdersModal/>
            }
            <SendAllBasketStates
                dialogClassName="sm-size"
                modalType={CONFIRM_MODAL_TYPES.SEND_ALL_BASKET_STATES}
                onSave={() => dispatch(summaryBatchRequest('/apilive/batchCart'))}
                title={getLangText('sendAllBasketStatesLabel')}
                desc={(
                    <div>
                        <div
                            className="sub-label grey-txt font-weight-normal pr-3">{getLangText('sendBasketCartSubTitleModalLabel')}</div>
                        <div className="form-info-box small-line-height align-items-center w-100 mt-3">
                            <div>
                                <i className="icon-info-c"></i>
                            </div>
                            <div>
                                <div className="title">{getLangText('itMakeTakeUpFiveMinLabel')}</div>
                            </div>
                        </div>
                    </div>
                )}
                saveButton={getLangText('sendBasketStatesLabel')}
            />
            <SendAllSummary
                dialogClassName="sm-size"
                modalType={CONFIRM_MODAL_TYPES.SEND_ALL_SUMMARY}
                onSave={() => dispatch(summaryBatchRequest('/apilive/batchSummary'))}
                title={getLangText('sendAllSummariesLabel')}
                desc={(
                    <div>
                        <div
                            className="sub-label grey-txt font-weight-normal pr-3">{getLangText('sendSummarySubTitleModalLabel')}</div>
                        <div className="form-info-box small-line-height align-items-center w-100 mt-3">
                            <div>
                                <i className="icon-info-c"></i>
                            </div>
                            <div>
                                <div className="title">{getLangText('itMakeTakeUpFiveMinLabel')}</div>
                            </div>
                        </div>
                    </div>
                )}
                saveButton={getLangText('sendSummariesLabel')}
            />
        </div>
    );
};

export default Summary;
