import React, {useContext, useState} from "react";
import FormActions from "./FormActions.js";
import useForm from "../../../../src_shared/form/useForm.js";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import ShopNameBar from "../../../routes/shopSettingsRoute/ShopNameBar";
import {useDispatch, useSelector} from "react-redux";
import {ShopSettingsContext} from "../../../routes/shopSettingsRoute/ShopSettingsRoute";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import CopyButton from "../../../../src_shared/components/form/copyButton/CopyButton";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";
import moment from "moment/moment";
import FunctionalityModal from "../../payments/functionalityModal/Form";
import {SHOP_PERMISSION} from "../../shared/enums/SelmoPermissions";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const ShopDesktop = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const [openModal, setOpenModal] = useState(false);
    const {shopData} = useContext(ShopSettingsContext);
    const shopLink = shopData.full_url ? `https://${shopData.domain || shopData.full_url}/${userData.origin !== SHOP_ORIGIN_PL ? `${userData.origin}/` : ''}` : `https://${userData.domain || userData.full_url}/${userData.origin !== SHOP_ORIGIN_PL ? `${userData.origin}/` : ''}`
    const shopLinkToDisplay = shopData.full_url ? `https://${shopData.domain || shopData.full_url}/${userData.origin === SHOP_ORIGIN_FR ? 'fr' : ''}` : `https://${userData.domain || userData.full_url}/${userData.origin === SHOP_ORIGIN_FR ? 'fr' : ''}`

    useForm({
        FormActions,
        params: {},
        requireParentId: false,
        preventAutoLoad: true
    });

    return (
        <>
            <ShopNameBar/>
            <div className="settings-page sub-settings-page loader-parent">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('shopGeneralSettingsLabel')}Selmo</title>
                </Helmet>
                <div className="settings-form shop-desktop-settings">
                    <div>
                        <h2
                            className="section-title"
                            dangerouslySetInnerHTML={{__html: getLangText('whereIsMyShopAddressLabel')}}
                        />
                        <div className="shop-name-bar d-block">
                            <div
                                className="client-link pb-0 border-0 flex-grow-1">
                                <div className="form-group with-copy-btn with-preview flex-grow-1">
                                    <div className="form-control">
                                        <a
                                            href={shopLinkToDisplay}
                                            target="_blank"
                                            className="short-link"
                                        >
                                            {shopLinkToDisplay}
                                        </a>
                                        <CopyButton
                                            buttonClassName="ml-sm-auto border-button only-icon"
                                            copyText={shopLinkToDisplay}
                                        />
                                        <a
                                            href={shopLinkToDisplay}
                                            className="button border-button small-size mb-0 ml-1 d-lg-flex d-none"
                                            target="_blank"
                                        >
                                            <i className="icon-eye"/>
                                            {getLangText('previewShopButton')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-info-box transparent p-0">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title mb-0">
                                        {getLangText('whereCanIChangeShopAddressLabel')}
                                    </div>
                                    <div className="desc">
                                        {getLangText('whereCanIChangeShopAddressSubLabel')}
                                        <Link className="blue-txt"
                                              to={RouterPaths.ShopDomain}>{getLangText('domainLabel')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="section-title">
                            {getLangText('shortcutsLabel')}
                        </h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopGeneral}>
                                        <div className="title">
                                            {getLangText('mainSettingsLabel')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('setLogoAndDefaultSettingsLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopDomain}>
                                        <div className="title">
                                            {getLangText('domainLabel')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('manageAddressShopLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopMainPage}>
                                        <div className="title">
                                            {getLangText('mainPageLabel')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('setBannersAndCategoriesLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 pt-1">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopPromotionsMessages}>
                                        <div className="title">
                                            {getLangText('announcementLabel')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('addContentToMessagesBarLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 pt-1">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopPromotionsNewsletter}>
                                        <div className="title">
                                            {getLangText('newsletterLabel')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('manageNewsletterListLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 pt-1">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopRegulation}>
                                        <div className="title">
                                            {getLangText('termsButton')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('insertTermsLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 pt-1">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopPages}>
                                        <div className="title">
                                            {getLangText('asidePagesLabel')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('createCustomPageInShopLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 pt-1">
                                <div className="settings-box">
                                    <Link to={RouterPaths.ShopIntegrations}>
                                        <div className="title">
                                            {getLangText('asideIntegrationLabel')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('connectIntegrationsLabel')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('goToLabel')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="section-title" dangerouslySetInnerHTML={{__html: getLangText('mostFaqLabel')}}/>
                        <div className="row">
                            <div className="col-md-4">
                                <Link
                                    to={RouterPaths.ProductsList}
                                    className="info-box blue"
                                >
                                    <div className="cat">{getLangText('asideProductsLabel')}</div>
                                    <div className="box-title">
                                        {getLangText('howToAddProductsTitleLabel')}
                                    </div>
                                    <div className="box-desc"
                                         dangerouslySetInnerHTML={{__html: getLangText('howToAddProductsLabel')}}/>
                                    <div className="button grey-button">
                                        {getLangText('goToProductsLabel')}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mt-md-0 mt-3">
                                <Link
                                    to={RouterPaths.CategoriesList}
                                    className="info-box yellow"
                                >
                                    <div className="cat">{getLangText('categoriesListLabel')}</div>
                                    <div className="box-title">
                                        {getLangText('howToCreateCategoriesTitleLabel')}
                                    </div>
                                    <div className="box-desc"
                                         dangerouslySetInnerHTML={{__html: getLangText('howToCreateCategoriesLabel')}}/>

                                    <div className="button grey-button">
                                        {getLangText('goToCategoriesLabel')}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mt-md-0 mt-3">
                                <Link
                                    to={RouterPaths.CollectionsList}
                                    className="info-box pink"
                                >
                                    <div className="cat">{getLangText('collectionsLabel')}</div>
                                    <div className="box-title">
                                        {getLangText('howToCreateCollectionsTitleLabel')}
                                    </div>
                                    <div className="box-desc"
                                         dangerouslySetInnerHTML={{__html: getLangText('howToCreateCollectionsLabel')}}/>
                                    <div className="button grey-button">
                                        {getLangText('goToCollectionsLabel')}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {shopData?.store_date &&
                        <div>
                            <h2 className="text-xl mb-2">
                                {getLangText('subscriptionStatusLabel')}
                            </h2>
                            <div className="active-plan-date d-flex align-items-center">
                                <div
                                    className="date px-3 py-2.5 rounded-md bg-gray-100 font-medium text-gray-600 text-sm">{getLangText('paymentsActivePlanToLabel')}
                                    <strong
                                        className="ml-1">{convertToUserTimeZone(shopData?.store_date).format('DD.MM.YYYY')}</strong>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => setOpenModal(true)}
                                    className="button primary small-size ml-2"
                                >
                                    {getLangText('extendLabel')}
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {openModal &&
                <FunctionalityModal
                    openModal={openModal}
                    setModalOpen={setOpenModal}
                    title={getLangText('shopModalTitleLabel')}
                    desc={getLangText('shopModalTitleSubLabel')}
                    functionalityName={SHOP_PERMISSION}
                />
            }
        </>
    );
};

export default ShopDesktop;



