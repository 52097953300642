import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {getCurrenciesShortcut} from "../../../../../stats/list/List";

const StatsRow = ({data}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const currency = 'PLN' || userData.currency_info.text;

    const progressValue = (value) => {
        if (!+value) {
            return null;
        }
        if (+value >= 0) {
            return (
                <div className="progress-value">
                    <strong>
                        <i className="icon-arrow-up"/>
                        {value} %
                    </strong>
                </div>
            )
        }
        return (
            <div className="progress-value red">
                <strong>
                    <i className="icon-arrow-down"/>
                    {value} %
                </strong>
            </div>
        )
    }

    return (
        <div className="stats-row">
            <div>
                <div>
                    <div className="label">
                        <img src="/assets/images/svg/orders.svg" alt=""/>
                        {getLangText('productsLeft')}
                    </div>
                    <div className="value">{data.available_qty}
                        {!!+data.ordersCountOverall && progressValue(data.lastPeriodOrdersCountOverall)}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div className="label">
                        <img src="/assets/images/svg/product-value.svg" alt=""/>
                        {getLangText('budgetSpent')}
                    </div>
                    <div className="value">{data.budget_spent} {getCurrenciesShortcut(currency)}
                        {!!+data.averageProductsValue && progressValue(data.lastPeriodAverageProductsValue)}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div className="label">
                        <img src="/assets/images/svg/order-value.svg" alt=""/>
                        {getLangText('ordersCountLabel')}
                    </div>
                    <div className="value">{data.order_total}
                        {!!+data.averageOrderValue && progressValue(data.lastPeriodAverageOrderValue)}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div className="label">
                        <img src="/assets/images/svg/income.svg" alt=""/>
                        {getLangText('salesFromAd')}
                    </div>
                    <div className="value">{data.ads_sale} {getCurrenciesShortcut(currency)}
                        {!!+data.incomeOverall && progressValue(data.lastPeriodIncomeOverall)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatsRow;
