import React from 'react';
import {getCurrenciesShortcut} from "../../../../stats/list/List";
import useLang from "../../../../../../src_shared/hooks/useLang";
import useList from "../../../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from './listReducer'
import {useSelector} from "react-redux";
import Table from "./components/Table";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../src_shared/modules/model/Model";
import {services} from "../../../../../RestServices";
import useFetchData from "../../../../../../src_shared/hooks/useFetchData";
import StatsRow from "./components/StatsRow";
import Pagination from "../../../../../admin/components/app/shared/components/pagination/Pagination";

const Statistics = () => {
    const {getLangText} = useLang();

    const [{data, isLoading}] = useFetchData({
        rest: services.API_MARKETING_AD_STATS_DETAILS,
        // initialAutoLoad: false,
        // afterSubmit,
    });

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove},
    } = useList({
        ListActions,
        getStateRoot,
    });

    return (
        <div className="">
            <div className="stats-wrapper bg-[#fff] p-6">
                <div className="text-lg font-bold mb-3">
                    {getLangText('statisticsLabel')}
                </div>
                <StatsRow data={data}/>
            </div>
            <div>
                <div className="bg-[#fff] px-6 pb-3 text-lg font-bold">
                    {getLangText('lastSold')}
                </div>
                <Table
                    isWaiting={isWaiting}
                    items={items}
                    updateSortOptions={updateSortOptions}
                    getSortClass={getSortClass}
                    askForRemoveItem={askForRemoveItem}
                    markedToRemove={markedToRemove}
                />
                <Pagination
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left', nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect={false}
                />
            </div>
        </div>
    );
};

export default Statistics;
