import React, {useEffect, useState} from 'react';
import {SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";
import cn from "clsx";
import useLang from "../../../../src_shared/hooks/useLang";
import useAssetsLang from "../../shared/helpers/useAssetsLang";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import AdvertsInfoModal from "../components/AddvertsInfoModal";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {Helmet} from "react-helmet";


const howItWorksOptions = [
    {
        id: 1,
        title: 'marketingHowItWorks1',
        icon: 'bag',
    },
    {
        id: 2,
        title: 'marketingHowItWorks2',
    },
    {
        id: 3,
        title: 'marketingHowItWorks3',
        icon: 'chart',
    },
]

const whyWorthItOptions = [
    {
        id: 0,
        title: 'uniqueShopData',
        icon: 'unique-data',
    },
    {
        id: 1,
        title: 'dedicatedCarer',
        icon: 'heart',
    },
    {
        id: 2,
        title: 'campaignControl',
        icon: 'control',
    },
]

const options = [
    {id: 1, title: 'marketingFacebookLPTitle1', desc: 'marketingFacebookLPSubTitle1'},
    {id: 2, title: 'marketingFacebookLPTitle2', desc: 'marketingFacebookLPSubTitle2'},
    {id: 3, title: 'marketingFacebookLPTitle3', desc: 'marketingFacebookLPSubTitle3'},
]

const FacebookMarketing = () => {
    const {getLangText} = useLang();
    const [showVideo, setShowVideo] = useState(false);
    const {origin} = useSelector((state) => state.session.userData);
    const [showModal, setShowModal] = useState(null);
    const activeView = 1;

    const handleShowVideo = () => setShowVideo(true);
    const lang = useAssetsLang();

    const autoPlaySrc = `https://www.youtube.com/embed/unczpON8Tx8?si=f49PcWgxL5n2xdad&autoplay=1&cc_load_policy=1`

    const [{isLoading, data, fetchData}] = useFetchData({
        rest: `${services.API_MARKETING_LANDING}/${activeView}`,
    })

    const isSubmitted = !!+data.submitted || false;
    const isPaid = !!+data.paid || false;
    const isFinished = !!+data.finished || false;

    useEffect(() => {
        if (isFinished && !isSubmitted) {
            setShowModal(activeView)
        }
    }, [isFinished, isSubmitted])


    if (isPaid) {
        return <Redirect to={RouterPaths.MarketingFacebookFirstSteps}/>
    }

    if (isLoading) return <ComponentLoader style={{borderRadius: 12, overflow: 'hidden'}}/>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('facebookAdds')} - Selmo</title>
            </Helmet>
            <div className="animate-[fadeIn_.3s_ease_forwards]">
                <div
                    className={`bg-[#fff] relative sm:rounded-[12px] shadow-default sm:pt-12 pb-10 overflow-hidden ${!!showModal ? '' : ''}`}>
                    {!!showModal &&
                        <div
                            onClick={() => setShowModal(false)}
                            className="transition-all absolute top-0 left-0 bottom-0 right-0 bg-[#B2B8C0] opacity-0 sm:opacity-90 z-10"/>}
                    {lang === SHOP_ORIGIN_PL &&
                        <div className="w-full sm:w-[750px] mx-auto">
                            <div className="selmo-video-wrapper no-border-sm">
                                {!showVideo ?
                                    <div
                                        onClick={handleShowVideo}
                                        className={cn('absolute top-0 left-0 bottom-0 right-0 group cursor-pointer sm:rounded-[12px] overflow-hidden')}>
                                        <video
                                            autoPlay={true}
                                            loop
                                            playsInline
                                            muted
                                            src="/assets/videos/pl/selmo-marketing-short.mp4"/>
                                        <div className="absolute top-0 right-0 bottom-0 left-0 bg-[#000] opacity-30"/>
                                        <img
                                            className="absolute sm:w-[100px] sm:h-[100px] w-[84pxpx] h-[84px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-all duration-200"
                                            src="/assets/images/svg/play-white.svg" alt="play" width={100}
                                            height={100}
                                        />
                                    </div> :
                                    <iframe
                                        frameBorder="0" allowFullScreen="" scrolling="no"
                                        allow="autoplay;fullscreen"
                                        src={autoPlaySrc}
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            left: '0px',
                                            top: '0px'
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    }
                    <div className={`sm:px-0 px-6 ${origin === 'pl' ? 'sm:mt-16' : ''}`}>
                        <div className="max-w-[600px] mx-auto">
                            <div className="flex items-cetner mt-4 mb-3">
                                <div
                                    className="text-[#5fa5fa] flex items-center text-xs tracking-[1px] uppercase font-bold">
                                    {getLangText('facebookAdds')}
                                </div>
                                <div
                                    className={`p-2 rounded-[5px] flex items-center text-xs font-bold  ml-auto  ${isFinished ? 'bg-[#fef2f2] text-[#981b1b]' : 'text-[#0D3364] bg-[#F0FAFF]'}`}>
                                    {!isFinished &&
                                        <span
                                            className={`border min-w-[8px] min-h-[8px] inline-block rounded-full mr-1 ${isFinished ? 'border-[#981b1b]' : 'border-[#5fa5fa] '}`}/>
                                    }
                                    {getLangText(isFinished ? 'applicationStopped' : 'openRegistration')}
                                </div>
                            </div>
                            <div
                                className="whitespace-pre-wrap big-text-vacation font-bold text-[24px] sm:text-[32px] leading-[36px] sm:leading-[46px]"
                                dangerouslySetInnerHTML={{
                                    __html: getLangText('increaseSale')
                                }}
                            />
                            <div className="mb-4 mt-4">
                                <div className="or-separator big-size text-center">
                                    <div
                                        style={{color: '#4b5563'}}
                                        className="text-[#4b5563]">{getLangText('paymentsHowItWorksLabel')}</div>
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-3 gap-4 mt-3 mb-4">
                                {howItWorksOptions?.map((i) => (
                                    <div
                                        key={i.id}
                                        className={`marketing-card flex pb-[25px] flex-col relative`}
                                    >
                                        <div
                                            className="absolute -top-2 -left-2 border-4 border-[#fff] w-[34px] h-[34px] rounded-full bg-[#5fa5fa] flex items-center justify-center text-sm font-bold text-[#fff]">{i.id}</div>
                                        <div
                                            className="title sm:whitespace-pre-wrap"
                                            dangerouslySetInnerHTML={{
                                                __html: getLangText({
                                                    key: i.title,
                                                })
                                            }}
                                        />
                                        <img
                                            className={`mt-auto w-[80%] mx-auto ${i.id === 1 ? '-mb-2' : ''}`}
                                            src={`/assets/images/svg/facebookMarketing/${i.icon}.${i.id === 1 ? 'webp' : 'svg'}`}
                                            alt=""/>
                                    </div>
                                ))}
                            </div>
                            <div className="mb-4 mt-4">
                                <div className="or-separator big-size text-center">
                                    <div
                                        style={{color: '#4b5563'}}
                                        className="text-[#4b5563]">{getLangText('pricingLabel')}</div>
                                </div>
                            </div>
                            <div className="mt-3 mb-4 space-y-1">
                                <div className="text-[#6b7280] font-medium">
                                <span
                                    className="text-[28px] text-[#101827] font-semibold">4 999 zł</span> / {getLangText('monthly')}
                                </div>
                                <div className="text-[#6b7280] font-medium">
                                 <span
                                     className="text-[#101827] font-semibold">+ 1 999 zł</span> {getLangText('oneTimeActivationFee')}
                                </div>
                                <div className="text-[#6b7280] font-medium">
                                <span
                                    className="text-[#101827] font-semibold">+ 10%</span> {getLangText('fromMarketingBudget')}
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    disabled={isLoading || isSubmitted}
                                    onClick={() => setShowModal(activeView)}
                                    style={{fontSize: '18px', borderRadius: 8, paddingTop: 20, paddingBottom: 20}}
                                    className={`button w-full medium-size text-center ${isSubmitted ? 'green-button pointer-events-none' : 'black primary'}`}
                                >
                                    {isSubmitted && <i className="icon-tick-c mr-1"/>}
                                    {getLangText(isFinished ? (isSubmitted ? 'successfulSubmittedToWaitingList' : 'signupForWaitingList') : 'applyToParticipate')}
                                </button>
                                <div
                                    className="rounded-[8px] py-[10px] font-medium flex items-center justify-center mt-2 bg-[#fef2f2] text-[#981b1b]">
                                    <i className="icon-safe mr-1"/>
                                    <div dangerouslySetInnerHTML={{__html: getLangText('marketingShopsLimit')}}/>
                                </div>
                            </div>
                            <div className="mb-4 mt-5">
                                <div className="or-separator big-size text-center">
                                    <div
                                        style={{color: '#4b5563'}}
                                        className="text-[#4b5563]">{getLangText('pricingWhyWorthyTitle')}</div>
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-3 gap-4 mt-3 mb-4">
                                {whyWorthItOptions?.map((i) => (
                                    <div
                                        key={i.id}
                                        className={`marketing-card py-5 text-center`}
                                    >
                                        <img
                                            className="w-[60px] mb-3 mx-auto"
                                            src={`/assets/images/svg/facebookMarketing/${i.icon}.svg`}
                                            alt=""
                                        />
                                        <div
                                            className="text-[#101827] font-medium whitespace-pre-wrap leading-[1.18]"
                                            dangerouslySetInnerHTML={{
                                                __html: getLangText({
                                                    key: i.title,
                                                })
                                            }}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#fff] relative sm:rounded-[12px] shadow-default overflow-hidden p-6 sm:pt-28 mt-3">
                    {!!showModal && <div
                        onClick={() => setShowModal(false)}
                        className="transition-all absolute top-0 left-0 bottom-0 right-0 bg-[#B2B8C0] opacity-0 sm:opacity-90 z-10"/>}
                    <div className="space-y-20 sm:px-10 pb-12 sm:pb-20">
                        {options.map((i) => (
                            <div className="grid sm:grid-cols-2 items-center">
                                <div className={`${i.id % 2 === 0 ? 'sm:order-1' : 'sm:order-2'} sm:mb-0 mb-3.5`}>
                                    <img
                                        className="rounded-[15px] sm:rounded-[30px]"
                                        src={`/assets/images/marketing/mark${i.id}.webp`} alt=""/>
                                </div>
                                <div
                                    className={`${i.id % 2 === 0 ? 'sm:order-2 sm:ml-12' : 'sm:order-1'} sm:mr-12`}>
                                    <div
                                        className="text-[#5fa5fa] flex items-center text-[10px] tracking-[1px] uppercase font-bold mb-2">
                                        {getLangText('facebookAdds')}
                                    </div>
                                    <div
                                        className="text-[#101827] font-bold text-2xl sm:text-[34px] sm:leading-[1.19] mb-2 whitespace-pre-wrap"
                                        dangerouslySetInnerHTML={{__html: getLangText(i.title)}}/>
                                    <div className="text-[#6b7280] leading-[1.71] text-sm font-medium"
                                         dangerouslySetInnerHTML={{__html: getLangText(i.desc)}}
                                    />
                                    <button
                                        type="button"
                                        disabled={isLoading || isSubmitted}
                                        onClick={() => setShowModal(activeView)}
                                        style={{fontSize: 14, borderRadius: 8}}
                                        className={`sm:w-auto w-full text-center mt-3 bg-[#000000] text-nowrap text-[#fff] rounded-[8px] text-xs sm:py-[6px] py-[12px] px-7 font-bold button black`}
                                    >
                                        {getLangText(isFinished ? 'signupForWaitingList' : 'applyToParticipate')}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {showModal &&
                    <AdvertsInfoModal
                        show={showModal}
                        hideModal={() => setShowModal(null)}
                        isLoading={isLoading}
                        isFinished={isFinished}
                        fetchData={fetchData}
                    />
                }
            </div>
        </>
    );
};

export default FacebookMarketing;
