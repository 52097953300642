import React from 'react';
import {Link, useLocation} from "react-router-dom";
import useLang from "../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../routes/RouterPath";

const tabs = [
    {
        id: 1,
        label: 'firstSteps',
        path: RouterPaths.MarketingFacebookFirstSteps,
    },
    {
        id: 2,
        label: 'advertisements',
        path: RouterPaths.MarketingFacebookAdvertisements,
    },
    {
        id: 3,
        label: 'statisticsLabel',
        path: RouterPaths.MarketingFacebookStatistics,
    },
]

const SubmittedWrapper = ({children}) => {
    const {getLangText} = useLang();
    const {pathname} = useLocation();

    return (
        <div className="animate-[fadeIn_.3s_ease_forwards]">
            <title>{getLangText('facebookAdds')} - Selmo</title>
            <div className={``}>
                <div className="tabs-wrapper bg-[#fff] sm:rounded-t-medium shadow-default">
                    {tabs.map((i) => (
                        <Link
                            to={i.path}
                            key={i.id}
                            className={`${pathname === i.path ? 'active' : ''}`}
                            activeClassName="active"
                        >
                            {getLangText(i.label)}
                        </Link>
                    ))}
                </div>
                {children}
            </div>
        </div>
    );
};

export default SubmittedWrapper;
