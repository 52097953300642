import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";
import ListReducer from "../../../../../../src_shared/list/ListReducer";

export const getStateRoot = (state) => state.admin.shopifyClients.list;
export const prefix = 'ADMIN_SHOPIFY_CLIENTS_SHOPS_';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

