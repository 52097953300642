import React from "react";
import {RouterPaths} from "../../../../../../../routes/RouterPath";
import useTableRowClick from "../../../../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../../../shared/EmptyList";
import {useSelector} from "react-redux";
import SwitchField from "../../../../../../../../src_shared/components/form/switchField/SwitchField";
import ServerImage from "../../../../../../shared/components/serverImage/ServerImage";
import Loader from "../../../../../../../../src_shared/components/loader/Loader";

const Table = ({
                   items,
                   isWaiting,
                   updateSortOptions,
                   getSortClass,
                   askForRemoveItem,
                   createAd,
                   isWaitingForAd
               }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick('');
    const {userData} = useSelector((state) => state.session);

    const getPreparedRedirectPath = (item) => {
        const path = !!+item?.product?.id ? `${RouterPaths.MarketingFacebookAdvertisements}/details/statistics/${item.id}`: `${RouterPaths.MarketingFacebookAdvertisements}/details/${item.id}`
        return path.slice(1);
    }

    const ExtraComponent = () => (
        <button
            onClick={createAd}
            style={{borderRadius: 8}}
            className={`button primary medium-size mt-3 mb-5 ${isWaitingForAd ? 'loading-button' : ''}`}
            type="button"
        >
            {isWaitingForAd && <Loader isLoading={true}/>}
            {getLangText('createAd')}
        </button>
    )

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd functionality-purchase with-bg"
            imgSrc="/assets/images/svg/marketing/fb.svg"
            title={getLangText('noAddedAds')}
            text={getLangText('createAdToIncreaseReach')}
            imgStyle={{width: 44, height: 44}}
            ExtraComponent={ExtraComponent}
        >
            <div className="table-wrapper">
                <div className="table-responsive rounded-b-medium">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th
                                name="shop_ad_id"
                                onClick={updateSortOptions}
                                className={`${getSortClass('shop_ad_id')}`}
                            >
                                    <span name="shop_ad_id">
                                {getLangText('numberShortLabel')}
                                    </span>
                            </th>
                            <th className="whitespace">
                                {getLangText('turnOn')}
                            </th>
                            <th
                                name="products_total"
                                onClick={updateSortOptions}
                                className={`whitespace-nowrap ${getSortClass('products_total')}`}
                            >
                                    <span name="products_total">
                                        {getLangText('productLabel')}
                                    </span>
                            </th>
                            <th
                                name="status"
                                onClick={updateSortOptions}
                                className={`${getSortClass('status')}`}
                            >
                                    <span name="status">
                                       {getLangText('statusLabel')}
                                    </span>
                            </th>
                            <th
                                name="available_squ"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('available_squ')}`}
                            >
                                    <span name="available_squ">
                                        {getLangText('availablePieces')}
                                    </span>
                            </th>
                            <th
                                name="sold_qty"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('sold_qty')}`}
                            >
                                    <span name="sold_qty">
                                        {getLangText('soldPieces')}
                                    </span>
                            </th>
                            <th
                                name="sold_value"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('sold_value')}`}
                            >
                                    <span name="sold_value">
                                        {getLangText('valueLabel')}
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items?.map((item) => (
                            <tr
                                {...onTableRowClick(getPreparedRedirectPath(item))}
                            >
                                <td
                                    data-thead={`${getLangText('numberShortLabel')}`}
                                    className="number with-mobile-thead inline-thead mb-lg-0 mb-1">
                                    <div className="text-xs font-bold text-desc mr-2">{item.shop_ad_id}</div>
                                </td>
                                <td className="sm:ml-0 ml-auto">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            askForRemoveItem(item);
                                        }}
                                    >
                                        <SwitchField
                                            value={(+item.status === 1 || +item.status === 2)}
                                            id="active"
                                            className="switch-form blue-version inline-label light-version flex mb-0"
                                        />
                                    </button>
                                </td>
                                <td
                                    style={{color: 'black'}}
                                    className="text-black sm:mb-0 mb-1 sm:w-auto w-full"
                                >
                                    {!!item.product?.id ?
                                        <div className="flex items-center">
                                            <div
                                                className="w-[32px] h-[32px] rounded-[5px] overflow-hidden mr-2">
                                                {item?.product?.photo_small ?
                                                    <ServerImage
                                                        className="w-full h-full object-cover"
                                                        src={item?.product?.photo_small}
                                                        alt={item?.product?.name}/> :
                                                    <img
                                                        className="w-full h-full object-cover"
                                                        src="/assets/images/default/product.svg" alt=""/>
                                                }
                                            </div>
                                            <div
                                                className="max-w-[240px] sm:max-w-[300px]">
                                                {item?.product?.name}
                                            </div>
                                            {!!item?.products_total &&
                                                <div className="custom-badge font-weight-bold inline-block ml-1">
                                                    +{item?.products_total}
                                                </div>
                                            }
                                        </div> :
                                        <div className="flex items-center">
                                            <div
                                                className="w-[32px] flex justify-center items-center h-[32px] rounded-[5px] bg-[#f9f9f9] mr-2">
                                                <img
                                                    className="w-[22px] mt-[4px] ml-0.5"
                                                    src="/assets/images/svg/no-product.svg" alt=""/>
                                            </div>
                                            <div className="font-medium text-desc">
                                                {getLangText('noProduct')}
                                            </div>
                                        </div>
                                    }
                                </td>
                                <td className="">
                                    {+item.status === 1 &&
                                        <div
                                            style={{minWidth: 95}}
                                            className="status-bg green small-status min-w-[95px]">
                                            <i className="icon-tick-c text-xs"/>
                                            {getLangText('active_label')}
                                        </div>
                                    }
                                    {+item.status === 2 &&
                                        <div
                                            style={{minWidth: 95}}
                                            className="status-bg blue small-status min-w-[95px] h-[28px] inline-flex items-center justify-center">
                                            <img
                                                className="inline-block w-[12px] mr-[4px] align-bottom"
                                                src="/assets/images/svg/half-time.svg" alt=""/>
                                            {getLangText('processing')}
                                        </div>
                                    }
                                    {+item.status === 3 &&
                                        <div
                                            style={{minWidth: 95}}
                                            className="status-bg grey small-status min-w-[95px]">
                                            <i className="icon-cross-c text-xs"/>
                                            {getLangText('not_active_label')}
                                        </div>
                                    }
                                </td>
                                <td className="text-right">
                                    <div
                                        className="sm:block hidden">{item.available_squ}</div>
                                </td>
                                <td className="text-right">
                                    <div
                                        className="sm:block hidden">{item.sold_qty}</div>
                                </td>
                                <td className="text-right">
                                    <div
                                        className="sm:block hidden">{item.sold_value}&nbsp;{userData?.currency_info?.text}</div>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
